<div style="display: block;width: 100%; max-height: 500px; overflow: auto;">
  <mat-form-field appearance="outline" style="border-radius: 0px">
      <mat-label>Sort By</mat-label>
      <mat-select [(value)]="selected" (selectionChange)="changeView()">
          <mat-option value="All">All</mat-option>
          <mat-option value="NS">Not Started</mat-option>
          <mat-option value="NF">Not Finished</mat-option>
          <mat-option value="F">Finished</mat-option>
      </mat-select>
  </mat-form-field>
  <table mat-table [dataSource]="dataSource" class="" style="width: 100%; margin-bottom: 20px; overflow-y: auto;">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                  (change)="$event ? selectRow($event, row) : null" [checked]="selection.isSelected(row)">
              </mat-checkbox>
          </td>
      </ng-container>

      <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef> User </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
  </table>
  <div>
      <button mat-raised-button style="background-color: #003180;color:white;margin: 5px 20px 0 20px;"
          (click)="report()">
          Download Report

      </button>

      <button mat-raised-button style="background-color: #003180;color:white;margin: 5px 20px 0 20px;" (click)="openProjectList()">
          Combine Reports
      </button >
  </div>

</div>
