<div *ngIf="showDialog"><app-survey-dialog (onConfirm)="confirmAction()" (onCancel)="closeDialog()"
        [dialogText]="dialogText"></app-survey-dialog>
</div>

<div *ngIf="showCreationModalVideo && bmxClientPageOverview" id="myModal" class="modal-video"
    style="display: flex;flex-direction: column;gap: 2px;">
    <!-- <label class="container-checkbox">Don't show again
    <input (click)="saveSelection()" type="checkbox" value="Bike" #modalChecked>
    <span class="checkmark">&#x2714;</span>
</label> -->
    <div class="modal-content">
        <video src='{{CREATION_VIDEO_PATH}}' controls></video>
    </div>
    <button class="btn btn-raised close-modal" (click)="showCreationModalVideo=false">close</button>
</div>
<!-- ➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗  SETTINGS ICONS  ➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗ -->
<div *ngIf="bmxClientPageOverview" style="display: flex; flex-direction: row-reverse;justify-content: end;top: -30px;right: 50px;border: 2px dashed #324395;padding-right: 10px;background: #ffffffd9;
    position: absolute;height: 54px;align-items: center;padding-right: 38px;">
    <!-- ⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺RAGE INPUTS FOR COLUMN SETTINGS ⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺ -->
    <div *ngIf="isColumnResizerOn" class="" style="display: flex;
        flex-direction: row;
        justify-content: start;background: #ffffffd9;
        gap: 0px;position: absolute;left: -713px;z-index: 1;border: 2px dashed #324395;">
        
        <!-- NAME CANDIATES -->
        <div style="display: flex;flex-direction: column;align-items: center;">
            <p style="margin: 0 0 -12px;color: #324395;">candidates
            </p>
            <input type="range" style="margin: 20px;width: 50px;"
                [(ngModel)]="bmxItem.componentSettings[0].nameCandidatesWidth" class="slider-font-size"
                tickInterval="0.5" min="50" max="400" [value]="bmxItem.componentSettings[0].nameCandidatesWidth">
        </div>
        <!-- RATIONALE -->
        <div style="display: flex;flex-direction: column;align-items: center;">
            <p style="margin: 0 0 -12px;color: #324395;">rationale
            </p>
            <input type="range" style="margin: 20px;width: 50px;"
                [(ngModel)]="bmxItem.componentSettings[0].rationalewidth" class="slider-font-size" tickInterval="0.5"
                min="50" max="600" [value]="bmxItem.componentSettings[0].rationalewidth">
        </div>
        <!-- RATE -->
        <div style="display: flex;flex-direction: column;align-items: center;">
            <p style="margin: 0 0 -12px;color: #324395;">rate
            </p>
            <input type="range" style="margin: 20px;width: 50px;" [(ngModel)]="bmxItem.componentSettings[0].rateWidth"
                class="slider-font-size" tickInterval="0.5" min="50" max="400"
                [value]="bmxItem.componentSettings[0].rateWidth">
        </div>
        <!-- COMMENTS -->
        <div style="display: flex;flex-direction: column;align-items: center;">
            <p style="margin: 0 0 -12px;color: #324395;">comments
            </p>
            <input type="range" style="margin: 20px;width: 50px;"
                [(ngModel)]="bmxItem.componentSettings[0].commentsWidth" class="slider-font-size" tickInterval="0.5"
                min="50" max="400" [value]="bmxItem.componentSettings[0].commentsWidth">
        </div>
        <!-- REST OF THE COLUMNS -->
        <div style="display: flex;flex-direction: column;align-items: center;">
            <p style="margin: 0 0 -12px;color: #324395;">other columns
            </p>
            <input type="range" style="margin: 20px;width: 50px;" [(ngModel)]="bmxItem.componentSettings[0].columnWidth"
                class="slider-font-size" tickInterval="0.5" min="50" max="400"
                [value]="bmxItem.componentSettings[0].columnWidth">
        </div>
        <!-- ROW HEIGHT -->
        <div style="display: flex;flex-direction: column;align-items: center;">
            <p style="margin: 0 0 -12px;color: #324395;">row height</p>
            <input type="range" style="margin: 20px;width: 60px;" [(ngModel)]="bmxItem.componentSettings[0].rowHeight"
                class="slider-font-size" tickInterval="0.5" min="-10" max="20"
                [value]="bmxItem.componentSettings[0].rowHeight">
        </div>
        <!-- FONT -->
        <div style="display: flex;flex-direction: column;align-items: center;">
            <p style="margin: 0 0 -12px;color: #324395;">font size: {{bmxItem.componentSettings[0].fontSize}}</p>
            <input type="range" style="margin: 20px;width: 50px;" [(ngModel)]="bmxItem.componentSettings[0].fontSize"
                class="slider-font-size" tickInterval="0.1" min="2" max="50"
                [value]="bmxItem.componentSettings[0].fontSize">
        </div>
        <!-- RADIO -->
        <div *ngIf="radioColumnCounter > 1" style="display: flex;flex-direction: column;align-items: center;">
            <p style="margin: 0 0 -12px;color: #324395;">radio</p>
            <input type="range" style="margin: 20px;width: 50px;"
                [(ngModel)]="bmxItem.componentSettings[0].radioColumnsWidth" class="slider-font-size" tickInterval="0.5"
                min="50" max="100" [value]="bmxItem.componentSettings[0].radioColumnsWidth">
        </div>
    </div>
    <!-- <mat-slider min="200" max="500" step="10" value="200" [(value)]="bmxItem.componentSettings[0].columnWidth"></mat-slider><p>{{bmxItem.componentSettings[0].columnWidth}}</p> -->
    <!-- ⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺  END  ⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺ -->
    <div style="display: flex; flex-direction: row-reverse;">
        <button (click)="showMatrixMenuBmx()" mat-icon-button matTooltip="open menu" matTooltipPosition="right">
            <mat-icon class="full-icon">{{iconMenuShow}}</mat-icon>
        </button>
        <div *ngIf="showMatrixMenu">
            <button (click)="openDialog('undo'); editSingleTableCells = false" class="add-column"
                matTooltip="undo last change" matTooltipPosition="above" mat-icon-button aria-label="ranking">
                <mat-icon style="font-size: 26px;" class="full-icon">undo</mat-icon>
            </button>
            <button (click)="selectedIndex = ''" class="add-column" matTooltip="cancel" matTooltipPosition="above"
                *ngIf="i === selectedIndex" mat-icon-button>
                <mat-icon style="font-size: 25px;" class="full-icon">check_circle</mat-icon>
            </button>
            <button (click)="deleteRows = !deleteRows; editSingleTableCells = false"
                [ngClass]="(!deleteRows)?'add-column':'add-column-active'" matTooltip="delete rows"
                matTooltipPosition="above" mat-icon-button aria-label="ranking">
                <mat-icon style="font-size: 26px;" class="full-icon">delete_outline</mat-icon>
            </button>
            <button (click)="setPronunciation(); editSingleTableCells = false" class="add-column" matTooltip="speech"
                matTooltipPosition="above" mat-icon-button aria-label="ranking">
                <mat-icon style="font-size: 26px;" class="full-icon">mic</mat-icon>
            </button>
            <button (click)="insertTextColumn(); editSingleTableCells = false" class="add-column"
                matTooltip="add text column" matTooltipPosition="above" mat-icon-button aria-label="ranking">
                <mat-icon style="font-size: 26px;" class="full-icon">view_list</mat-icon>
            </button>
            <!-- <button (click)="insertRadioColumn()" class="add-column"  matTooltip="add radio column"
    matTooltipPosition="above" mat-icon-button aria-label="ranking">
    <mat-icon style="font-size: 26px;" class="full-icon">radio_button_checked</mat-icon>
    </button> -->
            <button (click)="insertRow(); editSingleTableCells = false" class="add-column" matTooltip="insert row"
                matTooltipPosition="above" mat-icon-button aria-label="ranking">
                <mat-icon style="font-size: 26px;" class="full-icon">vertical_distribute</mat-icon>
            </button>
            <button (click)="dragRows = !dragRows; editSingleTableCells = false"
                [ngClass]="(!dragRows)?'add-column':'add-column-active'" matTooltip="drag rows"
                matTooltipPosition="above" mat-icon-button aria-label="ranking">
                <mat-icon style="font-size: 26px;" class="full-icon">drag_indicator</mat-icon>
            </button>
            <button (click)="editSingleTableCells = !editSingleTableCells"
                [ngClass]="(!editSingleTableCells)?'add-column':'add-column-active'" matTooltip="edit table cells"
                matTooltipPosition="above" mat-icon-button aria-label="ranking">
                <mat-icon style="font-size: 26px;" class="full-icon">explicit</mat-icon>
            </button>
        </div>
        <button (click)="showCreationModalVideo=true; editSingleTableCells = false" class="add-column"
            matTooltip="see tutorial" matTooltipPosition="above" mat-icon-button aria-label="ranking">
            <mat-icon style="font-size: 26px;" class="full-icon">video_library</mat-icon>
        </button>
        <button (click)="insertCommentBoxColumn(); editSingleTableCells = false" class="add-column"
            matTooltip="add comments column" matTooltipPosition="above" mat-icon-button aria-label="ranking">
            <mat-icon style="font-size: 26px;" class="full-icon">wysiwyg</mat-icon>
        </button>
        <button (click)="isColumnResizerOn = !isColumnResizerOn; editSingleTableCells = false"
            [ngClass]="(!isColumnResizerOn)?'add-column':'add-column-active'" matTooltip="columns width"
            matTooltipPosition="above" mat-icon-button aria-label="ranking">
            <mat-icon style="font-size: 26px;    z-index: 9;" class="full-icon">swap_horizontal_circle</mat-icon>
        </button>
        <button (click)="selectedIndex = i; editSingleTableCells = false; verifyCritera()" class="add-column"
            matTooltip="setup settings" matTooltipPosition="above" *ngIf="i !== selectedIndex" mat-icon-button>
            <mat-icon style="font-size: 25px;" class="full-icon">edit</mat-icon>
        </button>
        <button (click)="showModalTable = true" class="add-column" matTooltip="update names" matTooltipPosition="above"
            *ngIf="i !== selectedIndex" mat-icon-button>
            <mat-icon style="font-size: 25px;" class="full-icon"> text_fields</mat-icon>
        </button>
    </div>
</div>
<!-- ➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗  END  ➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗ -->
<!--🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑 CONFIGURATION BOX 🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑-->
<div *ngIf="i === selectedIndex" class="mat-elevation-z4" cdkDrag style="position: absolute;width: 50%;
background: #fafafa;z-index: 1;top: -65px;left: 25%;border: #063074 4px solid; overflow: auto;
   max-height: 36rem;
bottom: -2; ">

    <div class="example-handle" cdkDragHandle style="display: flex;
flex-direction: row;
justify-content: center;cursor: move; height: 25px; width: 100%;"><svg width="24px" fill="currentColor"
            viewBox="0 0 24 24">
            <path
                d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
            </path>
            <path d="M0 0h24v24H0z" fill="none"></path>
        </svg></div>

    <!-- Cards Test Name-->
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Columns</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div style="text-align: center;">
                <div style="font-size: 16px;margin: 20px;color: #48535e;">Copy & Paste Columns</div>
                <!-- <div style="font-size: 14px;margin-bottom: 20px;color: lightgray;">Brief Description Here</div> -->
            </div>
            <div style="display: flex; justify-content: center; align-items: center;">
                <mat-form-field style="width: 90%; margin-left: 20px;" appearance="outline">
                    <mat-label>Copy & Paste Columns </mat-label>
                    <textarea matInput [(ngModel)]="testNamesInput" (paste)="onPaste()"
                        (ngModelChange)="upLoadNamesAndRationales(testNamesInput)" placeholder="Paste test names here"
                        cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5"></textarea>
                    <mat-hint>Type or paste the test names here that you want to include</mat-hint>
                </mat-form-field>
            </div>

        </mat-card-content>

    </mat-card>
    <br>
    <!-- Cards Criteria-->

    <!-- Cards Select Ranking-->
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Select Ranking & Criteria</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="sm-section3-rkScale">

                <div class="sm-column-box-rkScale" style="gap: 4rem;">
                    <div style="display: flex; flex-direction: column; width: 40%;">
                        <div style="font-size: 12px; color: #48535e;">CREATE NEW CRITERIA</div>
                        <div style="display: flex;">
                            <mat-form-field style="flex: 1; width: 100%;" appearance="outline">
                                <mat-label>New Criteria</mat-label>
                                <input matInput [(ngModel)]="newCriteria" style="font-size: 12px;">
                                <mat-hint>Enter a criteria and click add</mat-hint> <!-- Hint for New Criteria -->
                            </mat-form-field>
                            <mat-icon style="cursor: pointer; font-size: 33px; color: #324395;"
                                (click)="addCriteria(newCriteria); newCriteria=''">add_box</mat-icon>
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 40%;">
                        <mat-form-field appearance="outline" style="margin: 0px; width: 100%;">
                            <mat-label>Choose Criteria</mat-label>
                            <mat-select multiple [(ngModel)]="newselectedCriteria"
                                (selectionChange)="criteriaSelection(selectedCriteria)">
                                <mat-option *ngFor="let criteriaItem of CRITERIA; let c = index" [value]="criteriaItem">
                                    {{criteriaItem.name}}
                                    <mat-icon matTooltip="Double click to delete criteria" matTooltipPosition="right"
                                        style="cursor: pointer; font-size: 15px; color: #ee7f25;"
                                        (dblclick)="deleteCriteria(c);">
                                        cancel</mat-icon>
                                </mat-option>
                            </mat-select>
                            <mat-hint>Select one or more criteria from the list</mat-hint>
                            <!-- Hint for Choose Criteria -->
                        </mat-form-field>
                    </div>
                </div>

                <div class="sm-row-box-rkScale">
                    <div>
                        <div style="font-size: 16px;margin-top: 10px;color: #48535e;">SELECT RATING RANGE</div>
                        <br>
                        <div
                            style="text-align: center;display: flex;flex-direction: row;justify-content: space-around;">
                            <input [(ngModel)]="rankingScaleValue" type="range" id="volume" name="volume" min="0"
                                max="10">
                            {{rankingScaleValue}}
                        </div>
                    </div>

                    <div style="margin-top: 0.5rem;">
                        <div style="font-size: 14px;color: #48535e;">CHOOSE RATING ICON</div>
                        <br>
                        <mat-radio-group aria-label="Select an option" [(ngModel)]="ratingScaleIcon">
                            <mat-icon style="color:#76d354;">grade</mat-icon>
                            <mat-radio-button value="grade">
                            </mat-radio-button>
                            <mat-icon style="color:#e6ad11;">rectangle</mat-icon>
                            <mat-radio-button value="rectangle">
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>

                </div>
            </div>
        </mat-card-content>

    </mat-card>

    <br>

    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Category</mat-card-title>
        </mat-card-header>
        <br>
        <div class="sm-section2-rkScale">
            <div class="sm-column-box-rkScale">
                <mat-form-field appearance="outline" style="width: 40%">
                    <mat-label>Category Name</mat-label>
                    <input matInput [(ngModel)]="bmxItem.componentSettings[0].categoryName" placeholder="Category Name"
                        style="font-size: 20px;color: #324395;">
                    <mat-hint>Enter the name of the category</mat-hint> <!-- Hint text for Category Name -->
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 40%">
                    <mat-label>Sub category Description</mat-label>
                    <input matInput [(ngModel)]="bmxItem.componentSettings[0].categoryDescription"
                        placeholder="Sub category Description" style="font-size: 20px;color: #ee7f25;">
                    <mat-hint>Enter a brief description of the Sub category </mat-hint>
                    <!-- Hint text for Category Description -->
                </mat-form-field>
            </div>
            <br>
            <mat-checkbox [(ngModel)]="randomizeTestNames">Randomize Names</mat-checkbox>
            <div class="sm-row-box-rkScale">
                <div>
                    <div
                        style="display: flex;flex-direction: column;justify-content: center;gap: 10px;align-items: center; ">
                        <div style="font-size: 16px;margin-top: 10px;color: #48535e;text-align: center;">Minimum:
                            {{bmxItem.componentSettings[0].minRule}}
                        </div>
                        <input type="number" min="0" [max]="rowsCount"
                            [(ngModel)]="bmxItem.componentSettings[0].minRule" style="width:53px; padding: 0px 10px;"
                            [value]="bmxItem.componentSettings[0].minRule">
                    </div>
                    <div style="font-size: 13px;color: #48535e;">Min number of selections to vote on</div>
                </div>
                <div>
                    <div
                        style="display: flex;flex-direction: column;justify-content:center;gap: 10px;align-items: center;">
                        <div style="font-size: 16px;margin-top: 10px;color: #48535e;text-align: center;">Maximum:
                            {{bmxItem.componentSettings[0].maxRule}}
                        </div>
                        <input type="number" min="0" [max]="rowsCount"
                            [(ngModel)]="bmxItem.componentSettings[0].maxRule" style="width:53px; padding: 0px 10px;"
                            [value]="bmxItem.componentSettings[0].maxRule">
                    </div>
                    <div style="font-size: 13px;color: #48535e;">Max number of selections to vote on</div>
                </div>
            </div>

        </div>
        <mat-card-content>

        </mat-card-content>

    </mat-card>
    <br>


    <!-- BUTTON-->
    <div class="sm-row-box-rkScale">
        <div class="button-cancel" style=" display: flex;
  flex-direction: column;
  align-items: center; ">
            <button style="outline: none;color:#d35854;   font-size: 21px;" mat-icon-button (click)="selectedIndex = ''"
                aria-label="ranking">
                <mat-icon>cancel</mat-icon>
            </button>
            <p style="margin-top: -11; color: #D35854; font-size: 21px;">Cancel</p>
        </div>
        <div class="button-Done" style="display: flex; flex-direction: column; align-items: center;">
            <button style="outline: none;color:#76d354;   font-size: 21px;" mat-icon-button
                (click)="selectedIndex = '';upLoadNamesAndRationales(testNamesInput, false)" [disabled]="false"
                aria-label="ranking">
                <mat-icon>check_circle</mat-icon>
            </button>
            <p style="margin-top: -1; color: #76D354; font-size: 21px;">Done</p>
        </div>
    </div>
</div>
<!--🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑 CONFIGURATION BOX 🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑-->



<!--💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲 TABLE 💻 DESKTOP 💻 GENERATOR 💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲-->
<div class="desktop">
    <div style="display: flex;flex-direction: column;padding-left: 40px;">
        <div style="font-size: 25px;color: #324395;padding-bottom: 10px;">{{bmxItem.componentSettings[0].categoryName}}
        </div>
        <div style="font-size: 18px;color: #ee7f25;padding-bottom: 20px;">
            {{bmxItem.componentSettings[0].categoryDescription}}</div>
    </div>
    <div class="scroller-style main-container" [dragula]="(dragRows)?'DRAGGABLE_ROW':''"
        [(dragulaModel)]="bmxItem.componentText" (dragulaModelChange)="checkDragEvetn($event);">
        <div *ngFor="let testName of bmxItem.componentText; let y = index">
            <!-- <div *ngIf="(y !== 0)" style="color: white;font-size: xx-small;">.</div> -->
            <div class="test-name-table">
                <div *ngFor="let columnName of columnsNames; let e =  index"
                    style="display: flex;align-items: center;justify-content: center; flex-direction: column;"
                    [ngClass]="(columnName.includes('Comments') == true)?'red':'blue'"
                    [style.padding]="bmxItem.componentSettings[0].rowHeight + 'px'" [style.width]="(columnName == 'rationale')?bmxItem.componentSettings[0].rationalewidth + 'px':
            (columnName == 'RATE')?bmxItem.componentSettings[0].rateWidth + 'px':
            (columnName == 'nameCandidates')?bmxItem.componentSettings[0].nameCandidatesWidth + 'px':
            (columnName.includes('Comments'))?bmxItem.componentSettings[0].commentsWidth + 'px':
            (columnName.includes('RadioColumn'))?bmxItem.componentSettings[0].radioColumnsWidth + 'px' :
            bmxItem.componentSettings[0].columnWidth + 'px'">
                    <!-- 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 HEADER TEXT AREA 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 -->
                    <div *ngIf="(y == 0)" class="header-title " (mouseenter)="selectedColumn = e">
                        <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
                            <div>
                                <textarea *ngIf="editSingleTableCells"
                                    [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                                    style="text-align: center; font-weight: bold;color: #324395;" class="row-box"
                                    [(ngModel)]="testName[columnName]" value="testName[columnName]"></textarea>
                                <p style="text-align: center; font-weight: bold;color: #324395;margin: 0 0 0px;padding:5px 1px"
                                    [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                                    *ngIf=" !editSingleTableCells">{{testName[columnName]}}
                                </p>
                            </div>
                            <div *ngIf="selectedColumn === e && bmxClientPageOverview && !editSingleTableCells" style="display: flex;flex-direction: row;justify-content: space-around;
                    position: absolute;top: 35px;background: white;border: #324395 dashed 1px;"
                                (mouseleave)="selectedColumn = ''">
                                <span class="header-delete-icon" matTooltip="double click to delete column"
                                    matTooltipPosition="above"
                                    (dblclick)="openDialog('delete column', columnName)">x</span>
                                <mat-icon style="cursor: pointer;" matTooltip="move column to the left"
                                    matTooltipPosition="above" (click)="swapColumnsLeft(e)">chevron_left</mat-icon>
                                <mat-icon style="cursor: pointer;" matTooltip="move column to the right"
                                    matTooltipPosition="above" (click)="swapColumns(e)">chevron_right</mat-icon>


                            </div>
                        </div>
                    </div>
                    <!-- 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 END HEADER TEXT AREA  🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 -->

                    <!-- 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 TABLE CELLS  🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 -->
                    <div>
                        <!--⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ STARS RENDERER ⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ -->
                        <div *ngIf="y !== 0  &&  columnName.includes('RATE') == true"
                            style="display: flex;align-items: center;justify-content: center;padding-right: 10px;">
                            <div *ngIf="(y !== 0)">
                                <div *ngIf="testName.CRITERIA; else elseBlock">
                                    <div *ngFor="let criteria of testName.CRITERIA  let x =  index"
                                        style="display: flex;flex-direction: column;justify-content: space-between;text-align: center;">
                                        <span [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                                            style="padding-top:6px;max-width: 200px;">{{criteria.name}}</span>
                                        <div style="min-width: 200px;padding-bottom:6px">
                                            <mat-icon matRipple *ngFor="let star of  criteria.STARS;"
                                                (click)="setCriteriaRating(star.id,x,y)" [ngClass]="star.styleClass"
                                                (mouseenter)="selectCriteriaStar(star.id,x,y)"
                                                (mouseleave)="leaveCriteriaStar(y,x)"
                                                style="outline: none;margin:1px -1px;cursor: pointer;">{{star.icon}}
                                            </mat-icon>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #elseBlock>
                                    <div style="position: relative;">
                                        <mat-icon matRipple *ngFor="let star of  testName.STARS; let x =  index"
                                            (click)="setRating(star.id,y)" [ngClass]="star.styleClass"
                                            (mouseenter)="selectStar(star.id , y)" (mouseleave)="leaveStar(y)"
                                            style="outline: none;margin:1px -1px;cursor: pointer;">{{star.icon}}
                                        </mat-icon>
                                    </div>
                                </ng-template>
                            </div>
                            <!-- 🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸 RATE BUTTONS 🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸 -->
                            <div style="display: flex;">
                                <button
                                    (click)="maxRuleCounterMinus();bmxItem.componentText[y]['RATE'] = 0;leaveStar(y)"
                                    *ngIf="bmxItem.componentText[y]['RATE']>0 && y != 0 " style="background-color: #ee7f25;
                    color: white;
                    min-width: 20px;
                    line-height: 19px;
                    padding: 1px 0px;
                    margin: -12px 6px;
                    position: absolute;
                    animation: rubberBand 700ms ease-in  forwards;height: 25px;"
                                    mat-button>{{bmxItem.componentText[y]['RATE']}}</button>
                            </div>
                            <div style="display: flex;gap: 60px;flex-direction: column;">
                                <div *ngFor="let criteria of testName.CRITERIA  let x =  index">
                                    <button (click)="criteria.RATE = 0;leaveCriteriaStar(y,x);maxRuleCounterMinus()"
                                        *ngIf="criteria.RATE>0 && y != 0 " style="background-color: #ee7f25;
                     color: white;
                     min-width: 20px;
                     line-height: 19px;
                     padding: 2px 0px;
                     margin: -5px -8px;
                     position: absolute;
                     animation: rubberBand 700ms ease-in  forwards;height: 25px;" mat-button>{{criteria.RATE}}</button>
                                </div>
                            </div>
                            <!-- 🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸 END RATE BUTTONS 🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸 -->
                            <textarea *ngIf="y == 0 && editSingleTableCells"
                                [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                                style="text-align: center; font-weight: bold;color: #324395;" class="row-box"
                                [(ngModel)]="bmxItem.componentSettings[0].ratingScaleTitle"
                                value="bmxItem.componentSettings[0].ratingScaleTitle"></textarea>
                            <div [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'" class="rating-text"
                                *ngIf="y == 0 && !editSingleTableCells">
                                {{bmxItem.componentSettings[0].ratingScaleTitle}}</div>
                        </div>
                        <!--⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ END STARS ⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ -->

                        <!-- IF ELSE TEMPLATE INPUTS  -->
                        <input type="radio" *ngIf="y !== 0  &&  columnName.includes('RadioColumn') == true"
                            [name]="'rate' + y" [checked]="testName[columnName]"
                            (change)="saveRadioColumValue(columnName, y)">
                        <!-- TEXT_AREA COLUMN COMMENTS-->
                        <textarea *ngIf="y !== 0  && columnName.includes('Comments') == true"
                            [style.width]="(bmxItem.componentSettings[0].commentsWidth - 20) +'px'"
                            [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'" cdkTextareaAutosize
                            [cdkAutosizeMinRows]="bmxItem.componentSettings[0].rowHeight"
                            style="text-align: center;height: 32px;width: 358px;font-size: 16px;border: 1px solid #dddddd;"
                            class="row-box" [(ngModel)]="testName[columnName]"></textarea>
                        <!-- TEXT AREA TO EDIT TABLE CELL CONTENT -->
                        <textarea *ngIf="y !== 0  && editSingleTableCells && (columnName.includes('RadioColumn') != true) &&
                      columnName.includes('Comments') != true &&  columnName.includes('RATE') != true"
                            [style.width]="(columnName == 'rationale')?(bmxItem.componentSettings[0].rationalewidth - 10) + 'px':(bmxItem.componentSettings[0].columnWidth -10) +'px'"
                            [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'" cdkTextareaAutosize
                            [cdkAutosizeMinRows]="bmxItem.componentSettings[0].rowHeight"
                            style="outline: none;text-align: center;margin: 0 10px;" class="row-box"
                            [(ngModel)]="testName[columnName]"></textarea>
                        <div style="display: flex;">
                            <mat-icon style="cursor: pointer;color: #324395;"
                                (click)="playTestNameSound(testName[columnName])"
                                *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('nameCandidates') == true) && displaySound">
                                volume_up
                            </mat-icon>
                            <p *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('RadioColumn') != true)
                        &&  columnName.includes('Comments') != true &&  columnName.includes('RATE') != true"
                                [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                                [style.width]="(columnName == 'rationale')?bmxItem.componentSettings[0].rationalewidth-50 + 'px':bmxItem.componentSettings[0].columnWidth-50 +'px'"
                                style="text-align: center;margin: 0 0 0px;padding: 4px;"
                                [innerHTML]="testName[columnName] | safe: 'html'">{{testName[columnName]}}
                            </p>
                        </div>
                    </div>
                    <!-- 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 END TABLE CELLS 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 -->
                </div>
                <!-- 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 -->
                <!-- <div class="test-name-table">
            <div *ngFor="let columnName of columnsNames; let e =  index">
                <div *ngIf="(y == 0)&& columnName.includes('Comments') == true" class="header-title "
                    (mouseenter)="selectedColumn = e" (mouseleave)="selectedColumn = ''">
                    <span *ngIf="selectedColumn === e && bmxClientPageOverview" class="header-delete-icon"
                        matTooltip="double click to delete column" matTooltipPosition="above"
                        (dblclick)="openDialog('delete column', columnName)">x</span>


                    <textarea *ngIf="editSingleTableCells"
                        [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                        style="text-align: center; font-weight: bold;color: #324395;" class="row-box"
                        [(ngModel)]="testName[columnName]" value="testName[columnName]"></textarea>
                    <p style="text-align: center; font-weight: bold;color: #324395;margin: 0 0 0px;"
                        [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                        *ngIf=" !editSingleTableCells">{{testName[columnName]}}
                    </p>
                </div>
                <textarea *ngIf="y !== 0  && columnName.includes('Comments') == true"
                    [style.width]="(bmxItem.componentSettings[0].columnWidth - 20) +'px'"
                    [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'" cdkTextareaAutosize
                    [cdkAutosizeMinRows]="bmxItem.componentSettings[0].rowHeight"
                    style="text-align: center;height: 32px;width: 358px;font-size: 16px;border: 1px solid #dddddd;"
                    class="row-box" [(ngModel)]="testName[columnName]"></textarea>
            </div>

        </div> -->
                <!-- 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 👻 -->
                <div *ngIf="(y !== 0) && bmxClientPageOverview && deleteRows"
                    style="min-width: 100px; position: absolute;right: 0px;display: flex;">
                    <button style="outline: none;margin:3px -8px;" (dblclick)="deletRow(y)" mat-icon-button
                        matTooltip="double click to delete row" matTooltipPosition="left" aria-label="ranking">
                        <mat-icon>delete_outline</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲 END TABLE GENERATOR 💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲-->
<!--📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱 TABLE 📱 MOBILE 📱 GENERATOR 📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱-->
<div class="mobile">
    <div style="display: flex;flex-direction: column;text-align: center;">
        <div style="font-size: 25px;color: #324395;padding-bottom: 10px;margin: 5px;">
            {{bmxItem.componentSettings[0].categoryName}}
        </div>
        <div style="font-size: 18px;color: #ee7f25;padding-bottom: 20px;margin: 10px;">
            {{bmxItem.componentSettings[0].categoryDescription}}</div>
    </div>
    <div class="scroller-style main-container">
        <div *ngFor="let testName of bmxItem.componentText; let y = index" style="margin-bottom: 10px;">
            <div class="test-name-table" *ngIf="(y != 0)" [style.height]="(open(y))?'':'45px'">
                <button mat-mini-fab style="cursor: pointer;position: absolute;left: 7px;
            line-height: 40px;    background-color: lightgray;color: white;box-shadow: none;" *ngIf="!open(y)"
                    (click)="(selectedCard == y)?selectedCard = '':selectedCard = y ; openSelected(y)">
                    <mat-icon>expand_more</mat-icon>
                </button>
                <button mat-mini-fab style="cursor: pointer;position: absolute;left: 7px;
            line-height: 40px;    background-color: lightgray;color: white;box-shadow: none;" *ngIf="open(y)"
                    (click)="(selectedCard == y)?selectedCard = '':selectedCard = y; openSelected(y)">
                    <mat-icon>expand_less</mat-icon>
                </button>
                <!-- 🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸 RATE BUTTONS 🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸 -->
                <button (click)="maxRuleCounterMinus();bmxItem.componentText[y]['RATE'] = 0;leaveStar(y)"
                    *ngIf="bmxItem.componentText[y]['RATE']>0 && y != 0 " style="background-color: #ee7f25;color: white;margin: 5px;width: 30px;
            height: 30px;
            line-height: 30px;right: 7px;
            position: absolute;animation: rubberBand 500ms ease-in  forwards;"
                    mat-icon-button>{{bmxItem.componentText[y]['RATE']}}
                </button>
                <!-- 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 HEADER TEXT AREA 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 -->
                <div style="display: flex;gap: 23px;padding-top: 10px;position: absolute;right: 24px;">
                    <div *ngFor="let criteria of testName.CRITERIA  let x =  index">
                        <button (click)="criteria.RATE = 0;leaveCriteriaStar(y,x);maxRuleCounterMinus()"
                            *ngIf="criteria.RATE>0 && y != 0 " style="background-color: #ee7f25;
                  color: white;
                  min-width: 20px;
                  line-height: 19px;
                  padding: 2px 0px;
                  position: absolute;
                  animation: rubberBand 500ms ease-in  forwards;" mat-button>{{criteria.RATE}}
                        </button>
                    </div>
                </div>
                <!-- 🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸 END RATE BUTTONS 🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸 -->
                <div *ngFor="let columnName of columnsNames; let e =  index">
                    <!-- <div *ngIf="(y == 0)" class="header-title " >
                <p style="text-align: center; font-weight: bold;color: #324395;margin: 0 0 0px;"
                    [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                    *ngIf=" !editSingleTableCells">{{testName[columnName]}}
                </p>
            </div> -->

                    <!-- 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 END HEADER TEXT AREA  🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 -->

                    <!-- 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 TABLE CELLS  🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 -->
                    <div>
                        <!--⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ STARS RENDERER ⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ -->
                        <div *ngIf="y !== 0  &&  columnName.includes('RATE') == true"
                            style="display: flex;align-items: center;justify-content: center;">
                            <div *ngIf="(y !== 0)">
                                <div *ngIf="testName.CRITERIA; else elseBlock">
                                    <div *ngFor="let criteria of testName.CRITERIA  let x =  index"
                                        style="display: flex;flex-direction: column;justify-content: space-between;text-align: center;">
                                        <span [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                                            style="padding-top:6px;max-width: 200px;">{{criteria.name}}</span>
                                        <div style="min-width: 200px;padding-bottom:6px">
                                            <mat-icon *ngFor="let star of  criteria.STARS;"
                                                (click)="setCriteriaRating(star.id,x,y)" [ngClass]="star.styleClass"
                                                (mouseenter)="selectCriteriaStar(star.id,x,y)"
                                                (mouseleave)="leaveCriteriaStar(y,x)"
                                                style="outline: none;margin:1px 5px;font-size: 40px;">{{star.icon}}
                                            </mat-icon>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #elseBlock>
                                    <div style="position: relative;top: -5px;">
                                        <mat-icon *ngFor="let star of  testName.STARS; let x =  index"
                                            (click)="setRating(star.id,y)" [ngClass]="star.styleClass"
                                            (mouseenter)="selectStar(star.id , y)" (mouseleave)="leaveStar(y)"
                                            style="outline: none;margin:1px 5px;font-size: 40px;">{{star.icon}}
                                        </mat-icon>
                                    </div>
                                </ng-template>
                            </div>
                            <!-- 🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸 RATE BUTTONS 🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸 -->
                            <!-- <div style="display: flex;">
                        <button
                            (click)="maxRuleCounterMinus();bmxItem.componentText[y]['RATE'] = 0;leaveStar(y)"
                            *ngIf="bmxItem.componentText[y]['RATE']>0 && y != 0 " style="background-color: #ee7f25;
                            color: white;
                            min-width: 20px;
                            line-height: 19px;
                            padding: 2px 0px;
                            margin: -17px 9px;
                            position: absolute;
                            animation: rubberBand 700ms ease-in  forwards;" mat-button>{{bmxItem.componentText[y]['RATE']}}</button>
                    </div> -->
                            <!-- <div style="display: flex;gap: 60px;flex-direction: column;">
                        <div *ngFor="let criteria of testName.CRITERIA  let x =  index">
                            <button (click)="criteria.RATE = 0;leaveCriteriaStar(y,x)"
                                *ngIf="criteria.RATE>0 && y != 0 " style="background-color: #ee7f25;
                                color: white;
                                min-width: 20px;
                                line-height: 19px;
                                padding: 2px 0px;
                                margin: -3px 7px;
                                position: absolute;
                                animation: rubberBand 700ms ease-in  forwards;" mat-button>{{criteria.RATE}}</button>
                        </div>
                    </div> -->
                            <!-- 🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸 END RATE BUTTONS 🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸 -->
                            <textarea *ngIf="y == 0 && editSingleTableCells"
                                [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                                style="text-align: center; font-weight: bold;color: #324395;" class="row-box"
                                [(ngModel)]="bmxItem.componentSettings[0].ratingScaleTitle"
                                value="bmxItem.componentSettings[0].ratingScaleTitle">
                    </textarea>
                            <div [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'" class="rating-text"
                                *ngIf="y == 0 && !editSingleTableCells">
                                {{bmxItem.componentSettings[0].ratingScaleTitle}}
                            </div>
                        </div>
                        <!--⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ END STARS ⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ -->
                        <!-- IF ELSE TEMPLATE INPUTS  -->
                        <input type="radio" *ngIf="y !== 0  &&  columnName.includes('RadioColumn') == true"
                            [name]="'rate' + y" [checked]="testName[columnName]"
                            (change)="saveRadioColumValue(columnName, y)">
                        <!-- TEXT_AREA COLUMN-->
                        <div style="display: flex;
                    justify-content: center;">
                            <textarea *ngIf="y !== 0  && columnName.includes('Comments') == true && open(y)"
                                [style.width]="'95%'" [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                                cdkTextareaAutosize [cdkAutosizeMinRows]="bmxItem.componentSettings[0].rowHeight" style="text-align: center;height: 32px;width: 358px;font-size: 16px;border: 1px solid grey;
                        animation: bounceIn 500ms ease-in forwards;padding: 0 4px;margin-bottom: 10px;" class="row-box"
                                [(ngModel)]="testName[columnName]"
                                [placeholder]="(columnName=='Comments0')?'comments':(columnName=='Comments1')?'new names':'type here' "
                                rows="4" cols="50">
                    </textarea>
                        </div>
                        <!-- TEST NAME -->
                        <div style="display: flex;align-items: center;justify-content: space-evenly;">
                            <mat-icon
                                style="cursor: pointer;position: absolute;left: 60px;color: #324395;margin-top: -10px;"
                                (click)="playTestNameSound(testName[columnName])" *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('nameCandidates') == true) &&
                         displaySound">
                                volume_up
                            </mat-icon>
                            <p *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('RadioColumn') != true) &&
                        columnName.includes('Comments') != true &&  columnName.includes('RATE') != true &&
                        columnName.includes('kata') != true &&
                        columnName.includes('ratio') != true"
                                [style.font-size]=" (columnName.includes('name') == true)?'27px':'20px'"
                                [style.color]="(columnName.includes('name') == true)?'#324395':'#bababa'"
                                style="text-align: center;padding: 10px 4px;"
                                [innerHTML]="testName[columnName] | safe: 'html'">{{(testName[columnName])}}
                            </p>
                        </div>
                        <!-- <p *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('RadioColumn') != true) &&
                      columnName.includes('Comments') != true && selectedCard == y &&  columnName.includes('RATE') != true &&
                      columnName.includes('name') != true"
                    [style.font-size]=" (columnName.includes('name') == true)?'27px':'20px'"
                    [style.color]="(columnName.includes('name') == true)?'#324395':'#bababa'"
                    style="text-align: center;padding: 5px 4px;"
                    [innerHTML]="testName[columnName] | safe: 'html'">{{(testName[columnName])}}</p> -->

                        <p *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('RadioColumn') != true) &&
                      columnName.includes('Comments') != true && open(y) &&  columnName.includes('RATE') != true &&
                      columnName.includes('name') != true"
                            [style.font-size]=" (columnName.includes('name') == true)?'27px':'20px'"
                            [style.color]="(columnName.includes('name') == true)?'#324395':'#bababa'"
                            style="text-align: center;padding: 5px 4px;"
                            [innerHTML]="testName[columnName] | safe: 'html'">{{(testName[columnName])}}
                        </p>
                    </div>
                    <!-- 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 END TABLE CELLS 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 -->
                </div>
                <!--⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ STARS RENDERER ⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ -->
                <!-- <div *ngIf="selectedCard == y" style="min-width: 230px;background-color:white;display: flex;align-items: center;
        justify-content: center;animation: bounceIn 500ms ease-in  forwards;padding-top: 10px;">
            <div *ngIf="(y !== 0)">
                <div *ngIf="testName.CRITERIA; else elseBlock">
                    <div *ngFor="let criteria of testName.CRITERIA  let x =  index"
                        style="display: flex;flex-direction: column;justify-content: space-between;text-align: center;">
                        <span [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                            style="padding-top:6px;max-width: 200px;">{{criteria.name}}</span>
                        <div style="min-width: 200px;padding-bottom:6px">
                            <mat-icon *ngFor="let star of  criteria.STARS;"
                                (click)="setCriteriaRating(star.id,x,y)" [ngClass]="star.styleClass"
                                (mouseenter)="selectCriteriaStar(star.id,x,y)"
                                (mouseleave)="leaveCriteriaStar(y,x)"
                                style="outline: none;margin:1px 5px;font-size: 40px;">{{star.icon}}</mat-icon>
                        </div>
                    </div>
                </div>
                <ng-template #elseBlock>
                    <div style="position: relative;top: -5px;">
                        <mat-icon *ngFor="let star of  testName.STARS; let x =  index"
                            (click)="setRating(star.id,y)" [ngClass]="star.styleClass"
                            (mouseenter)="selectStar(star.id , y)" (mouseleave)="leaveStar(y)"
                            style="outline: none;margin:1px 5px;font-size: 40px;">{{star.icon}}</mat-icon>
                    </div>
                </ng-template>
            </div>
            <textarea *ngIf="y == 0 && editSingleTableCells"
                [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                style="text-align: center; font-weight: bold;color: #324395;" class="row-box"
                [(ngModel)]="bmxItem.componentSettings[0].ratingScaleTitle"
                value="bmxItem.componentSettings[0].ratingScaleTitle"></textarea>
            <div [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'" class="rating-text"
                *ngIf="y == 0 && !editSingleTableCells">{{bmxItem.componentSettings[0].ratingScaleTitle}}</div>
        </div> -->
                <!--⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ END STARS ⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ -->
            </div>
        </div>
    </div>
</div>
<!--📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱 END TABLE GENERATOR 📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱-->
<app-table *ngIf="showModalTable" (save)="showModalTable = $event" [displayedColumns]="columnsNames"
    [dataSource]="dataSource" (cancelEvent)="showModalTable = false"></app-table>