<button *ngIf="openSettings && bmxClientPageOverview" class="cancel"
  (click)=" replaceBiI_Markers(); openSettings = !openSettings" mat-icon-button>
  <mat-icon class="full-icon" style="font-size: 25px;">check_circle</mat-icon>
</button>
<button *ngIf="!openSettings && bmxClientPageOverview" mat-icon-button class="edit-icon"
  (click)="editTextWithEditor();openSettings = !openSettings;">
  <mat-icon class="full-icon" style="font-size: 25px;">edit</mat-icon>
</button>

<div class="paragram-desktop" *ngIf="currentPage == 1 && template== 'tinder' ; else regularTemplate"
  style="margin: 30px;font-size: 20px;" [innerHTML]="bmxItem.componentText | safe: 'html'"></div>
<ng-template #regularTemplate>
  <div class="paragram-mobile" style="margin: 30px;font-size: 20px;" [innerHTML]="bmxItem.componentText | safe: 'html'">
  </div>
</ng-template>

<ckeditor 
  *ngIf="openSettings && bmxClientPageOverview" 
  [editor]="Editor" 
  id="txtTexto" 
  class="ckeditorcev"
  [config]="config" 
  [(ngModel)]="bmxItem.componentText" 
  [data]="bmxItem.componentText" 
  [ngModelOptions]="{ updateOn: 'blur' }">
</ckeditor>