<div *ngIf="showDialog"><app-survey-dialog (onConfirm)="confirmAction()" (onCancel)="closeDialog()"
        [dialogText]="dialogText"></app-survey-dialog>
</div>
<div *ngIf="showCreationModalVideo && bmxClientPageOverview" id="myModal" class="modal-video"
    style="display: flex;flex-direction: column;gap: 2px;">
    <!-- <label class="container-checkbox">Don't show again
    <input (click)="saveSelection()" type="checkbox" value="Bike" #modalChecked>
    <span class="checkmark">&#x2714;</span>
</label> -->
    <div class="modal-content">
        <video src='{{CREATION_VIDEO_PATH}}' controls></video>

    </div>
    <button class="btn btn-raised close-modal" (click)="showCreationModalVideo=false">close</button>
</div>
<!-- ➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗  SETTINGS ICONS  ➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗ -->
<div *ngIf="bmxClientPageOverview" style="display: flex;
flex-direction: row-reverse;
justify-content: end;
top: -30px;
right: 5px;border: 2px dashed #324395;padding-right: 10px;background: #ffffffd9;
position: absolute;height: 54px;align-items: center">

    <!-- ⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺RAGE INPUTS FOR COLUMN SETTINGS ⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺ -->

    <div *ngIf="isColumnResizerOn && bmxClientPageOverview" class="" style="    display: flex;
    flex-direction: row;
    justify-content: start;background: #ffffffd9;
    gap: 0px;position: absolute;left: -748px;z-index: 1;border: 2px dashed #324395;">
        <!-- RATE -->
        
        <!-- NAME CANDIATES -->
        <div style="display: flex;flex-direction: column;align-items: center;">
            <p style="margin: 0 0 -12px;color: #324395;">candidates
            </p>
            <input type="range" style="margin: 20px;width: 50px;"
                [(ngModel)]="bmxItem.componentSettings[0].nameCandidatesWidth" class="slider-font-size"
                tickInterval="0.5" min="50" max="400" [value]="bmxItem.componentSettings[0].nameCandidatesWidth">
        </div>

        <!-- RATIONALE -->
        <div style="display: flex;flex-direction: column;align-items: center;">
            <p style="margin: 0 0 -12px;color: #324395;">rationale
            </p>
            <input type="range" style="margin: 20px;width: 50px;"
                [(ngModel)]="bmxItem.componentSettings[0].rationalewidth" class="slider-font-size" tickInterval="0.5"
                min="50" max="600" [value]="bmxItem.componentSettings[0].rationalewidth">
        </div>

        <div style="display: flex;flex-direction: column;align-items: center;">
            <p style="margin: 0 0 -12px;color: #324395;">rank
            </p>
            <input type="range" style="margin: 20px;width: 50px;" [(ngModel)]="bmxItem.componentSettings[0].rateWidth"
                class="slider-font-size" tickInterval="0.5" min="50" max="400"
                [value]="bmxItem.componentSettings[0].rateWidth">
        </div>

        <!-- COMMENTS -->
        <div style="display: flex;flex-direction: column;align-items: center;">
            <p style="margin: 0 0 -12px;color: #324395;">comments
            </p>
            <input type="range" style="margin: 20px;width: 50px;"
                [(ngModel)]="bmxItem.componentSettings[0].commentsWidth" class="slider-font-size" tickInterval="0.5"
                min="50" max="400" [value]="bmxItem.componentSettings[0].commentsWidth">
        </div>

        <!-- REST OF THE COLUMNS -->
        <div style="display: flex;flex-direction: column;align-items: center;">
            <p style="margin: 0 0 -12px;color: #324395;">other columns
            </p>
            <input type="range" style="margin: 20px;width: 50px;" [(ngModel)]="bmxItem.componentSettings[0].columnWidth"
                class="slider-font-size" tickInterval="0.5" min="50" max="400"
                [value]="bmxItem.componentSettings[0].columnWidth">
        </div>

        <!-- ROW HEIGHT -->

        <div style="display: flex;flex-direction: column;align-items: center;">
            <p style="margin: 0 0 -12px;color: #324395;">row height</p>
            <input type="range" style="margin: 20px;width: 60px;" [(ngModel)]="bmxItem.componentSettings[0].rowHeight"
                class="slider-font-size" tickInterval="0.5" min="-10" max="20"
                [value]="bmxItem.componentSettings[0].rowHeight">
        </div>

        <!-- FONT -->
        <div style="display: flex;flex-direction: column;align-items: center;">
            <p style="margin: 0 0 -12px;color: #324395;">font size: {{bmxItem.componentSettings[0].fontSize}}</p>
            <input type="range" style="margin: 20px;width: 50px;" [(ngModel)]="bmxItem.componentSettings[0].fontSize"
                class="slider-font-size" tickInterval="0.1" min="2" max="50"
                [value]="bmxItem.componentSettings[0].fontSize">
        </div>

        <!-- RADIO -->
        <div *ngIf="radioColumnCounter > 1" style="display: flex;flex-direction: column;align-items: center;">
            <p style="margin: 0 0 -12px;color: #324395;">radio</p>
            <input type="range" style="margin: 20px;width: 50px;"
                [(ngModel)]="bmxItem.componentSettings[0].radioColumnsWidth" class="slider-font-size" tickInterval="0.5"
                min="50" max="100" [value]="bmxItem.componentSettings[0].radioColumnsWidth">
        </div>
    </div>

    <!-- <mat-slider min="200" max="500" step="10" value="200" [(value)]="bmxItem.componentSettings[0].columnWidth"></mat-slider><p>{{bmxItem.componentSettings[0].columnWidth}}</p> -->
    <!-- ⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺  END  ⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺ -->

    <div style="display: flex; flex-direction: row-reverse;">
        <button (click)="showMatrixMenuBmx()" mat-icon-button matTooltip="{{textToolTip}}" matTooltipPosition="right">
            <mat-icon class="full-icon">{{iconMenuShow}}</mat-icon>
        </button>
        <div *ngIf="showMatrixMenu">
            <button (click)="openDialog('undo'); editSingleTableCells = false" class="add-column"
                matTooltip="undo last change" matTooltipPosition="above" mat-icon-button aria-label="ranking">
                <mat-icon style="font-size: 26px;" class="full-icon">undo</mat-icon>
            </button>
            <button (click)="selectedIndex = ''; editSingleTableCells = false" class="add-column" matTooltip="cancel"
                matTooltipPosition="above" *ngIf="i === selectedIndex" mat-icon-button>
                <mat-icon style="font-size: 25px;" class="full-icon">check_circle</mat-icon>
            </button>
            <button (click)="deleteRows = !deleteRows; editSingleTableCells = false"
                [ngClass]="(!deleteRows)?'add-column':'add-column-active'" matTooltip="delete rows"
                matTooltipPosition="above" mat-icon-button aria-label="ranking">
                <mat-icon style="font-size: 26px;" class="full-icon">delete_outline</mat-icon>
            </button>
            <button (click)="setPronunciation(); editSingleTableCells = false" class="add-column" matTooltip="speech"
                matTooltipPosition="above" mat-icon-button aria-label="ranking">
                <mat-icon style="font-size: 26px;" class="full-icon">mic</mat-icon>
            </button>
            <button (click)="insertTextColumn(); editSingleTableCells = false" class="add-column"
                matTooltip="add text column" matTooltipPosition="above" mat-icon-button aria-label="ranking">
                <mat-icon style="font-size: 26px;" class="full-icon">view_list</mat-icon>
            </button>
            <button (click)="insertRadioColumn(); editSingleTableCells = false" class="add-column"
                matTooltip="add radio column" matTooltipPosition="above" mat-icon-button aria-label="ranking">
                <mat-icon style="font-size: 26px;" class="full-icon">radio_button_checked</mat-icon>
            </button>

            <button (click)="insertRow(); editSingleTableCells = false" class="add-column" matTooltip="insert row"
                matTooltipPosition="above" mat-icon-button aria-label="ranking">
                <mat-icon style="font-size: 26px;" class="full-icon">vertical_distribute</mat-icon>
            </button>
            <button (click)="dragRows = !dragRows; editSingleTableCells = false"
                [ngClass]="(!dragRows)?'add-column':'add-column-active'" matTooltip="drag rows"
                matTooltipPosition="above" mat-icon-button aria-label="ranking">
                <mat-icon style="font-size: 26px;" class="full-icon">drag_indicator</mat-icon>
            </button>
            <button (click)="editSingleTableCells = !editSingleTableCells"
                [ngClass]="(!editSingleTableCells)?'add-column':'add-column-active'" matTooltip="edit table cells"
                matTooltipPosition="above" mat-icon-button aria-label="ranking">
                <mat-icon style="font-size: 26px;" class="full-icon">explicit</mat-icon>
            </button>
        </div>
        <button (click)="showCreationModalVideo=true; editSingleTableCells = false" class="add-column"
            matTooltip="see tutorial" matTooltipPosition="above" mat-icon-button aria-label="ranking">
            <mat-icon style="font-size: 26px;" class="full-icon">video_library</mat-icon>
        </button>

        <button (click)="insertCommentBoxColumn(); editSingleTableCells = false" class="add-column"
            matTooltip="add comments column" matTooltipPosition="above" mat-icon-button aria-label="ranking">
            <mat-icon style="font-size: 26px;" class="full-icon">wysiwyg</mat-icon>
        </button>
        <button (click)="isColumnResizerOn = !isColumnResizerOn; editSingleTableCells = false"
            [ngClass]="(!isColumnResizerOn)?'add-column':'add-column-active'" matTooltip="columns width"
            matTooltipPosition="above" mat-icon-button aria-label="ranking">
            <mat-icon style="font-size: 26px;    z-index: 9;" class="full-icon">swap_horizontal_circle</mat-icon>
        </button>
        <button (click)="selectedIndex = i; editSingleTableCells = false; verifyCritera()" class="add-column"
            matTooltip="setup settings" matTooltipPosition="above" *ngIf="i !== selectedIndex" mat-icon-button>
            <mat-icon style="font-size: 25px;" class="full-icon">edit</mat-icon>
        </button>
        <button (click)="showModalTable = true" class="add-column" matTooltip="Update names" matTooltipPosition="above"
            *ngIf="i !== selectedIndex" mat-icon-button>
            <mat-icon style="font-size: 25px;" class="full-icon"> text_fields</mat-icon>
        </button>
    </div>
</div>
<!-- ➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗  END  ➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗ -->




<!-- 🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧 CONFIG BOX 🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧 -->
<div *ngIf="i === selectedIndex" class="mat-elevation-z4" cdkDrag style="position: absolute;width: 50%;background: #fafafa;z-index: 1;top: -65px;left: 25%;border: #063074 4px solid; overflow: auto;
   max-height: 36rem;
bottom: -2; ">

    <div class="example-handle" cdkDragHandle
        style="display: flex;flex-direction: row;justify-content: center;cursor: move; height: 25px; width: 100%;"><svg
            width="24px" fill="currentColor" viewBox="0 0 24 24">
            <path
                d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
            </path>
            <path d="M0 0h24v24H0z" fill="none"></path>
        </svg></div>

    <!-- Cards Select Ranking-->
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Select Ranking

            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="sm-section3-rkScale">

                <div class="sm-row-box-rkScale">

                    <div style="margin-top: 0.5rem;">
                        <mat-radio-group aria-label="Select an option"
                            [(ngModel)]="this.bmxItem.componentSettings[0].rankType"
                            (change)="rankingTableType(this.bmxItem.componentSettings[0].rankType)">
                            <div style="display: flex;
                        flex-direction: column;
                        gap: 1px;
                        align-items: flex-start;">
                                <mat-radio-button value="dropDown">DropDown</mat-radio-button>
                                <mat-radio-button value="dragAndDrop">Drag & Drop</mat-radio-button>
                                <mat-radio-button value="radio">Radio Buttons</mat-radio-button>
                                <mat-radio-button value="dinamycRadio">Dynamic Radio Buttons</mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>
                    <!-- <select>
              <option value="">
                  <mat-icon style="color:#e6ad11;">rectangle</mat-icon>
              </option>
              <option value="valid" selected>
                  <mat-icon style="color:#76d354;">grade</mat-icon>
              </option>
              <option value="invalid">
                  <mat-icon style="color:#e6ad11;">rectangle</mat-icon>
              </option>
          </select> -->
                </div>
            </div>
        </mat-card-content>

    </mat-card>

    <br>
    <!-- Cards Test Name-->
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Test Names</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div style="text-align: center;"><!-- <p style="margin-left: 30px; color: #757575;">
                    Add the names you want to test
                </p> -->
                <div style="font-size: 16px;margin: 20px;color: #48535e;">PASTE TEST NAMES</div>
                <!-- <div style="font-size: 14px;margin-bottom: 20px;color: lightgray;">Brief Description Here</div> -->
            </div>
            <div>
                <mat-form-field style="width: 90%;margin-left: 20px;" appearance="outline">
                    <mat-label>Add the names you want to test</mat-label>
                    <textarea matInput rows="4" cols="50" [(ngModel)]="testNamesInput" placeholder=""
                        (ngModelChange)="upLoadNamesAndRationales(testNamesInput, true); "></textarea>
                </mat-form-field>

            </div>

        </mat-card-content>

    </mat-card>

    <br>

    <mat-card class="example-card" *ngIf="this.bmxItem.componentSettings[0].rankType !='dragAndDrop'">
        <mat-card-header>
            <mat-card-title>Select Ranking

            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="sm-section3-rkScale">
                <div class="sm-row-box-rkScale">
                    <div>
                        <div style="font-size: 16px;margin-top: 10px;color: #48535e;">SELECT RANK</div>
                        <br>
                        <div
                            style="text-align: center;display: flex;flex-direction: row;justify-content: space-around;">

                            <input [(ngModel)]="rankingScaleValue" type="range" id="volume" name="volume" min="0"
                                [max]="this.rowsCount">
                            {{rankingScaleValue}}
                        </div>
                    </div>

                    <!-- <select>
              <option value="">
                  <mat-icon style="color:#e6ad11;">rectangle</mat-icon>
              </option>
              <option value="valid" selected>
                  <mat-icon style="color:#76d354;">grade</mat-icon>
              </option>
              <option value="invalid">
                  <mat-icon style="color:#e6ad11;">rectangle</mat-icon>
              </option>
          </select> -->
                </div>
            </div>
        </mat-card-content>

    </mat-card>

    <br>
    <!-- Cards Criteria-->
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Category</mat-card-title>
        </mat-card-header>
        <br>
        <div class="sm-section2-rkScale">
            <div class="sm-column-box-rkScale">
                <mat-form-field appearance="outline" style="width: 40%">
                    <mat-label>Category Name</mat-label>
                    <input matInput [(ngModel)]="bmxItem.componentSettings[0].categoryName" placeholder="Category Name"
                        style="font-size: 20px;color: #324395;">
                    <mat-hint>Add a category to the left side</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 40%">
                    <mat-label>Sub category Description</mat-label>
                    <input matInput [(ngModel)]="bmxItem.componentSettings[0].categoryDescription"
                        placeholder="Sub category Description" style="font-size: 20px;color: #ee7f25;">
                    <mat-hint>Enter a brief description of the Sub category </mat-hint>
                    <!-- Hint text for Category Description -->
                </mat-form-field>
            </div>
            <br>

            <div class="sm-row-box-rkScale">
                <mat-checkbox [(ngModel)]="randomizeTestNames">Randomize Names</mat-checkbox>

                <!-- <mat-checkbox [(ngModel)]="bmxItem.componentSettings[1].isImageType">Rank Logos</mat-checkbox> -->
            </div>
            <div class="sm-row-box-rkScale"
                *ngIf="this.bmxItem.componentSettings[0].rankType != 'dragAndDrop' && this.bmxItem.componentSettings[0].rankType != 'dropDown' && this.bmxItem.componentSettings[0].rankType != 'radio' && this.bmxItem.componentSettings[0].rankType != 'dinamycRadio' ">
                <div>
                    <div
                        style="display: flex;flex-direction: column;justify-content: center;gap: 10px;align-items: center; ">
                        <div style="font-size: 16px;margin-top: 10px;color: #48535e;text-align: center;"> Minimum:
                            {{bmxItem.componentSettings[0].minRule}}
                        </div>
                        <input type="number" min="0" [max]="rowsCount"
                            [(ngModel)]="bmxItem.componentSettings[0].minRule" style="width:53px; padding: 0px 10px;"
                            [value]="bmxItem.componentSettings[0].minRule">
                    </div>
                    <div style="font-size: 13px;color: #48535e;">Min number of selections to vote on</div>
                </div>
                <div>
                    <div
                        style="display: flex;flex-direction: column;justify-content:center;gap: 10px;align-items: center;">
                        <div style="font-size: 16px;margin-top: 10px;color: #48535e;text-align: center;"> Maximum:
                            {{bmxItem.componentSettings[0].maxRule}}
                        </div>
                        <input type="number" min="0" [max]="rowsCount"
                            [(ngModel)]="bmxItem.componentSettings[0].maxRule" style="width:53px; padding: 0px 10px;"
                            [value]="bmxItem.componentSettings[0].maxRule">
                    </div>
                    <div style="font-size: 13px;color: #48535e;">Max number of selections to vote on</div>
                </div>
            </div>
        </div>
        <mat-card-content>

        </mat-card-content>

    </mat-card>
    <br>

    <!-- BUTTON-->
    <div class="sm-row-box-rkScale">
        <div class="button-cancel" style=" display: flex;
  flex-direction: column;
  align-items: center; ">
            <button style="outline: none;color:#d35854;   font-size: 21px;" mat-icon-button (click)="selectedIndex = ''"
                aria-label="ranking">
                <mat-icon>cancel</mat-icon>
            </button>
            <p style="margin-top: -11; color: #D35854; font-size: 21px;">Cancel</p>
        </div>
        <div class="button-Done" style="display: flex; flex-direction: column; align-items: center;">
            <button style="outline: none;color:#76d354;   font-size: 21px;" mat-icon-button
                (click)="selectedIndex = '';upLoadNamesAndRationales(testNamesInput, false)" [disabled]="false"
                aria-label="ranking">
                <mat-icon>check_circle</mat-icon>
            </button>
            <p style="margin-top: -1; color: #76D354; font-size: 21px;">Done</p>
        </div>
    </div>
</div>
<!-- 🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧END CONFIG BOX 🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧 -->



<!--🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑 RATING TABLE 🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑-->



<!--💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲 TABLE GENERATOR 💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲-->
<div *ngIf="bmxItem.componentSettings[1].categoryTobeRender == '' || bmxItem.componentSettings[1].categoryTobeRender == bmxItem.componentSettings[0].categoryName"
    class="desktop">
    <div style="display: flex;flex-direction: column;padding-left: 40px;">

        <div style="font-size: 25px;color: #324395;padding-bottom: 10px;">{{bmxItem.componentSettings[0].categoryName}}
        </div>
        <div style="font-size: 18px;color: #ee7f25;padding-bottom: 20px;">
            {{bmxItem.componentSettings[0].categoryDescription}}
        </div>
    </div>
    <!--  [dragula]="draggableBag"  -->
    <div class="scroller-style main-container" cdkDropList (cdkDropListDropped)="checkDragEvetn($event)"
        [cdkDropListAutoScrollDisabled]="false" [cdkDropListLockAxis]="'y'"
        [dragula]="(dragRows)?'DRAGGABLE_ROW':(rankingType == 'dragAndDrop')?draggableBag:''"
        [(dragulaModel)]="bmxItem.componentText" (dragulaModelChange)="checkDragEvetn($event);"
        *ngIf="this.bmxItem.componentSettings[0].rankType == 'dragAndDrop'">

        <div *ngFor="let testName of bmxItem.componentText; let y = index" cdkDrag>

            <div class="test-name-table">

                <!--⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ RANK RENDERER ⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ -->
                <div [ngClass]="(this.bmxItem.componentSettings[0].rankType == 'dragAndDrop')?'rank-column':
                            (this.bmxItem.componentSettings[0].rankType == 'dropDown')?'rank-column1':'rank-column1'"
                    [style.width]="bmxItem.componentSettings[0].rateWidth + 'px'"
                    *ngIf="this.bmxItem.componentSettings[0].rankType != 'dinamycRadio'">
                    <div *ngIf="(y !== 0)">
                        <!--☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️ DROP DOWN ☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️-->
                        <mat-form-field *ngIf="this.bmxItem.componentSettings[0].rankType == 'dropDown'"
                            appearance="outline" style="width: 110px;padding-top: 13px;height: 80px;">
                            <mat-label>Rank 1 to {{rankingScaleValue}}</mat-label>
                            <mat-select matNativeControl [(ngModel)]="bmxItem.componentText[y].RATE"
                                (selectionChange)="setRating(bmxItem.componentText[y].RATE,y)">
                                <mat-option *ngFor="let star of  testName.STARS; let x =  index" [value]="star.icon">
                                    {{star.icon}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!--☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️ DRAG AND DROP ☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️-->
                        <button *ngIf="this.bmxItem.componentSettings[0].rankType == 'dragAndDrop'" mat-button style="background-color:#ee7f25 ;color: white;
                        animation: rubberBand 700ms ease-in  forwards;">{{y}}</button>


                        <!--☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️ RADIO ☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️-->
                        <button (click)="bmxItem.componentText[y]['RATE'] = 0;leaveStar(y);"
                            *ngIf="this.bmxItem.componentText[y].RATE > 0 && this.bmxItem.componentSettings[0].rankType == 'radio'"
                            mat-button style="background-color:#ee7f25 ;color: white;
                        animation: rubberBand 700ms ease-in forwards;">{{this.bmxItem.componentText[y].RATE}}
                        </button>

                        <!-- 🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸 RATE BUTTONS 🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸 -->
                        <div style="display: flex;">
                            <button (click)="bmxItem.componentText[y]['RATE'] = 0;leaveStar(y)" *ngIf="bmxItem.componentText[y]['RATE']>0 && y != 0 &&
                            this.bmxItem.componentSettings[0].rankType == 'dropDown'" style="background-color: #ee7f25;
                                color: white;
                                min-width: 20px;
                                line-height: 19px;
                                padding: 2px 0px;
                                margin: -45px 10px;
                                position: absolute;
                                animation: rubberBand 700ms ease-in  forwards;"
                                mat-button>{{bmxItem.componentText[y]['RATE']}}</button>
                        </div>
                        <!-- 🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸 END RATE BUTTONS 🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸 -->
                    </div>
                    <!-- COLUMN HEADER -->
                    <textarea *ngIf="y == 0 && editSingleTableCells"
                        [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                        style="text-align: center; font-weight: bold;color: #324395;min-width: 200px;" class="row-box"
                        [(ngModel)]="bmxItem.componentSettings[0].ratingScaleTitle"
                        value="bmxItem.componentSettings[0].ratingScaleTitle">
                </textarea>
                    <div class="rating-text" *ngIf="y == 0 && !editSingleTableCells">
                        {{bmxItem.componentSettings[0].ratingScaleTitle}}
                    </div>
                </div>
                <!--⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ RANK STARS ⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ -->

                <div *ngFor="let columnName of columnsNames; let e =  index"
                    style="display: flex;align-items: center;justify-content: center; flex-direction: column;" [ngClass]="(e%2==0)?'red':'blue'"
                    [style.padding]="bmxItem.componentSettings[0].rowHeight + 'px'" [style.width]="(columnName == 'rationale')?bmxItem.componentSettings[0].rationalewidth + 'px':
                (columnName == 'RATE')?bmxItem.componentSettings[0].rateWidth + 'px':
                (columnName == 'nameCandidates')?bmxItem.componentSettings[0].nameCandidatesWidth + 'px':
                (columnName.includes('Comments'))?bmxItem.componentSettings[0].commentsWidth + 'px':
                (columnName.includes('RadioColumn'))?bmxItem.componentSettings[0].radioColumnsWidth + 'px' :
                bmxItem.componentSettings[0].columnWidth + 'px'">

                    <!-- 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 HEADER TEXT AREA 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 -->

                    <div *ngIf="(y == 0)" class="header-title" (mouseenter)="selectedColumn = e">
                        <div>
                            <textarea *ngIf="editSingleTableCells"
                                [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                                style="text-align: center; font-weight: bold;color: #324395;" class="row-box"
                                [(ngModel)]="testName[columnName]" value="testName[columnName]"></textarea>

                            <p style="text-align: center; font-weight: bold;color: #324395;margin: 0 0 0px;padding:5px 1px"
                                [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                                *ngIf=" !editSingleTableCells">{{testName[columnName]}}
                            </p>
                        </div>
                        <div *ngIf="selectedColumn === e && bmxClientPageOverview && !editSingleTableCells" style="display: flex;flex-direction: row;justify-content: space-around;
                        position: absolute;top: 35px;background: white;border: #324395 dashed 1px;"
                            (mouseleave)="selectedColumn = ''">
                            <span class="header-delete-icon" matTooltip="double click to delete column"
                                matTooltipPosition="above" (dblclick)="openDialog('delete column', columnName)">x</span>
                                <mat-icon style="cursor: pointer;" matTooltip="move column to the left"
                                matTooltipPosition="above" (click)="swapColumnsLeft(e)">chevron_left</mat-icon>
                            <mat-icon style="cursor: pointer;" (click)="swapColumns(e)">chevron_right</mat-icon>
                        </div>
                    </div>

                    <!-- 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 END HEADER TEXT AREA  🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 -->


                    <!-- 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 TABLE CELLS  🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 -->
                    <div [style.width]=" columnName == 'nameCandidates'?bmxItem.componentSettings[0].nameCandidatesWidth-20 + 'px':''">
                        <!-- IF ELSE TEMPLATE INPUTS  -- BELOW RADION BUTTONS COLUMN -->
                        <input type="radio" *ngIf="y !== 0  &&  columnName.includes('RadioColumn') == true"
                            [name]="columnName + i" [checked]="testName[columnName]"
                            (click)="saveRadioColumValue(columnName, y)">


                        <!-- TEXT_AREA COLUMN COMMENTS-->
                        <textarea *ngIf="y !== 0  && columnName.includes('Comments') == true "
                            [style.width]="(bmxItem.componentSettings[0].commentsWidth - 20) +'px'"
                            [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'" cdkTextareaAutosize
                            [cdkAutosizeMinRows]="bmxItem.componentSettings[0].rowHeight"
                            style="text-align: center;height: 32px;width: 358px;font-size: 16px;border: 1px solid #dddddd;"
                            class="row-box" [(ngModel)]="testName[columnName]"></textarea>

                        <img class="row-box" style="width:300px;" [src]="testName[columnName]" *ngIf=" bmxItem.componentSettings[1].isImageType && (y !== 0) && (columnName == 'name' ||
                         columnName == 'nameCandidates')">


                        <!-- TEXT AREA TO EDIT TABLE CELL CONTENT -->
                        <textarea *ngIf="y !== 0  && editSingleTableCells && (columnName.includes('RadioColumn') != true) &&
                   columnName.includes('Comments') != true &&  columnName.includes('RATE') != true"
                            [style.width]="(columnName == 'rationale')?(bmxItem.componentSettings[0].rationalewidth - 10) + 'px':(bmxItem.componentSettings[0].columnWidth -10) +'px'"
                            [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'" cdkTextareaAutosize
                            [cdkAutosizeMinRows]="bmxItem.componentSettings[0].rowHeight"
                            style="outline: none;text-align: center;margin: 0 10px;" class="row-box"
                            [(ngModel)]="testName[columnName]"></textarea>

                        <!-- <p *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('RadioColumn') != true) &&
                      columnName.includes('Comments') != true &&  columnName.includes('RATE') != true &&
                       ((columnName.includes('name') != true)||!bmxItem.componentSettings[1].isImageType)"
                        [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                        [style.width]="(columnName == 'rationale')?bmxItem.componentSettings[0].rationalewidth + 'px':bmxItem.componentSettings[0].columnWidth +'px'"
                        style="text-align: center;margin: 0 0 0px;"
                        [innerHTML]="testName[columnName] | safe: 'html'">{{testName[columnName]}}</p> -->

                        <div style="display: flex;">
                            <mat-icon style="cursor: pointer;color: #324395;"
                                (click)="playTestNameSound(testName[columnName])"
                                *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('nameCandidates') == true) && displaySound">
                                volume_up
                            </mat-icon>
                            <p *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('RadioColumn') != true)
                                &&  columnName.includes('Comments') != true &&  columnName.includes('RATE') != true"
                                [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                                [style.width]="(columnName == 'rationale')?bmxItem.componentSettings[0].rationalewidth + 'px':(columnName == 'nameCandidates')?100 +'%':bmxItem.componentSettings[0].rationalewidth + 'px'"
                                style="text-align: center;margin: 0 0 0px;padding: 4px"
                                [innerHTML]="testName[columnName] | safe: 'html'">{{testName[columnName]}}
                            </p>
                        </div>
                    </div>
                    <!-- 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 END TABLE CELLS 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 -->
                </div>
                <div *ngIf="(y !== 0) && bmxClientPageOverview && deleteRows"
                    style="min-width: 100px; position: absolute;right: 0px;">
                    <button style="outline: none;margin:3px -8px;" (dblclick)="deletRow(y)" mat-icon-button
                        matTooltip="double click to delete row" matTooltipPosition="left" aria-label="ranking">
                        <mat-icon>delete_outline</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="scroller-style main-container" *ngIf="this.bmxItem.componentSettings[0].rankType != 'dragAndDrop'">

        <div *ngFor="let testName of bmxItem.componentText; let y = index">

            <div class="test-name-table">

                <!--⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ RANK RENDERER ⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ -->
                <div [ngClass]="(this.bmxItem.componentSettings[0].rankType == 'dragAndDrop')?'rank-column':
                        (this.bmxItem.componentSettings[0].rankType == 'dropDown')?'rank-column1':'rank-column1'"
                    [style.width]="bmxItem.componentSettings[0].rateWidth + 'px'"
                    *ngIf="this.bmxItem.componentSettings[0].rankType != 'dinamycRadio'">
                    <div *ngIf="(y !== 0)">
                        <!--☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️ DROP DOWN ☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️-->
                        <mat-form-field *ngIf="this.bmxItem.componentSettings[0].rankType == 'dropDown'"
                            appearance="outline" style="width: 110px;padding-top: 13px;height: 80px;">
                            <mat-label>Rank 1 to {{rankingScaleValue}}</mat-label>
                            <mat-select matNativeControl [(ngModel)]="bmxItem.componentText[y].RATE"
                                (selectionChange)="setRating(bmxItem.componentText[y].RATE,y)">
                                <mat-option *ngFor="let star of  testName.STARS; let x =  index" [value]="star.icon">
                                    {{star.icon}}</mat-option>
                            </mat-select>
                        </mat-form-field>


                        <!--☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️ DRAG AND DROP ☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️-->
                        <button *ngIf="this.bmxItem.componentSettings[0].rankType == 'dragAndDrop'" mat-button style="background-color:#ee7f25 ;color: white;
                    animation: rubberBand 700ms ease-in  forwards;">{{y}}</button>


                        <!--☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️ RADIO ☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️☂️-->
                        <button (click)="bmxItem.componentText[y]['RATE'] = 0;leaveStar(y);"
                            *ngIf="this.bmxItem.componentText[y].RATE > 0 && (this.bmxItem.componentSettings[0].rankType == 'radio'|| this.bmxItem.componentSettings[0].rankType == 'dinamycRadio')"
                            mat-button style="background-color:#ee7f25 ;color: white;
                    animation: rubberBand 700ms ease-in  forwards;">{{this.bmxItem.componentText[y].RATE}}</button>

                        <!-- 🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸 RATE BUTTONS 🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸 -->
                        <div style="display: flex;">
                            <button (click)="bmxItem.componentText[y]['RATE'] = 0;leaveStar(y)" *ngIf="bmxItem.componentText[y]['RATE']>0 && y != 0 &&
                        this.bmxItem.componentSettings[0].rankType == 'dropDown'" style="background-color: #ee7f25;
                            color: white;
                            min-width: 20px;
                            line-height: 19px;
                            padding: 2px 0px;
                            margin: -52px 10px;
                                position: absolute;
                                animation: rubberBand 700ms ease-in  forwards;height: 33px;"
                                mat-button>{{bmxItem.componentText[y]['RATE']}}</button>
                        </div>
                        <!-- 🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸 END RATE BUTTONS 🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸 -->
                    </div>
                    <!-- COLUMN HEADER -->
                    <textarea *ngIf="y == 0 && editSingleTableCells"
                        [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                        style="text-align: center; font-weight: bold;color: #324395;min-width: 200px;" class="row-box"
                        [(ngModel)]="bmxItem.componentSettings[0].ratingScaleTitle"
                        value="bmxItem.componentSettings[0].ratingScaleTitle">
                </textarea>
                    <div class="rating-text" *ngIf="y == 0 && !editSingleTableCells">
                        {{bmxItem.componentSettings[0].ratingScaleTitle}}
                    </div>
                </div>
                <!--⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ RANK STARS ⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ -->

                <div *ngFor="let columnName of columnsNames; let e =  index"
                    style="display: flex;align-items: center;justify-content: center; flex-direction: column;" [ngClass]="(e%2==0)?'red':'blue'"
                    [style.padding]="bmxItem.componentSettings[0].rowHeight + 'px'" [style.width]="(columnName == 'rationale')?bmxItem.componentSettings[0].rationalewidth + 'px':
                    (columnName == 'RATE')?bmxItem.componentSettings[0].rateWidth + 'px':
                    (columnName == 'nameCandidates')?bmxItem.componentSettings[0].nameCandidatesWidth + 'px':
                    (columnName.includes('Comments'))?bmxItem.componentSettings[0].commentsWidth + 'px':
                    (columnName.includes('RadioColumn'))?bmxItem.componentSettings[0].radioColumnsWidth + 'px' :
                    bmxItem.componentSettings[0].columnWidth + 'px'">

                    <!-- 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 HEADER TEXT AREA 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 -->

                    <div *ngIf="(y == 0)" class="header-title" (mouseenter)="selectedColumn = e" >
                        <div>
                            <textarea *ngIf="editSingleTableCells"
                                [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                                style="text-align: center; font-weight: bold;color: #324395;" class="row-box"
                                [(ngModel)]="testName[columnName]" value="testName[columnName]"></textarea>

                            <p style="text-align: center; font-weight: bold;color: #324395;margin: 0 0 0px;padding:5px 1px"
                                [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                                *ngIf=" !editSingleTableCells">{{testName[columnName]}}
                            </p>
                        </div>
                        <div *ngIf="selectedColumn === e && bmxClientPageOverview && !editSingleTableCells" style="display: flex;flex-direction: row;justify-content: space-around;
                        position: absolute;top: 35px;background: white;border: #324395 dashed 1px;"
                            (mouseleave)="selectedColumn = ''">
                            <span class="header-delete-icon" matTooltip="double click to delete column"
                                matTooltipPosition="above" (dblclick)="openDialog('delete column', columnName)">x</span>
                                <mat-icon style="cursor: pointer;" matTooltip="move column to the left"
                                matTooltipPosition="above" (click)="swapColumnsLeft(e)">chevron_left</mat-icon>

                            <mat-icon style="cursor: pointer;" (click)="swapColumns(e)">chevron_right</mat-icon>

                        </div>
                    </div>

                    <!-- 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 END HEADER TEXT AREA  🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 -->


                    <!-- 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 TABLE CELLS  🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 -->
                    <div [style.width]=" columnName == 'nameCandidates'?bmxItem.componentSettings[0].nameCandidatesWidth-20 + 'px':''">
                        <!-- IF ELSE TEMPLATE INPUTS  -- BELOW RADION BUTTONS COLUMN -->
                        <input type="radio"
                            *ngIf="y !== 0  &&  columnName.includes('RadioColumn') == true && (this.rankingType == 'radio')"
                            [name]="columnName + i" [checked]="testName[columnName]"
                            (click)="saveRadioColumValue(columnName, y); ">
                        <!-- IF ELSE TEMPLATE INPUTS  -- BELOW RADION BUTTONS COLUMN -->
                        <input type="radio"
                            *ngIf="y !== 0  &&  columnName.includes('RadioColumn') == true && this.rankingType == 'dinamycRadio'"
                            [name]="bmxItem.componentText[y].nameCandidates" [checked]="testName[columnName]"
                            (click)="saveRadioColumValue(columnName, y);">

                        <!-- TEXT_AREA COLUMN COMMENTS-->
                        <textarea *ngIf="y !== 0  && columnName.includes('Comments') == true"
                            [style.width]="(bmxItem.componentSettings[0].commentsWidth - 20) +'px'"
                            [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'" cdkTextareaAutosize
                            [cdkAutosizeMinRows]="bmxItem.componentSettings[0].rowHeight"
                            style="text-align: center;height: 32px;width: 358px;font-size: 16px;border: 1px solid #dddddd;"
                            class="row-box" [(ngModel)]="testName[columnName]">
                    </textarea>

                        <img class="row-box" style="width:300px;" [src]="testName[columnName]" *ngIf=" bmxItem.componentSettings[1].isImageType && (y !== 0) && (columnName == 'name' ||
                     columnName == 'nameCandidates')">


                        <!-- TEXT AREA TO EDIT TABLE CELL CONTENT -->
                        <textarea *ngIf="y !== 0  && editSingleTableCells && (columnName.includes('RadioColumn') != true) &&
                        columnName.includes('Comments') != true &&  columnName.includes('RATE') != true"
                            [style.width]="(columnName == 'rationale')?(bmxItem.componentSettings[0].rationalewidth - 10) + 'px':(bmxItem.componentSettings[0].columnWidth -10) +'px'"
                            [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'" cdkTextareaAutosize
                            [cdkAutosizeMinRows]="bmxItem.componentSettings[0].rowHeight"
                            style="outline: none;text-align: center;margin: 0 10px;" class="row-box"
                            [(ngModel)]="testName[columnName]">
                    </textarea>

                        <!-- <p *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('RadioColumn') != true) &&
                  columnName.includes('Comments') != true &&  columnName.includes('RATE') != true &&
                   ((columnName.includes('name') != true)||!bmxItem.componentSettings[1].isImageType)"
                    [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                    [style.width]="(columnName == 'rationale')?bmxItem.componentSettings[0].rationalewidth + 'px':bmxItem.componentSettings[0].columnWidth +'px'"
                    style="text-align: center;margin: 0 0 0px;"
                    [innerHTML]="testName[columnName] | safe: 'html'">{{testName[columnName]}}</p> -->

                        <div style="display: flex;">
                            <mat-icon style="cursor: pointer;color: #324395;"
                                (click)="playTestNameSound(testName[columnName])"
                                *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('nameCandidates') == true) && displaySound">
                                volume_up
                            </mat-icon>
                            <p *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('RadioColumn') != true)
                            &&  columnName.includes('Comments') != true &&  columnName.includes('RATE') != true"
                                [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                                [style.width]="(columnName == 'rationale')?bmxItem.componentSettings[0].rationalewidth + 'px':(columnName == 'nameCandidates')?100 +'%':bmxItem.componentSettings[0].rationalewidth + 'px'"
                                style="text-align: center;margin: 0 0 0px;padding: 4px"
                                [innerHTML]="testName[columnName] | safe: 'html'">{{testName[columnName]}}
                            </p>
                        </div>
                    </div>
                    <!-- 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 END TABLE CELLS 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 -->
                </div>
                <div *ngIf="(y !== 0) && bmxClientPageOverview && deleteRows"
                    style="min-width: 100px; position: absolute;right: 0px;">
                    <button style="outline: none;margin:3px -8px;" (dblclick)="deletRow(y)" mat-icon-button
                        matTooltip="double click to delete row" matTooltipPosition="left" aria-label="ranking">
                        <mat-icon>delete_outline</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!--📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱 TABLE 📱 MOBILE 📱 GENERATOR 📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱-->
<div *ngIf="bmxItem.componentSettings[1].categoryTobeRender == '' || bmxItem.componentSettings[1].categoryTobeRender == bmxItem.componentSettings[0].categoryName "
    class="mobile">
    <div style="display: flex;flex-direction: column;text-align: center;align-items: center;">

        <div style="font-size: 25px;color: #324395;padding-bottom: 10px;margin: 5px;">
            {{bmxItem.componentSettings[0].categoryName}}
        </div>
        <div style="font-size: 18px;color: #ee7f25;padding-bottom: 20px;margin: 10px;">
            {{bmxItem.componentSettings[0].categoryDescription}}</div>
        <!-- <button mat-raised-button *ngIf="this.bmxItem.componentSettings[0].rankType == 'dragAndDrop'"
        style="font-size: 18px;color: #ee7f25;width: 150;" (click)="toggleScrolling()" cdkDrag>
        {{(allowScrolling)?'DRAG':'SCROLL'}} </button> -->

    </div>

    <!-- <div class="scroller-style main-container" style="margin-bottom: 45px;" cdkDropList
(cdkDropListDropped)="checkDragEvetn($event)" [cdkDropListAutoScrollDisabled]="false"
[cdkDropListLockAxis]="'y'">

    <div *ngFor="let testName of bmxItem.componentText; let y = index" style="margin-bottom: 10px;" cdkDrag
        [ngClass]="(y == 0)?'ROW-CERO':''"> -->

    <div class="scroller-style main-container" *ngIf="this.bmxItem.componentSettings[0].rankType == 'dragAndDrop'"
        cdkDropList (cdkDropListDropped)="checkDragEvetn($event)" [cdkDropListAutoScrollDisabled]="false"
        [cdkDropListLockAxis]="'y'">

        <div *ngFor="let testName of bmxItem.componentText; let y = index" style="margin-bottom: 10px;" cdkDrag>

            <div class="test-name-table" *ngIf="(y != 0)"
                [style.touch-action]="(this.bmxItem.componentSettings[0].rankType == 'dragAndDrop')?'none':''"
                [style.height]="(selectedCard == y && (this.bmxItem.componentSettings[0].rankType == 'radio'|| this.bmxItem.componentSettings[0].rankType == 'dinamycRadio'))?'200px':(selectedCard == y)?'':'45px'">

                <button *ngIf="this.bmxItem.componentSettings[0].rankType == 'dragAndDrop'&& y != 0  " style="background-color: #ee7f25;color: white;margin: 5px;width: 30px;
                height: 30px;
                line-height: 30px;right: 7px;
                position: absolute;animation: rubberBand 700ms ease-in  forwards;" mat-icon-button>{{y}}</button>


                <button (click)="bmxItem.componentText[y]['RATE'] = 0;saveRadioColumValue('', y)"
                    *ngIf="bmxItem.componentText[y]['RATE']>0 && y != 0 " style="background-color: #ee7f25;color: white;margin: 5px;width: 30px;
                height: 30px;
                line-height: 30px;right: 7px;
                position: absolute;animation: rubberBand 700ms ease-in  forwards;"
                    mat-icon-button>{{bmxItem.componentText[y]['RATE']}}</button>
                <!-- 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 HEADER TEXT AREA 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 -->


                <div style="display: flex;gap: 23px;padding-top: 10px;position: absolute;right: 24px;">
                    <div *ngFor="let criteria of testName.CRITERIA  let x =  index">
                        <button *ngIf="criteria.RATE>0 && y != 0 " style="background-color: #ee7f25;
                         color: white;
                         min-width: 20px;
                         line-height: 20px;
                         padding: 2px 0px;
                         position: absolute;
                         animation: rubberBand 700ms ease-in  forwards;" mat-button>{{criteria.RATE}}</button>
                    </div>
                </div>
                <!-- <button *ngIf="this.bmxItem.componentSettings[0].rankType == 'dragAndDrop'" mat-button
            style="background-color:#ee7f25 ;color: white;">{{y}}</button> -->

                <div *ngFor="let columnName of columnsNames; let e =  index">
                    <!-- 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 END HEADER TEXT AREA  🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 -->

                    <!-- 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 TABLE CELLS  🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 -->

                    <div [style.width]=" columnName == 'nameCandidates'?bmxItem.componentSettings[0].nameCandidatesWidth-20 + 'px':''">

                        <!-- IF ELSE TEMPLATE INPUTS  -->
                        <div style="background-color: lightgray;">
                            <div *ngIf="selectedCard == y" style="position: absolute;margin-top: 5px;"
                                [style.left.px]="(e * 40)">
                                <span *ngIf="y !== 0  &&  columnName.includes('RadioColumn') == true"
                                    style="padding-right: 8px;">{{e - 1}}</span>
                                <input type="radio" *ngIf="y !== 0  &&  columnName.includes('RadioColumn') == true"
                                    [name]="'rate' + y" [checked]="testName[columnName]"
                                    (change)="saveRadioColumValue(columnName, y)">
                            </div>
                        </div>

                        <img (click)="(selectedCard == y)?selectedCard = '':selectedCard = y" class="row-box"
                            style="width: 300px;" [src]="testName[columnName]"
                            *ngIf=" bmxItem.componentSettings[1].isImageType && (y !== 0) && (columnName == 'name' || columnName == 'nameCandidates')">
                        <!-- TEXT_AREA COMMENTS-->
                        <div style="display: flex;
                    justify-content: center;">
                            <textarea *ngIf="y !== 0  && columnName.includes('Comments') == true && selectedCard == y"
                                [style.width]="'95%'" [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                                cdkTextareaAutosize [cdkAutosizeMinRows]="bmxItem.componentSettings[0].rowHeight" style="text-align: center;height: 64px;width: 358px;font-size: 16px;border: 1px solid grey;
                        animation: bounceIn 500ms ease-in forwards;padding: 0 4px;margin: 30px 0;" class="row-box"
                                [(ngModel)]="testName[columnName]" placeholder="comments" rows="4" cols="50"></textarea>
                        </div>


                        <!-- TEST NAME CELL -->
                        <!-- <p (click)="(selectedCard == y)?selectedCard = '':selectedCard = y" *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('RadioColumn') != true) &&
                          columnName.includes('Comments') != true &&
                          columnName.includes('kata') != true &&
                          columnName.includes('ratio') != true"
                        [style.font-size]=" (columnName.includes('name') == true)?'27px':'20px'"
                        [style.color]="(columnName.includes('name') == true)?'#324395':'#bababa'"
                        style="text-align: center;padding: 10px 4px;">{{(testName[columnName])}}</p> -->
                        <div style="display: flex;align-items: center;justify-content: space-evenly;">
                            <mat-icon
                                style="cursor: pointer;position: absolute;left: 60px;color: #324395;margin-top: -10px;"
                                (click)="playTestNameSound(testName[columnName])" *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('nameCandidates') == true) &&
                                 displaySound">
                                volume_up
                            </mat-icon>
                            <p (click)="(selectedCard == y)?selectedCard = '':selectedCard = y" *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('RadioColumn') != true) &&
                              columnName.includes('Comments') != true &&  columnName.includes('RATE') != true &&
                              columnName.includes('kata') != true &&
                              columnName.includes('ratio') != true"
                                [style.font-size]=" (columnName.includes('name') == true)?'27px':'20px'"
                                [style.color]="(columnName.includes('name') == true)?'#324395':'#bababa'"
                                style="text-align: center;padding: 10px 4px;">{{(testName[columnName])}}</p>
                        </div>


                        <p *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('RadioColumn') != true) &&
                          columnName.includes('Comments') != true && selectedCard == y &&
                          columnName.includes('name') != true"
                            [style.font-size]=" (columnName.includes('name') == true)?'27px':'20px'"
                            [style.color]="(columnName.includes('name') == true)?'#324395':'#bababa'"
                            style="text-align: center;padding: 5px 4px;"
                            [innerHTML]="testName[columnName] | safe: 'html'">{{testName[columnName]}}</p>


                    </div>
                    <!-- 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 END TABLE CELLS 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 -->
                </div>
                <mat-form-field *ngIf="this.bmxItem.componentSettings[0].rankType == 'dropDown' && selectedCard == y"
                    appearance="outline" style="width: 130px;padding-top: 13px;height: 80px;">
                    <mat-label>Rank 1 to {{rankingScaleValue}}</mat-label>
                    <mat-select matNativeControl [(ngModel)]="bmxItem.componentText[y].RATE"
                        (selectionChange)="setRating(bmxItem.componentText[y].RATE,y)">
                        <mat-option *ngFor="let star of  testName.STARS; let x =  index" [value]="star.icon">
                            {{star.icon}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </div>

    </div>
    <div class="scroller-style main-container" *ngIf="this.bmxItem.componentSettings[0].rankType != 'dragAndDrop'">

        <div *ngFor="let testName of bmxItem.componentText; let y = index" style="margin-bottom: 10px;">

            <div class="test-name-table" *ngIf="(y != 0)"
                [style.touch-action]="(this.bmxItem.componentSettings[0].rankType == 'dragAndDrop')?'none':''"
                [style.height]="(selectedCard == y && (this.bmxItem.componentSettings[0].rankType == 'radio'|| this.bmxItem.componentSettings[0].rankType == 'dinamycRadio'))?'200px':(selectedCard == y)?'':'45px'">

                <button *ngIf="this.bmxItem.componentSettings[0].rankType == 'dragAndDrop'&& y != 0  " style="background-color: #ee7f25;color: white;margin: 5px;width: 30px;
            height: 30px;
            line-height: 30px;right: 7px;
            position: absolute;animation: rubberBand 700ms ease-in  forwards;" mat-icon-button>{{y}}</button>


                <button (click)="bmxItem.componentText[y]['RATE'] = 0;saveRadioColumValue('', y)"
                    *ngIf="bmxItem.componentText[y]['RATE']>0 && y != 0 " style="background-color: #ee7f25;color: white;margin: 5px;width: 30px;
            height: 30px;
            line-height: 30px;right: 7px;
            position: absolute;animation: rubberBand 700ms ease-in  forwards;"
                    mat-icon-button>{{bmxItem.componentText[y]['RATE']}}</button>
                <!-- 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 HEADER TEXT AREA 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 -->


                <div style="display: flex;gap: 23px;padding-top: 10px;position: absolute;right: 24px;">
                    <div *ngFor="let criteria of testName.CRITERIA  let x =  index">
                        <button *ngIf="criteria.RATE>0 && y != 0 " style="background-color: #ee7f25;
                     color: white;
                     min-width: 20px;
                     line-height: 20px;
                     padding: 2px 0px;
                     position: absolute;
                     animation: rubberBand 700ms ease-in  forwards;" mat-button>{{criteria.RATE}}</button>
                    </div>
                </div>
                <!-- <button *ngIf="this.bmxItem.componentSettings[0].rankType == 'dragAndDrop'" mat-button
        style="background-color:#ee7f25 ;color: white;">{{y}}</button> -->

                <div *ngFor="let columnName of columnsNames; let e =  index">
                    <!-- 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 END HEADER TEXT AREA  🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 -->

                    <!-- 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 TABLE CELLS  🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 -->
                    <div [style.width]=" columnName == 'nameCandidates'?bmxItem.componentSettings[0].nameCandidatesWidth-20 + 'px':''">

                        <!-- IF ELSE TEMPLATE INPUTS  -->
                        <div style="background-color: lightgray;">
                            <div *ngIf="selectedCard == y" style="position: absolute;margin-top: 5px;"
                                [style.left.px]="(e * 40)">
                                <span *ngIf="y !== 0  &&  columnName.includes('RadioColumn') == true"
                                    style="padding-right: 8px;">{{e - 1}}</span>
                                <input type="radio" *ngIf="y !== 0  &&  columnName.includes('RadioColumn') == true"
                                    [name]="'rate' + y" [checked]="testName[columnName]"
                                    (change)="saveRadioColumValue(columnName, y)">
                            </div>
                        </div>

                        <img (click)="(selectedCard == y)?selectedCard = '':selectedCard = y" class="row-box"
                            style="width: 300px;" [src]="testName[columnName]"
                            *ngIf=" bmxItem.componentSettings[1].isImageType && (y !== 0) && (columnName == 'name' || columnName == 'nameCandidates')">
                        <!-- TEXT_AREA COMMENTS-->
                        <div style="display: flex;
                justify-content: center;">
                            <textarea *ngIf="y !== 0  && columnName.includes('Comments') == true && selectedCard == y"
                                [style.width]="'95%'" [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                                cdkTextareaAutosize [cdkAutosizeMinRows]="bmxItem.componentSettings[0].rowHeight" style="text-align: center;height: 64px;width: 358px;font-size: 16px;border: 1px solid grey;
                    animation: bounceIn 500ms ease-in forwards;padding: 0 4px;margin: 30px 0;" class="row-box"
                                [(ngModel)]="testName[columnName]" placeholder="comments" rows="4" cols="50"></textarea>
                        </div>


                        <!-- TEST NAME CELL -->
                        <!-- <p (click)="(selectedCard == y)?selectedCard = '':selectedCard = y" *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('RadioColumn') != true) &&
                      columnName.includes('Comments') != true &&
                      columnName.includes('kata') != true &&
                      columnName.includes('ratio') != true"
                    [style.font-size]=" (columnName.includes('name') == true)?'27px':'20px'"
                    [style.color]="(columnName.includes('name') == true)?'#324395':'#bababa'"
                    style="text-align: center;padding: 10px 4px;">{{(testName[columnName])}}</p> -->
                        <div style="display: flex;align-items: center;justify-content: space-evenly;">
                            <mat-icon
                                style="cursor: pointer;position: absolute;left: 60px;color: #324395;margin-top: -10px;"
                                (click)="playTestNameSound(testName[columnName])" *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('nameCandidates') == true) &&
                             displaySound">
                                volume_up
                            </mat-icon>
                            <p (click)="(selectedCard == y)?selectedCard = '':selectedCard = y" *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('RadioColumn') != true) &&
                          columnName.includes('Comments') != true &&  columnName.includes('RATE') != true &&
                          columnName.includes('kata') != true &&
                          columnName.includes('ratio') != true"
                                [style.font-size]=" (columnName.includes('name') == true)?'27px':'20px'"
                                [style.color]="(columnName.includes('name') == true)?'#324395':'#bababa'"
                                style="text-align: center;padding: 10px 4px;">{{(testName[columnName])}}</p>
                        </div>


                        <p *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('RadioColumn') != true) &&
                      columnName.includes('Comments') != true && selectedCard == y &&
                      columnName.includes('name') != true"
                            [style.font-size]=" (columnName.includes('name') == true)?'27px':'20px'"
                            [style.color]="(columnName.includes('name') == true)?'#324395':'#bababa'"
                            style="text-align: center;padding: 5px 4px;"
                            [innerHTML]="testName[columnName] | safe: 'html'">{{testName[columnName]}}</p>


                    </div>
                    <!-- 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 END TABLE CELLS 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 -->
                </div>
                <mat-form-field *ngIf="this.bmxItem.componentSettings[0].rankType == 'dropDown' && selectedCard == y"
                    appearance="outline" style="width: 130px;padding-top: 13px;height: 80px;">
                    <mat-label>Rank 1 to {{rankingScaleValue}}</mat-label>
                    <mat-select matNativeControl [(ngModel)]="bmxItem.componentText[y].RATE"
                        (selectionChange)="setRating(bmxItem.componentText[y].RATE,y)">
                        <mat-option *ngFor="let star of  testName.STARS; let x =  index" [value]="star.icon">
                            {{star.icon}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </div>

    </div>
</div>

<!--🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑END RATING TABLE 🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑-->
<app-table *ngIf="showModalTable" (save)="showModalTable = $event" [displayedColumns]="columnsNames"
    [dataSource]="dataSource" (cancelEvent)="showModalTable = false"></app-table>