<div *ngIf="this.bmxStore.messageInfo().display" class="message-container">
  <div class="message-card">
    <mat-icon class="icon">{{ this.bmxStore.messageInfo().icon }}</mat-icon>
    <h2 class="ti">{{ this.bmxStore.messageInfo().message}}</h2>
    <h3>{{ this.bmxStore.messageInfo().description }}</h3>
    <div class="button-container">
      <button class="btn accept-button" (click)="onAccept()">Accept</button>
      <button class="btn cancel-button" (click)="onCancel()">Cancel</button>
    </div>
  </div>
</div>

