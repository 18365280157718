<div class="container" style="display: flex; gap: 5%;max-width: 100%;     padding: 0;">
    <div class="email-container" style="width: 100% ">
        <h2 style="margin: 20px;">Project TEST BrandMatrix Setup</h2>
        <!-- HEADER INPUTS -->
        <div class="header" style="display: flex;flex-direction: row;justify-content: space-between;">

            <div>
                <div style="margin: 23px 20px;font-size: 20px;color:#063074;font-weight: 800;">Email Settings</div>
                <div
                    style="line-height: 1; margin-left: 40px;font-size: 15px;
                color:grey;font-weight: 100;width: 300px;display: flex;flex-direction: row; justify-content: space-between;margin-bottom: 10px;">
                    Send Director Confirmation
                    Emails
                    <input type="checkbox" [checked]="dirConfirm" (change)="dirConfirm = !dirConfirm" />
                </div>

                <div
                    style="line-height: 1; margin-left: 40px;font-size: 15px;
                color:grey;font-weight: 100;width: 300px;display: flex;flex-direction: row; justify-content: space-between;">
                    Send Department Confirmation
                    Emails
                    <input type="checkbox" [checked]="deptConfirm" (change)="deptConfirm = !deptConfirm" />
                </div>
            </div>


            <div style="display: flex;flex-direction: column;">
                <div style="display: flex;flex-direction: row;justify-content: space-evenly;">

                    <div class="options" style="display: flex;justify-content: space-around;">
                        <div>
                            <div style="margin: 20px;font-size: 20px;color:#063074;font-weight: 800;">Templates</div>
                            <mat-form-field appearance="outline" style="margin-left: 20px;">
                                <mat-label>Choose Template</mat-label>
                                <mat-select class="selectLabels" [(ngModel)]="emailTemp"
                                    (selectionChange)="changeTemplate(emailTemp)">
                                    <mat-option *ngFor="let EMAIL_TEMPLATE of EMAIL_TEMPLATES" [value]="EMAIL_TEMPLATE">
                                        {{EMAIL_TEMPLATE}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <div style="margin: 20px;font-size: 20px;color:#063074;font-weight: 800;">Link Type</div>
                            <mat-form-field appearance="outline" style="margin-left: 20px;">
                                <mat-label>Direct Link</mat-label>
                                <mat-select class="selectLabels" [(ngModel)]="linkType">
                                    <mat-option *ngFor="let link of LINK_TYPE" [value]="link">
                                        {{link}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button mat-raised-button
                                style="background-color: #003180;color:white;margin: 10px 20px 0 20px;height: 38px;">
                                Load Text
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- EMAIL EDITOR FORM -->
        <div class="invite" style="margin-left: 20px;">
            <div style="margin-bottom: 20px;font-size: 20px;color:#063074;font-weight: 800;">SEND INVITE</div>
            <div style="display: flex;flex-direction: row;margin-bottom: 20px; justify-content: space-between; ">
                <div style="color:#063074;font-size: 20px;margin-right: 20px;">FROM:</div>
                <input style="width: 458px;" mat-input type="text" placeholder="director@brandinstitute.com"
                    [(ngModel)]="From">
            </div>
            <div style="display: flex;flex-direction: row;margin-bottom: 20px;justify-content: space-between;">
                <div style="color:#063074;font-size: 20px;margin-right: 20px;">CC:</div>
                <input style="width: 458px;" mat-input type="text" placeholder="CC" [(ngModel)]="CC">
            </div>
            <div style="display: flex;flex-direction: row;margin-bottom: 20px;justify-content: space-between;">
                <div style="color:#063074;font-size: 20px;margin-right: 20px;">BCC:</div>
                <input style="width: 458px;" mat-input type="text"
                    placeholder="creative@brandinstitue.com,director1@brandinstitue.com,direct..." [(ngModel)]="BCC">
            </div>
            <div style="display: flex;flex-direction: row;margin-bottom: 20px;justify-content: space-between;">
                <div style="color:#063074;font-size: 20px;margin-right: 20px;">SUBJECT:</div>
                <input style="width: 458px;" mat-input type="text" placeholder="Subject" [(ngModel)]="Subject">
            </div>

            <div style="display: flex;flex-direction: row;">
                <mat-icon style="color: #003180;font-size: 25px;">attach_file</mat-icon>
                <div style="color: grey;">
                    <div *ngFor="let file of attachments; let i = index">
                        {{file.FileName}}
                        <button mat-icon-button (click)="deleteFile(i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
                <div>
                    <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload
                        style="display: none;" multiple>
                    <button mat-raised-button style="background-color: #003180;color:white;margin: -7px 0px 0 12px;"
                        (click)="fileUpload.click()">
                        Choose File(s)
                    </button>


                </div>

            </div>
            <div style="width: 100%;margin-top: 20px;">
                <!-- CKEDITOR -->
                <div>
                    <!-- <div style="margin: 30px;" [innerHTML]="brandMatrixObjects[1].componentText | safe: 'html'"></div> -->
                    <ckeditor [editor]="Editor" id="txtTexto" style="animation: fadeIn 0.5s ease forwards;"
                         [(ngModel)]="brandMatrixObjects[1].componentText"
                        [data]="brandMatrixObjects[1].componentText" >
                    </ckeditor>
                    <div style="display: flex;justify-content: flex-end;">
                        <button mat-raised-button style="background-color: #003180;color:white;margin:20px"
                            (click)="sendEmail()">
                            Send Invite
                        </button>
                        <button mat-raised-button style="background-color: #003180;color:white;margin:20px 0px"
                            (click)="previewEmail()">
                            Preview Invite
                        </button>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <div class="participants-container" style="    position: relative;
    top: 8vh; 
">
        <div style="display: flex;flex-direction: row;margin-bottom: 20px; justify-content: space-between; ">
            <div style="color:#063074;font-size: 20px;margin-right: 20px;font-weight: 800;">To :</div>
        </div>
        <mat-form-field appearance="outline" style="width: 100%;">
            <textarea matInput rows="4" cols="50" placeholder="Recipients">{{to}}</textarea>
        </mat-form-field>
        <div style="display: block;width: 100%; max-height: 500px; overflow: auto;">
            <mat-form-field appearance="outline" style="border-radius: 0px;padding-top: 5px;">
                <mat-label>Sort By</mat-label>
                <mat-select [(value)]="selected" (selectionChange)="changeView()">
                    <mat-option value="All">All</mat-option>
                    <mat-option value="NS">Not Started</mat-option>
                    <mat-option value="NF">Not Finished</mat-option>
                    <mat-option value="F">Finished</mat-option>
                </mat-select>
            </mat-form-field>
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8"
                style="width: 100%; margin-bottom: 20px; overflow-y: auto;">

                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row"  style="padding-right: 15px;">
                        <mat-checkbox style="  margin-top: 9px;" (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            (change)="$event ? selectRow($event, row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="FirstName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.FirstName}} </td>
                </ng-container>

                <ng-container matColumnDef="LastName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.LastName}} </td>
                </ng-container>

                <ng-container matColumnDef="Type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Group </th>
                    <td mat-cell *matCellDef="let element"> {{element.Type}} </td>
                </ng-container>

                <ng-container matColumnDef="SubGroup">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Subgroup </th>
                    <td mat-cell *matCellDef="let element"> {{element.SubGroup}} </td>
                </ng-container>

                <ng-container matColumnDef="Status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell title="{{getTitle(element.Status)}}" *matCellDef="let element"> {{element.Status}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
            </table>


        </div>
    </div>
</div>
