<div>
    <!-- LOGOS INFO -->
    <div style="display: flex;
    flex-direction: row;
    justify-content: space-around;">
        <img [width]="bmxItem.componentSettings[0].logoWidth" style="margin-top: 60px;cursor: pointer;"
            [src]="bmxItem.componentSettings[0].brandInstituteLogoURL">

        <img [width]="bmxItem.componentSettings[0].logoWidth" style="margin-top: 60px;cursor: pointer;"
            [src]="bmxItem.componentSettings[0].DSILogo">
    </div>

    <br>
    <br>
    <br>
    <br>
    <!-- BI INFO -->
    <div style="display: flex; flex-direction: row; justify-content: space-around;">
        <div>
            <p style="font-weight: bold;"> Brand Institute, Inc.</p>
            <p> 200 SE 1ST STREET – 12TH FL </p>
            <p> Miami, FL 33131 </p>
            <p> <span style="font-weight: bold;">P</span> 305 374 2500 </p>
            <p> <span style="font-weight: bold;">E</span> info&#64;brandinstitute.com</p>
        </div>
        <div>
            <p>Contact Person – New York Office </p>
            <p>William Johnson Global President, </p>
            <p>Brand Institute </p>
            <p><span style="font-weight: bold;">P</span> 212 557 2100 </p>
            <p><span style="font-weight: bold;">E</span> <span
                    style="color: #ee7f25;">wjohnson&#64;brandinstitute.com</span> </p>
        </div>
    </div>

    <br>
    <br>
    <br>
    <br>
    <!-- PROJECT INFO -->
    <div style="width: 100%;height: 400px;background-color: white; 
    background-image: url('./assets/img/bmx/cover-graphic.jpg'); background-size: cover;display: flex;
    justify-content: center;align-items: center;text-align: center;">

        <div style="display: flex;
        flex-direction: column;
        justify-content: space-around;">

            <p style="color: white;font-size: 40px;font-weight: bold;">BRANDMATRIX <sup>TM</sup> REPORT</p><br>
            <p style="color: white;font-size: 30px;">PROJECT : <span>STARDUST</span></p>

        </div>

    </div>

    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <!-- COMPANY INFO -->
    <div style="display: flex; flex-direction: row; justify-content: space-around;">
        <div>
            <p>Prepared for: </p>
            <p><span style="font-weight: bold;">{{'CONTACT NAME'}}</span></p>
            <p>Contact Title </p>
            <p><span style="font-weight: bold;">{{'COMPANYNAME'}}</span></p>
            <p>{{'ADDRESS INFORMATION'}}</p>
            <p><span style="font-weight: bold;">P</span> <span style="color: #ee7f25;"> 555-555-5555 </span>
                <span style="font-weight: bold;">E</span>
                FirstLastname&#64;companyname.com
            </p>
        </div>
        <div style="text-align: center;">
            <img [width]="'100'" style="cursor: pointer;" [src]="bmxItem.componentSettings[0].companyLogoURL"><br>
            <p>Report Generated</p>
            <p><span style="font-weight: bold;">{{'11.22.21 '}}</span></p>
        </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br> 
    <br>
</div>