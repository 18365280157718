<div class="modal-background"></div>
<div class="modal-content">
    <button mat-icon-button class="close-button" aria-label="Close" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
    <div style="width: 100%; display: flex; align-items: flex-start; justify-content: center; overflow-x: auto;">
        <div style="width: 75%;">
            <div style="display: flex;justify-content: space-between; margin-top: 20px;">
                <div style="margin: 20px;font-size: 20px;color:#063074;font-weight: 800;">MATRIX LIST</div>
                <mat-form-field style="float:right; padding-right: 20px" appearance="outline">
                    <mat-label>Search</mat-label>
                    <input matInput (keyup)="applyFilter($event.target.value)" value="" placeholder="Search Project">
                </mat-form-field>
                <mat-form-field appearance="outline" style="border-radius: 0px">
                    <mat-label>Sort By</mat-label>
                    <mat-select [(value)]="selected" (selectionChange)="changeView()">
                        <mat-option value="Live">Live</mat-option>
                        <mat-option value="Closed">Closed</mat-option>
                        <mat-option value="All">All</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%;">
                    <!-- Position Column -->
                    <ng-container matColumnDef="bmxChecked">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> checked</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox [(ngModel)]="element.checked" (ngModelChange)="handleCheckboxChange($event, element)"></mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="bmxCompany">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Client</th>
                        <td mat-cell *matCellDef="let element"> {{element.bmxCompany}} </td>
                    </ng-container>
                    <ng-container matColumnDef="bmxProjectName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Project Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.bmxProjectName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="bmxDepartment">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Department </th>
                        <td mat-cell *matCellDef="let element"> {{element.bmxDepartment}} </td>
                    </ng-container>
                    <ng-container matColumnDef="bmxRegion">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Office </th>
                        <td mat-cell *matCellDef="let element"> {{element.bmxRegion}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Created">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
                        <td mat-cell *matCellDef="let element"> {{element.Created}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Close">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Close </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container *ngIf="element.bmxStatus === 'close'">
                                <button mat-icon-button aria-label="Example icon button with a home icon"
                                    (click)="setBMStatus(element)">
                                    <mat-icon style="color: red">close</mat-icon>
                                </button>
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Active">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container *ngIf="element.bmxStatus != 'close'">
                                <button mat-icon-button aria-label="Example icon button with a home icon"
                                    (click)="setBMStatus(element)">
                                    <mat-icon style="color: green">done</mat-icon>
                                </button>
                            </ng-container>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>

                <button mat-raised-button style="background-color: #003180;color:white;margin: 5px 20px 0 20px;"
                    (click)="combineProjects()">
                    Combine
                </button>
            </div>
        </div>

    </div>
</div>
<!--   📆📆📆📆📆📆📆📆📆 END CALENDAR-->
