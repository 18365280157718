// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    // apiKey: "AIzaSyBeBTH-_q9BSI9Qzs3HczaUsE296ncCWJo",
    // authDomain: "elite-5253a.firebaseapp.com",
    // projectId: "elite-5253a",
    // storageBucket: "elite-5253a.appspot.com",
    // messagingSenderId: "965438517119",
    // appId: "1:965438517119:web:18acb595022c9295ffb44a",
    // measurementId: "G-94LSLQDJ0T"
    apiKey: "AIzaSyA5zrqmyxvr-XGzEn0fZCPFddJ6lNJrSo4",
    authDomain: "mrvrman.firebaseapp.com",
    databaseURL: "https://mrvrman.firebaseio.com",
    projectId: "mrvrman",
    storageBucket: "mrvrman.appspot.com",
    messagingSenderId: "108322920951",
    appId: "1:108322920951:web:79253f06045b2c222bb6f2"
  },
  provider: 'appBmxStore',
  isgAwsLogs: 'isgAwsLogsm',
  apiUrl: 'apiUrl',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
