<div >
    <!-- PROJECT INFO -->
    <div style="width:98%;background-color: white;">

        <div style="display: flex;
        flex-direction: column;
        justify-content: space-around;">

            <input type="text" [value]="this.bmxItem.componentSettings[0].pageTitle">

            <textarea name="" id="" cols="30" rows="5">{{this.bmxItem.componentSettings[0].pageContent}}</textarea>
        </div>

    </div>

</div>

