<div class="page-design noselect disable-dbl-tap-zoom">
    <div *ngIf="(status !='close'); else elseBlock">


        <div class="desktop">
            <button *ngIf="true" (click)="seeTutorial()" class="see-tutorial" mat-icon-button matTooltip="see tutorial"
                matTooltipPosition="above" style="position: absolute;color: grey;z-index: 9999999999;outline: none;">
                <mat-icon class="full-icon" style="font-size: 36px;">contact_support</mat-icon>
            </button>
            <!-- <button (click)="displayQrCode()" mat-icon-button matTooltip="design tools" matTooltipPosition="above"
            *ngIf="true"
            style="position: absolute;bottom: 10px;color: grey;z-index: 9999999999;right: 33px;outline: none;">
            <mat-icon class="full-icon" style="font-size: 35px;">qr_code
            </mat-icon>
         </button> -->
            <button (click)="openFullscreen()" mat-icon-button matTooltip="design tools" matTooltipPosition="above"
                *ngIf="true"
                style="position: absolute;bottom: 9px;color: grey;z-index: 9999999999;right: -3px;outline: none;">
                <mat-icon class="full-icon" style="font-size: 35px;">fullscreen
                </mat-icon>
            </button>
            <button (click)="saveUserAnswers()" mat-icon-button matTooltip="save answers" matTooltipPosition="above"
                *ngIf="true"
                style="position: absolute;bottom: 9px;color: grey;z-index: 9999999999;right: 25px;outline: none;">
                <mat-icon class="full-icon" style="font-size: 35px;">save
                </mat-icon>
            </button>
        </div>

        <div class="display">
            <div style="flex-direction: column;" class="bmx-page" *ngIf="true">


                <!-- BRAND MATRIX MODULAR TEMPLATE COMPONENTS  🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀 -->
                <div *ngFor="let bmxItem of bmxPagesClient[currentPage]?.page; let i = index"
                    style="display: flex;flex-direction: column;align-content: center;">

                    <div *ngIf="bmxItem.componentType === 'page-title'" class="paragraph">
                        <div class="emoji-handle">.</div>
                        <div style="position: absolute;right: 0px;">
                            <button *ngIf="bmxClientPageOverview" matTooltip="double click to delete"
                                matTooltipPosition="above" style="outline: none;color:#f10a0a;top: 60px;"
                                mat-icon-button (dblclick)="deleteComponent(i)" aria-label="delte">
                                <mat-icon style="font-size: 17px;">cancel</mat-icon>
                            </button>
                        </div>
                        <app-page-title [bmxItem]="bmxItem" [isBrandMatrixSurvey]="isBrandMatrixSurvey" [i]="i"
                            [bmxClientPageDesignMode]="bmxClientPageDesignMode"
                            [bmxClientPageOverview]="bmxClientPageOverview">
                        </app-page-title>
                    </div>
                    <!-- BMX HEADER 😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋 -->

                    <div *ngIf="bmxItem.componentType === 'logo-header'">
                        <app-logo-header [isBrandMatrixSurvey]="isBrandMatrixSurvey" [creationMode]="true"
                            [bmxItem]="bmxItem" [i]="i" [bmxClientPageDesignMode]="bmxClientPageDesignMode"
                            [bmxClientPageOverview]="bmxClientPageOverview"></app-logo-header>
                    </div>
                    <!--😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋 END BMX HEADER  -->


                    <!-- BMX PARAGRAPH EDITOR 📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑 -->
                    <div *ngIf="bmxItem.componentType === 'text-editor'" class="paragraph">

                        <app-text-paragraph [bmxItem]="bmxItem" [i]="i"
                            [bmxClientPageDesignMode]="bmxClientPageDesignMode" [currentPage]="currentPage"
                            [template]="typeTemplate" [bmxClientPageOverview]="bmxClientPageOverview"
                            style="margin-top: 25px"></app-text-paragraph>
                    </div>
                    <!-- 📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑 END BMX PARAGRAPH EDITOR -->


                    <!-- STARS SCALE 👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽-->
                    <div *ngIf="bmxItem.componentType === 'rate-scale'" class="paragraph " style="margin-bottom: 30px;">
                        <button *ngIf="!showModalVideo" (click)="seeTutorial()" class="see-tutorial-mobil"
                            mat-icon-button matTooltip="See tutorial" matTooltipPosition="above"
                            style="position: fixed;color: grey;z-index: 9999999999;outline: none;">
                            <mat-icon class="full-icon" style="font-size: 36px;">contact_support
                            </mat-icon>
                        </button>

                        <!-- modal -->
                        <div *ngIf="showModalVideo" id="myModal" class="modal-video"
                            style="display: flex;flex-direction: column;gap: 2px;">
                            <!-- <label class="container-checkbox">Don't show again
                            <input (click)="saveSelection()" type="checkbox" value="Bike" #modalChecked>
                            <span class="checkmark">&#x2714;</span>
                        </label> -->
                            <mat-checkbox #modalChecked (click)="saveSelection()"
                                class="example-margin container-checkbox">Don't show again</mat-checkbox>

                            <div class="modal-content">
                                <img *ngFor="let path of VIDEO_PATH" [src]="path" alt="tutorial">
                            </div>
                            <button class="btn btn-raised close-modal" (click)="showModalVideo=false">close</button>
                        </div>
                        <!-- modal end -->

                        <app-rating-scale [bmxItem]="bmxItem" [i]="i"
                            [bmxClientPageDesignMode]="bmxClientPageDesignMode"
                            [bmxClientPageOverview]="bmxClientPageOverview" (launchPathModal)="setPath($event)"
                            (autoSave)="autoSave()">
                        </app-rating-scale>
                    </div>
                    <!-- END STARS SCALE 👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽 -->


                    <!-- RANK SCALE 😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭-->
                    <div *ngIf="bmxItem.componentType === 'ranking-scale'" class="paragraph">

                        <app-rank-scale [bmxItem]="bmxItem" [i]="i" [bmxClientPageDesignMode]="bmxClientPageDesignMode"
                            [bmxClientPageOverview]="bmxClientPageOverview" (autoSave)="autoSave()"></app-rank-scale>
                    </div>
                    <!-- END RANK SCALE 😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭 -->


                    <!--IMAGE RANK SCALE 👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽-->
                    <!-- <div *ngIf="bmxItem.componentType === 'ranking-scale'" class="paragraph">

                    <app-image-rank [bmxItem]="bmxItem" [i]="i" [bmxClientPageDesignMode]="bmxClientPageDesignMode"
                        [bmxClientPageOverview]="bmxClientPageOverview"></app-image-rank>
                </div> -->
                    <!-- END RANK SCALE 👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽 -->


                    <!--IMAGE RANK DRAG 👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽-->
                    <div *ngIf="bmxItem.componentType === 'image-rank-drag'" class="paragraph">
                        <button *ngIf="!showModalVideo" (click)="seeTutorial()" class="see-tutorial-mobil"
                            mat-icon-button matTooltip="see tutorial" matTooltipPosition="above"
                            style="position: fixed;color: grey;z-index: 9999999999;outline: none;">
                            <mat-icon class="full-icon" style="font-size: 36px;">contact_support
                            </mat-icon>
                        </button>
                        <!-- modal -->
                        <div *ngIf="showModalVideo" id="myModal" class="modal-video"> CV

                            <mat-checkbox (click)="saveSelection()" class="example-margin container-checkbox">Don't show
                                again</mat-checkbox>

                            <div class="modal-content">
                                <img *ngFor="let path of VIDEO_PATH" [src]="path" alt="Girl in a jacket">
                            </div>
                            <button class="close-modal" (click)="showModalVideo=false">Close</button>
                        </div>
                        <!-- modal end -->

                        <app-image-rank-drag [bmxItem]="bmxItem" [i]="i"
                            [bmxClientPageDesignMode]="bmxClientPageDesignMode"
                            [bmxClientPageOverview]="bmxClientPageOverview" (launchPathModal)="setPath($event)"
                            (autoSave)="autoSave()"></app-image-rank-drag>
                    </div>
                    <!-- END RANK DRAG 👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽 -->

                    <!-- NARROW DOWN SCALE 😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭-->
                    <div *ngIf="bmxItem.componentType === 'narrow-down'" class="paragraph">
                        <app-narrow-down [bmxItem]="bmxItem" [i]="i" [bmxClientPageDesignMode]="bmxClientPageDesignMode"
                            (autoSave)="autoSave()" [bmxClientPageOverview]="bmxClientPageOverview"></app-narrow-down>
                    </div>
                    <!-- END NARROW DOWN SCALE 😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭 -->

                    <!-- IMAGE SCALE 👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀-->
                    <div *ngIf="bmxItem.componentType === 'image-rate-scale'" class="paragraph">
                        <button *ngIf="!showModalVideo" (click)="seeTutorial()" class="see-tutorial-mobil"
                            mat-icon-button matTooltip="see tutorial" matTooltipPosition="above"
                            style="position: fixed;color: grey;z-index: 9999999999;outline: none;">
                            <mat-icon class="full-icon" style="font-size: 36px;">contact_support
                            </mat-icon>
                        </button>
                        <!-- modal -->
                        <div *ngIf="showModalVideo" id="myModal" class="modal-video" CV
                            style="display: flex;flex-direction: column;gap: 6px;">
                            <mat-checkbox (click)="saveSelection()" class="example-margin container-checkbox">Don't show
                                again</mat-checkbox>

                            <div class="modal-content">
                                <img *ngFor="let path of VIDEO_PATH" [src]="path" alt="Girl in a jacket">
                            </div>
                            <button class="close-modal" (click)="showModalVideo=false">Close</button>
                        </div>
                        <!-- modal end -->

                        <app-image-rate-scale [bmxItem]="bmxItem" [i]="i"
                            [bmxClientPageDesignMode]="bmxClientPageDesignMode"
                            [bmxClientPageOverview]="bmxClientPageOverview" (launchPathModal)="setPath($event)"
                            (autoSave)="autoSave()"></app-image-rate-scale>
                    </div>
                    <!-- END IMAGE SCALE 👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀 -->

                    <!-- QUESTION AND ANSWER  ✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️-->
                    <div *ngIf="bmxItem.componentType === 'question-answer'" class="paragraph">
                        <app-question-answer [bmxItem]="bmxItem" [i]="i"
                            [bmxClientPageDesignMode]="bmxClientPageDesignMode" (autoSave)="autoSave()"
                            [bmxClientPageOverview]="bmxClientPageOverview"></app-question-answer>
                    </div>
                    <!-- END QUESTION AND ANSWER  ✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️ -->

                    <!-- TINDER  🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥-->
                    <div *ngIf="bmxItem.componentType === 'tinder'" class="paragraph">
                        <button *ngIf="!showModalVideo" (click)="seeTutorial()" class="see-tutorial-mobil"
                            mat-icon-button matTooltip="see tutorial" matTooltipPosition="above"
                            style="position: fixed;color: grey;z-index: 9999999999;outline: none;">
                            <mat-icon class="full-icon" style="font-size: 36px;">contact_support
                            </mat-icon>
                        </button>
                        <!-- modal -->
                        <!-- <div *ngIf="showModalVideo" id="myModal" class="modal-video" CV
                    style="display: flex;flex-direction: column;gap: 6px;">
                        <mat-checkbox (click)="saveSelection()" class="example-margin container-checkbox" >Don't show again</mat-checkbox>

                        <div class="modal-content">
                            <img *ngFor="let path of VIDEO_PATH" [src]="path" alt="Girl in a jacket">
                        </div>
                        <button class="close-modal" (click)="showModalVideo=false">Close</button>
                    </div> -->
                        <!-- modal end -->
                        <div class="emoji-handle">.</div>
                        <div style="position: absolute;right: 0px;">
                            <button *ngIf="bmxClientPageOverview" matTooltip="double click to delete"
                                matTooltipPosition="above" style="outline: none;color:#f10a0a;top: 19px;"
                                mat-icon-button (dblclick)="deleteComponent(i)" aria-label="delte">
                                <mat-icon style="font-size: 17px;">cancel</mat-icon>
                            </button>
                        </div>
                        <app-tinder [bmxItem]="bmxItem" [i]="i" [bmxClientPageDesignMode]="bmxClientPageDesignMode"
                            (launchPathModal)="setPath($event)" [survey]="true"
                            [bmxClientPageOverview]="bmxClientPageOverview" (autoSave)="autoSave()"></app-tinder>
                    </div>
                    <!-- END TINDER  🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥 -->

                </div>


                <!-- PAGE BUTTONS AND DIRECTIONS 1 2 3 📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲-->
                <div class="page-buttons">
                    <div class="page-buttons-heigth-minus-50" style="gap:10px">

                        <!-- <button *ngIf="isBrandMatrixSurvey" (click)="changePage('previous')" mat-icon-button>
                        <mat-icon class="full-icon">chevron_left</mat-icon>
                    </button> -->
                        <button style="background: #324395;color: white;"
                            *ngIf="isBrandMatrixSurvey && surveyLanguage != 'Japanese'" (click)="changePage('previous')"
                            mat-raised-button>
                            <mat-icon class="full-icon">chevron_left</mat-icon> <span
                                class="menu-descriptions">Previous</span>
                        </button>
                        <button style="background: #324395;color: white;"
                            *ngIf="isBrandMatrixSurvey && surveyLanguage == 'Japanese'" (click)="changePage('previous')"
                            mat-raised-button>
                            <mat-icon class="full-icon">chevron_left</mat-icon> <span
                                class="menu-descriptions">戻る</span>
                        </button>
                        <div *ngFor="let page of bmxPagesClient; let pageIndex = index" >
                            <button disableRipple style="min-width: 35px;cursor: auto;outline: none;box-shadow:none;"
                                [ngClass]="currentPage == pageIndex?'pageNumberActive':'pageNumber'"
                                mat-raised-button>
                                {{page.pageNumber}} 
                            </button>
                            <!-- DISBLE CLICKING ON THE BUTTONS -->
                            <!-- <button style="min-width: 35px;" (click)="selectPageNumber(pageIndex)"
                            [ngClass]="(currentPage === pageIndex)?'pageNumberActive':'pageNumber'" mat-raised-button>
                            {{page.pageNumber}}
                         </button> -->
                        </div>  
                        <button [style.background]="(continueButtonToComple == 'Complete')?'#05b85e':'#324395'"
                            style="color: white !important;" *ngIf="isBrandMatrixSurvey && surveyLanguage != 'Japanese'"
                            (click)="changePage('next')" mat-raised-button style=""
                            style="flex-direction: row-reverse;    gap: 13px;">
                            <span class="menu-descriptions"
                                style="color: white  !important;">{{continueButtonToComple}}</span>
                            <mat-icon
                                style="margin-right: 0 !important; margin-left: 5px !important; margin-top: 1.5px !important; "
                                class="full-icon" style="color: white  !important; margin-right: 0 !important;">
                                {{ (continueButtonToComple != 'Complete')?'chevron_right':'task_alt'}}</mat-icon>
                        </button>

                        <!-- <button *ngIf="isBrandMatrixSurvey" (click)="changePage('next')" mat-icon-button>
                        <mat-icon class="full-icon">chevron_right</mat-icon>
                    </button> -->
                    </div>
                </div>
                <!-- 📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲 END PAGE BUTTONS AND DIRECTIONS -->
                <!-- END 🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀 BRAND MATRIX MODULAR LEGO TEMPLATE COMPONENTS   -->
            </div>
        </div>
    </div>
    <div [style.display]="(popUpQRCode)?'flex':'none'" class="qr-code">
        <div style="position: absolute;top:71px" #canvas></div>
        <img style="width: 360px;" src="./assets/img/bmx/qr/darkBlueW.png">
        <p>{{myAngularxQrCode}}</p>
    </div>
</div>
<ng-template #elseBlock>
    <div class="error">

        <div *ngIf="(projectId === '-404'); else block" fxFlexFill fxLayout="column" fxLayoutAlign="center center">
            <div class="errorcode"> This Project Does Not Exist</div>
            <div class="errormess">Please contact your BI Representative if this link needs to be re-activated.</div>

        </div>
        <ng-template #block>
            <div fxFlexFill fxLayout="column" fxLayoutAlign="center center">
                <div class="errorcode">This Project is Currently Closed</div>
                <div class="errormess">Please contact your BI Representative if this link needs to be re-activated.
                </div>
            </div>
        </ng-template>
    </div>
</ng-template>
<!-- <lottie-player class="lottie-player" *ngIf="loadingLottie" style="
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    width: 465px;animation:
    fadeIn 3s ease 4s forwards;" slot="end" autoplay loop [src]="'assets/img/cubo.json'">
</lottie-player>
<lottie-player class="lottie-player" *ngIf="loadingLottie" style="
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    width: 70px;animation:
    fadeIn 3s ease 4s forwards;" slot="end" autoplay loop [src]="'assets/img/esfera.json'">
</lottie-player> -->