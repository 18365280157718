<div *ngIf="showDialog">
    <app-survey-dialog (onConfirm)="confirmAction()" (onCancel)="closeDialog()"
        [dialogText]="dialogText"></app-survey-dialog>
</div>

<div *ngIf="showSaveTemplate">
    <app-survey-dialog (onConfirm)="saveOrUpdateTemplate(templateName)" (onCancel)="closeDialog()"
        [dialogText]="dialogText"></app-survey-dialog>
</div>

<div *ngIf="showDelete">
    <app-survey-dialog (onConfirm)="deleteTemplate(templateName)" (onCancel)="closeDialog()"
        [dialogText]="dialogText"></app-survey-dialog>
</div>

<div *ngIf="showDeletePage">
    <app-survey-dialog (onConfirm)="deletePage()" (onCancel)="closeDialog()"
        [dialogText]="dialogText"></app-survey-dialog>
</div>

<div *ngIf="showReset">
    <app-survey-dialog (onConfirm)="resetTemplate()" (onCancel)="closeDialog()"
        [dialogText]="dialogText"></app-survey-dialog>
</div>

<div style="display: flex; margin: 0px;width: 99%;">
    <div style="flex-direction: column;" class="bmx-page" >
        <div style="width: 100%;">
            &nbsp;
        </div>

        <!-- BRAND MATRIX MODULAR TEMPLATE COMPONENTS  🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀 -->
        <div *ngFor="let bmxItem of bmxPages[currentPage].page; let i = index"
            style="display: flex;flex-direction: column;margin: 10px;align-content: center;">

            <div *ngIf="bmxItem.componentType === 'page-title'" class="paragraph">
                <div class="emoji-handle">.</div>
                <div style="position: absolute;right: 0px;">
                    <button *ngIf="bmxClientPageOverview" matTooltip="double click to delete" matTooltipPosition="above"
                        style="outline: none;color:#f10a0a;top: 60px;" mat-icon-button
                        (dblclick)="openDialog('delete',i)" aria-label="delte">
                        <mat-icon style="font-size: 17px;">cancel</mat-icon>
                    </button>
                </div>
                <app-page-title [bmxItem]="bmxItem" [isBrandMatrixSurvey]="isBrandMatrixSurvey" [i]="i"
                    [bmxClientPageDesignMode]="bmxClientPageDesignMode" [bmxClientPageOverview]="bmxClientPageOverview">
                </app-page-title>
            </div>

            <!-- BMX HEADER 😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋 -->

            <div *ngIf="bmxItem.componentType === 'logo-header'" class="paragraph">
                <div style="position: absolute;right: 0px;">
                    <button *ngIf="bmxClientPageOverview" matTooltip="double click to delete" matTooltipPosition="above"
                        style="outline: none;color:#f10a0a;top: 19px;" mat-icon-button
                        (dblclick)="openDialog('delete', i)" aria-label="delte">
                        <mat-icon style="font-size: 17px;">cancel</mat-icon>
                    </button>
                </div>
                <app-logo-header [bmxItem]="bmxItem" [isBrandMatrixSurvey]="isBrandMatrixSurvey" [i]="i"
                    (logoChanged)="logoChanged($event)" (resizeWidthLogo)="resizeWidthLogo($event)"
                    [bmxClientPageDesignMode]="bmxClientPageDesignMode" [bmxClientPageOverview]="bmxClientPageOverview">
                </app-logo-header>
            </div>
            <!--😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋😋 END BMX HEADER  -->


            <!-- BMX PARAGRAPH EDITOR 📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑 -->
            <div *ngIf="bmxItem.componentType === 'text-editor'" class="paragraph">
                <div class="emoji-handle">.</div>
                <div style="position: absolute;right: 0px;">
                    <button *ngIf="bmxClientPageOverview" matTooltip="double click to delete" matTooltipPosition="above"
                        style="outline: none;color:#f10a0a;top: 30px;" mat-icon-button
                        (dblclick)="openDialog('delete', i)" aria-label="delte">
                        <mat-icon style="font-size: 17px;">cancel</mat-icon>
                    </button>
                </div>
                <app-text-paragraph [bmxItem]="bmxItem" [i]="i" [bmxClientPageDesignMode]="bmxClientPageDesignMode"
                    [bmxClientPageOverview]="bmxClientPageOverview" style="margin-top: 25px"></app-text-paragraph>
            </div>
            <!-- 📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑📑 END BMX PARAGRAPH EDITOR -->


            <!-- STARS SCALE 👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽-->
            <div *ngIf="bmxItem.componentType === 'rate-scale'" class="paragraph " style="margin-bottom: 30px;">
                <div class="emoji-handle">.</div>
                <div style="position: absolute;right: 0px;">
                    <button *ngIf="bmxClientPageOverview" matTooltip="double click to delete" matTooltipPosition="above"
                        style="outline: none;color:#f10a0a;top: 7px;" mat-icon-button
                        (dblclick)="openDialog('delete', i)" aria-label="delte">
                        <mat-icon style="font-size: 17px;">cancel</mat-icon>
                    </button>
                </div>
                <app-rating-scale [bmxItem]="bmxItem" [i]="i" [bmxClientPageDesignMode]="bmxClientPageDesignMode"
                    [bmxClientPageOverview]="bmxClientPageOverview">
                </app-rating-scale>
            </div>
            <!-- END STARS SCALE 👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽 -->


            <!-- RANK SCALE 😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭-->
            <div *ngIf="bmxItem.componentType === 'ranking-scale'" class="paragraph">
                <div class="emoji-handle">.</div>
                <div style="position: absolute;right: 0px;">
                    <button *ngIf="bmxClientPageOverview" matTooltip="double click to delete" matTooltipPosition="above"
                        style="outline: none;color:#f10a0a;top: 19px;" mat-icon-button
                        (dblclick)="openDialog('delete', i)" aria-label="delte">
                        <mat-icon style="font-size: 17px;">cancel</mat-icon>
                    </button>
                </div>
                <app-rank-scale [bmxItem]="bmxItem" [i]="i" [bmxClientPageDesignMode]="bmxClientPageDesignMode"
                    [bmxClientPageOverview]="bmxClientPageOverview"></app-rank-scale>
            </div>
            <!-- END RANK SCALE 😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭 -->

            <!--IMAGE RANK DRAG 👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽-->
            <div *ngIf="bmxItem.componentType === 'image-rank-drag'" class="paragraph">
                <div class="emoji-handle">.</div>
                <div style="position: absolute;right: 0px;">
                    <button *ngIf="bmxClientPageOverview" matTooltip="double click to delete" matTooltipPosition="above"
                        style="outline: none;color:#f10a0a;top: 19px;" mat-icon-button
                        (dblclick)="openDialog('delete', i)" aria-label="delte">
                        <mat-icon style="font-size: 17px;">cancel</mat-icon>
                    </button>
                </div>
                <app-image-rank-drag [bmxItem]="bmxItem" [i]="i" [bmxClientPageDesignMode]="bmxClientPageDesignMode"
                    [bmxClientPageOverview]="bmxClientPageOverview"></app-image-rank-drag>
            </div>
            <!-- END RANK DRAG 👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽 -->


            <!-- NARROW DOWN SCALE 😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭-->
            <div *ngIf="bmxItem.componentType === 'narrow-down'" class="paragraph">
                <div class="emoji-handle">.</div>
                <div style="position: absolute;right: 0px;">
                    <button *ngIf="bmxClientPageOverview" matTooltip="double click to delete" matTooltipPosition="above"
                        style="outline: none;color:#f10a0a;top: 19px;" mat-icon-button
                        (dblclick)="openDialog('delete', i)" aria-label="delte">
                        <mat-icon style="font-size: 17px;">cancel</mat-icon>
                    </button>
                </div>
                <app-narrow-down [bmxItem]="bmxItem" [i]="i" [bmxClientPageDesignMode]="bmxClientPageDesignMode"
                    [bmxClientPageOverview]="bmxClientPageOverview"></app-narrow-down>
            </div>
            <!-- END NARROW DOWN SCALE 😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭 -->

            <!-- IMAGE SCALE 👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀-->
            <div *ngIf="bmxItem.componentType === 'image-rate-scale'" class="paragraph">
                <div class="emoji-handle">.</div>
                <div style="position: absolute;right: 0px;">
                    <button *ngIf="bmxClientPageOverview" matTooltip="double click to delete" matTooltipPosition="above"
                        style="outline: none;color:#f10a0a;top: 19px;" mat-icon-button
                        (dblclick)="openDialog('delete', i)" aria-label="delte">
                        <mat-icon style="font-size: 17px;">cancel</mat-icon>
                    </button>
                </div>
                <app-image-rate-scale [bmxItem]="bmxItem" [i]="i" [bmxClientPageDesignMode]="bmxClientPageDesignMode"
                    [bmxClientPageOverview]="bmxClientPageOverview"></app-image-rate-scale>
            </div>
            <!-- END IMAGE SCALE 👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀👀 -->


            <!-- QUESTION AND ANSWER  ✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️-->
            <div *ngIf="bmxItem.componentType === 'question-answer'" class="paragraph">
                <div class="emoji-handle">.</div>
                <div style="position: absolute;right: 0px;">
                    <button *ngIf="bmxClientPageOverview" matTooltip="double click to delete" matTooltipPosition="above"
                        style="outline: none;color:#f10a0a;top: 19px;" mat-icon-button
                        (dblclick)="openDialog('delete', i)" aria-label="delte">
                        <mat-icon style="font-size: 17px;">cancel</mat-icon>
                    </button>
                </div>
                <app-question-answer [bmxItem]="bmxItem" [i]="i" [bmxClientPageDesignMode]="bmxClientPageDesignMode"
                    [bmxClientPageOverview]="bmxClientPageOverview"></app-question-answer>
            </div>
            <!-- END QUESTION AND ANSWER  ✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️✒️ -->



            <!-- TINDER  🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥-->
            <div *ngIf="bmxItem.componentType === 'tinder'" class="paragraph">
                <div class="emoji-handle">.</div>
                <div style="position: absolute;right: 0px;">
                    <button *ngIf="bmxClientPageOverview" matTooltip="double click to delete" matTooltipPosition="above"
                        style="outline: none;color:#f10a0a;top: 19px;" mat-icon-button
                        (dblclick)="openDialog('delete', i)" aria-label="delte">
                        <mat-icon style="font-size: 17px;">cancel</mat-icon>
                    </button>
                </div>
                <app-tinder [bmxItem]="bmxItem" [i]="i" [bmxClientPageDesignMode]="bmxClientPageDesignMode"
                    [survey]="false" [bmxClientPageOverview]="bmxClientPageOverview"></app-tinder>
            </div>
            <!-- END TINDER  🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥 -->
            <!-- PAGE BUTTONS AND DIRECTIONS 1 2 3 📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲-->
            <div class="page-buttons">
                <div [ngClass]="bmxClientPageDesignMode?'page-buttons-heigth-40':'page-buttons-heigth-minus-50'">

                    <button (click)="changePage('previous')" mat-icon-button>
                        <mat-icon class="full-icon">chevron_left</mat-icon>
                    </button>
                    <button (click)="changePage('previous')" mat-icon-button>
                        Prev
                    </button>
                    <div *ngFor="let page of bmxPages; let pageIndex = index">
                        <button style="min-width: 35px;" (click)="selectPageNumber(pageIndex)"
                            [ngClass]="(currentPage === pageIndex)?'pageNumberActive':'pageNumber'" mat-raised-button>
                            {{page.pageNumber}}
                        </button>
                    </div>

                    <button (click)="changePage('next')" mat-icon-button>
                        Next
                    </button>
                    <button (click)="changePage('next')" mat-icon-button>
                        <mat-icon class="full-icon">chevron_right</mat-icon>
                    </button>
                </div>
                <div class="button-name">
                    <button *ngIf="globalProjectName" class="no-print project-name-global" mat-raised-button
                        style="position: relative; z-index: 2; outline: none;">
                        {{title}}: {{ globalDisplayName ? globalDisplayName : globalProjectName }}
                    </button>
                </div>

            </div>
        </div>

        <!-- 📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲📲 END PAGE BUTTONS AND DIRECTIONS -->

        <!-- END 🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀🧑🏻‍🚀 BRAND MATRIX MODULAR LEGO TEMPLATE COMPONENTS   -->
    </div>


    <!-- 🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵  RIGHT SIDE MENU 🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵-->
    <!-- <div class="project-info" *ngIf="bmxClientPageDesignMode" style="border: pink solid 1px; width: 25%; height: 100vh;"> -->
    <div class="project-info" *ngIf="true">
        <button (click)="showMenucreateNewBmx()" mat-icon-button matTooltip="open menu" matTooltipPosition="right">
            <mat-icon class="full-icon">{{iconMenuShow}}</mat-icon>
        </button>

        <div [ngClass]="showMenuCreator?'menuCreatorBmx':'hideMenuCreatorBmx'">

            <button (click)="createNewBmxComponent('logo-header')" mat-icon-button matTooltip="header"
                matTooltipPosition="right">
                <mat-icon class="full-icon">account_circle</mat-icon>
            </button>
            <button (click)="createNewBmxComponent('text-editor')" mat-icon-button matTooltip="paragraph"
                matTooltipPosition="right">
                <mat-icon class="full-icon">article</mat-icon>
            </button>
            <!-- <button (click)="createNewBmxComponent('page-title')" matTooltip="title box" matTooltipPosition="right"
                mat-icon-button color="warn" aria-label="aria">
                <mat-icon class="full-icon-secondary">title</mat-icon>
            </button> -->
            <button (click)="createNewBmxComponent('rate-scale')" matTooltip="rate matrix" matTooltipPosition="right"
                mat-icon-button color="warn" aria-label="aria">
                <mat-icon class="full-icon">star_border</mat-icon>
            </button>
            <button (click)="createNewBmxComponent('ranking-scale')" matTooltip="rank radio matrix" matTooltipPosition="right"
                mat-icon-button color="warn" aria-label="aria">
                <mat-icon class="full-icon">military_tech</mat-icon>
            </button>
            <button (click)="createNewBmxComponent('narrow-down')" matTooltip="narrow down matrix"
                matTooltipPosition="right" mat-icon-button color="warn" aria-label="aria">
                <mat-icon class="full-icon">rule</mat-icon>
            </button>
            <button (click)="createNewBmxComponent('image-rate-scale')" matTooltip="image rate matrix"
                matTooltipPosition="right" mat-icon-button color="warn" aria-label="aria">
                <mat-icon class="full-icon">image</mat-icon>
            </button>
            <button (click)="createNewBmxComponent('image-rank-drag')" matTooltip="image drag and drop matrix"
                matTooltipPosition="right" mat-icon-button color="warn" aria-label="aria">
                <mat-icon class="full-icon">tune</mat-icon>
            </button>
            <button (click)="createNewBmxComponent('tinder')" matTooltip="slide by slide" matTooltipPosition="right"
                mat-icon-button color="warn" aria-label="aria">
                <mat-icon class="full-icon">local_fire_department</mat-icon>
            </button>
            <button (click)="createNewBmxComponent('question-answer')" matTooltip="questions & answers"
                matTooltipPosition="right" mat-icon-button color="warn" aria-label="aria">
                <mat-icon class="full-icon">question_answer</mat-icon>
            </button>
            <br>
        </div>
        <div style="display: flex;flex-direction: column;">
            <button *ngIf="!false" (click)="createPage()" matTooltip="Create New Page" matTooltipPosition="right"
                mat-icon-button aria-label="aria">
                <mat-icon class="full-icon-secondary">post_add</mat-icon>
            </button>
            <button *ngIf="!false" (dblclick)="showDeletePage = true" matTooltip="double click to delete current page"
                matTooltipPosition="right" mat-icon-button aria-label="aria">
                <mat-icon class="full-icon-secondary">layers_clear</mat-icon>
            </button>
            <!-- <button mat-icon-button color="warn" aria-label="aria" id="openSaveTamplate" style="border: none">
                <mat-icon class="full-icon-secondary">category</mat-icon>
                <div class="list-save-template">
                    <mat-list role="list">
                        <mat-list-item (click)="openSaveTemplateBox()" role="listitem">Save new template</mat-list-item>
                        <mat-list-item (click)="openUploadDeleteLoadTemplateBox()" role="listitem">Update, load, delete
                            template</mat-list-item>
                    </mat-list>
                </div>
            </button> -->

            <button *ngIf="true" (dblclick)="showReset = true" mat-icon-button
                matTooltip="double click to reset template" matTooltipPosition="right">
                <mat-icon class="full-icon-secondary">delete_sweep
                </mat-icon>
            </button>
            <br>

            <button *ngIf="true" (click)="isTemplate=='true'?saveTemplate() : openDialog('save')" mat-icon-button
            matTooltip="{{isTemplate == 'true' ? 'save template' : 'save brand matrix'}}"
            matTooltipPosition="right">
        <mat-icon class="full-icon">save</mat-icon>
    </button>


            <button *ngIf="true && this.isTemplate != 'true' " (click)="previewSurvey()" mat-icon-button matTooltip="preview brand matrix"
                matTooltipPosition="right">
                <mat-icon class="full-icon">visibility
                </mat-icon>
            </button>
            <button *ngIf="true" (click)="openFullscreen()" mat-icon-button matTooltip="full screen"
                matTooltipPosition="right">
                <mat-icon class="full-icon">fullscreen
                </mat-icon>
            </button>
            <!-- <button *ngIf="true" (click)="bmxClientPageOverview=!bmxClientPageOverview" mat-icon-button matTooltip="reset template"
                matTooltipPosition="right">
                <mat-icon class="full-icon">visibility
                </mat-icon>
            </button>                       -->
        </div>
    </div>


    <!-- 🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵 END RIGHT SIDE MENU 🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵🎵-->

</div>

<!--  MODAL BOX TO SAVE TEMPLATES -->
<div *ngIf="isTemplateBoxOn && !showSaveTemplate" class="save-template-box"
    style="display: flex;flex-direction: column;align-items: center;justify-content: space-evenly;">
    <h2 style="text-align: center;padding-top: 5px;color: #2a3f8a;">{{templateTitle}}</h2>
    <mat-form-field appearance="outline">
        <mat-label>New Template Name</mat-label>
        <input matInput [(ngModel)]="templateName" placeholder="New Name" (click)="isSaveOrUpdate = false">
        <button *ngIf="templateName" matSuffix mat-icon-button aria-label="Clear" (click)="templateName=''">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>

    <div *ngIf="templateName.length > 3"
        style="display: flex;flex-direction: row;justify-content: space-evenly; width: 300px;">
        <button (click)="showSaveTemplate = true; changeDialog(templateName)" mat-raised-button style="color: white;background-color: #2a3f8a">
            save
        </button>
    </div>
    <mat-icon (click)="isTemplateBoxOn = false" style="cursor: pointer;" class="full-icon">close
    </mat-icon>
</div>



<!--  MODAL BOX TO UPDATE TEMPLATES -->
<div *ngIf="isTemplateUpdate" class="save-template-box"
    style="display: flex;flex-direction: column;align-items: center;justify-content: space-evenly;">
    <h2 style="text-align: center;padding-top: 5px;color: #2a3f8a;">{{templateTitle}}</h2>

    <p style="text-align: center;color: grey;" *ngIf="templateName.length == 0">select one to load or update</p>
    <mat-form-field appearance="outline">
        <mat-label>Templates</mat-label>
        <mat-select [(ngModel)]="templateName" (selectionChange)="templateSelected()">
            <mat-option *ngFor="let template of TEMPLATES " [value]="template.template">
                {{template.displayname? template.displayname: template.template}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div *ngIf="templateName.length > 3"
        style="display: flex;flex-direction: row;justify-content: space-evenly; width: 300px;">
        <button *ngIf="isSaveOrUpdate" (click)="loadTemplate(templateName)" mat-raised-button
            style="color: white;background-color: #8a2a5a ">
            load
        </button>

        <p *ngIf="isSaveOrUpdate">or</p>
        <button *ngIf="isSaveOrUpdate" (click)="saveOrUpdateTemplate(templateName)" mat-raised-button
            style="color: white;background-color: #2a3f8a">
            update
        </button>

        <p *ngIf="isSaveOrUpdate">or</p>
        <button *ngIf="isSaveOrUpdate" (dblclick)="showDelete = true; changeDialogDelete(templateName)"
            matTooltip="double click to delete template" matTooltipPosition="below" mat-raised-button
            style="color: white;background-color: #972044">
            delete
        </button>
    </div>
    <mat-icon (click)="isTemplateUpdate = false" style="cursor: pointer;" class="full-icon">close</mat-icon>

</div>
