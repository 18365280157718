<div *ngIf="showDialog"><app-survey-dialog (onConfirm)="confirmAction()" (onCancel)="closeDialog()"
        [dialogText]="dialogText"></app-survey-dialog>
</div>
<div *ngIf="showCreationModalVideo && bmxClientPageOverview" id="myModal" class="modal-video"
    style="display: flex;flex-direction: column;gap: 2px;">
    <!-- <label class="container-checkbox">Don't show again
        <input (click)="saveSelection()" type="checkbox" value="Bike" #modalChecked>
        <span class="checkmark">&#x2714;</span>
    </label> -->
    <div class="modal-content">
        <video src='{{CREATION_VIDEO_PATH}}' controls></video>

    </div>
    <button class="btn btn-raised close-modal" (click)="showCreationModalVideo=false">close</button>
</div>
<!-- ➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗  SETTINGS ICONS  ➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗ -->
<div *ngIf="bmxClientPageOverview" style="display: flex;
    flex-direction: row-reverse;
    justify-content: end;
    top: -30px;
    right: 50px;border: 2px dashed #324395;padding-right: 10px;background: #ffffffd9;
    position: absolute;height: 54px;align-items: center;padding-right: 38px;">

    <div>
        <button (click)="openDialog('undo')" class="add-column" matTooltip="undo last change" matTooltipPosition="above"
            mat-icon-button aria-label="ranking">
            <mat-icon style="font-size: 26px;" class="full-icon">undo</mat-icon>
        </button>
        <button (click)="selectedIndex = ''" class="add-column" matTooltip="cancel" matTooltipPosition="above"
            *ngIf="i === selectedIndex" mat-icon-button>
            <mat-icon style="font-size: 25px;" class="full-icon">check_circle</mat-icon>
        </button>

        <button (dblclick)="resetVotes()" [ngClass]="(!deleteRows)?'add-column':'add-column-active'"
            matTooltip="double click to reset votes" matTooltipPosition="above" mat-icon-button aria-label="ranking">
            <mat-icon style="font-size: 26px;" class="full-icon">delete_outline</mat-icon>
        </button>

        <button (click)="setPronunciation()" class="add-column" matTooltip="speech" matTooltipPosition="above"
            mat-icon-button aria-label="ranking">
            <mat-icon style="font-size: 26px;" class="full-icon">mic</mat-icon>
        </button>
        <!-- <button (click)="isColumnResizerOn = !isColumnResizerOn"
            [ngClass]="(!isColumnResizerOn)?'add-column':'add-column-active'" matTooltip="columns width"
            matTooltipPosition="above" mat-icon-button aria-label="ranking">
            <mat-icon style="font-size: 26px;    z-index: 9;" class="full-icon">swap_horizontal_circle</mat-icon>
        </button> -->
        <button (click)="showCreationModalVideo=true; editSingleTableCells = false" class="add-column"
            matTooltip="see tutorial" matTooltipPosition="above" mat-icon-button aria-label="ranking">
            <mat-icon style="font-size: 26px;" class="full-icon">video_library</mat-icon>
        </button>
        <button (click)="showModalTable = true" class="add-column" matTooltip="Update names" matTooltipPosition="above"
            *ngIf="i !== selectedIndex" mat-icon-button>
            <mat-icon style="font-size: 25px;" class="full-icon"> text_fields</mat-icon>
        </button>
        <button (click)="selectedIndex = i; verifyCritera() " class="add-column" matTooltip="setup settings"
            matTooltipPosition="above" *ngIf="i !== selectedIndex" mat-icon-button>
            <mat-icon style="font-size: 25px;" class="full-icon">edit</mat-icon>
        </button>
    </div>
</div>
<!-- ➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗  END  ➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗ -->



<!-- 🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧 CONFIG BOX 🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧 -->
<div *ngIf="i === selectedIndex" class="mat-elevation-z4" cdkDrag style="position: absolute;width: 50%;background: #fafafa;z-index: 1;top: -65px;left: 25%;border: #063074 4px solid; overflow: auto; z-index: 10;
       max-height: 36rem;
    bottom: -2; ">

    <div class="example-handle" cdkDragHandle style="display: flex;
flex-direction: row;
justify-content: center;cursor: move; height: 25px; width: 100%;"><svg width="24px" fill="currentColor"
            viewBox="0 0 24 24">
            <path
                d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
            </path>
            <path d="M0 0h24v24H0z" fill="none"></path>
        </svg></div>

    <!-- Cards Test Name-->
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Columns</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div style="text-align: center;">
                <div style="font-size: 16px;margin: 20px;color: #48535e;">Copy & Paste Columns</div>
                <!-- <div style="font-size: 14px;margin-bottom: 20px;color: lightgray;">Brief Description Here</div> -->
            </div>
            <div style="display: flex; justify-content: center; align-items: center;">
                <mat-form-field style="width: 90%; margin-left: 20px;" appearance="outline">
                    <mat-label>Copy & Paste Columns</mat-label>
                    <textarea matInput [(ngModel)]="testNamesInput" (paste)="onPaste()"
                        (ngModelChange)="upLoadNamesAndRationales(testNamesInput)" placeholder="Paste test names here"
                        cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5"></textarea>
                    <mat-hint>Type or paste the test names here that you want to include</mat-hint>
                </mat-form-field>
            </div>

        </mat-card-content>

    </mat-card>
    <br>
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Select Ranking</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="sm-section3-rkScale">



                <div class="sm-row-box-rkScale">
                    <div *ngIf="ranking">
                        <div style="font-size: 16px;margin-top: 10px;color: #48535e;">SELECT RATING RANGE</div>
                        <br>
                        <div
                            style="text-align: center;display: flex;flex-direction: row;justify-content: space-around;">

                            <input [(ngModel)]="rankingScaleValue" type="range" id="volume" name="volume" min="0"
                                max="10">
                            {{rankingScaleValue}}
                        </div>
                    </div>
                    <div style="    margin-top: 0.5rem;
                 ">
                        <div style="display: flex; flex-direction: column">
                            <mat-checkbox [(ngModel)]="randomizeTestNames">Randomize Names</mat-checkbox>
                            <mat-checkbox *ngIf="!ranking" #showIcon [(ngModel)]="showNeutralIcon">Add Neutral
                                Button</mat-checkbox>
                            <mat-checkbox #changePreference (click)="changePreferenceScore()"
                                [(ngModel)]="ranking">Preference
                                Score</mat-checkbox>
                        </div>
                    </div>

                </div>
            </div>
        </mat-card-content>

    </mat-card>
    <br>
    <!-- Cards Criteria-->
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Category</mat-card-title>
        </mat-card-header>
        <br>
        <div class="sm-section2-rkScale">
            <div class="sm-column-box-rkScale">
                <mat-form-field appearance="outline" style="width: 40%">
                    <mat-label>Category Name</mat-label>
                    <input matInput [(ngModel)]="bmxItem.componentSettings[0].categoryName" placeholder="Category Name"
                        style="font-size: 20px;color: #324395;">
                    <mat-hint>Enter the name of the category</mat-hint> <!-- Hint text for Category Name -->
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 40%">
                    <mat-label>Sub category Description</mat-label>
                    <input matInput [(ngModel)]="bmxItem.componentSettings[0].categoryDescription"
                        placeholder="Sub category Description" style="font-size: 20px;color: #ee7f25;">
                    <mat-hint>Enter a brief description of the Sub category </mat-hint>
                    <!-- Hint text for Category Description -->
                </mat-form-field>
            </div>
            <br>


        </div>
        <mat-card-content>

        </mat-card-content>

    </mat-card>
    <br>
    <!-- Cards Select Ranking-->


    <!-- BUTTON-->
    <div class="sm-row-box-rkScale">
        <div class="button-cancel" style=" display: flex;
  flex-direction: column;
  align-items: center; ">
            <button style="outline: none;color:#d35854;" mat-icon-button (click)="selectedIndex = ''"
                aria-label="ranking">
                <mat-icon>cancel</mat-icon>
            </button>
            <p style="margin-top: -11; color: #D35854; font-size: 21px;">Cancel</p>
        </div>

        <div class="button-Done" style="display: flex; flex-direction: column; align-items: center;">
            <button style="outline: none;color:#76d354;" mat-icon-button
                (click)="selectedIndex = '';upLoadNamesAndRationales(testNamesInput)" [disabled]="false"
                aria-label="ranking">
                <mat-icon>check_circle</mat-icon>
            </button>
            <p style="margin-top: -1; color: #76D354; font-size: 21px;">Done</p>
        </div>
    </div>
</div>
<!-- 🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧END CONFIG BOX 🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧 -->



<!--🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑 RATING TABLE 🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑-->


<!--💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲 TABLE 💻 DESKTOP 💻 GENERATOR 💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲-->
<div class="desktop">
    <div style="display: flex;flex-direction: column;padding-left: 40px;">

        <div style="font-size: 25px;color: #324395;padding-bottom: 10px;">{{bmxItem.componentSettings[0].categoryName}}
        </div>
        <div style="font-size: 18px;color: #ee7f25;padding-bottom: 20px;">
            {{bmxItem.componentSettings[0].categoryDescription}}</div>
    </div>
    <div class="scroller-style main-container">

        <div style="display: flex;flex-direction: row;gap: 20px; align-items: center;width: 750px;">

            <mat-icon (click)="moveleft()" style="font-size: 30px;color: grey;cursor: pointer;" class="full-icon">
                chevron_left</mat-icon>

            <div class="tinder-box">

                <!-- <div [ngClass]="(!bmxItem.componentText[testNameIndex].vote)?'':(bmxItem.componentText[testNameIndex].vote === 'negative')?'positive':'negative'" -->
                <div [ngStyle]="{'color':colorText}"
                    style="font-size: 80px;text-align: center;font-weight: bolder;animation: fadeInRight 1s ease-in 0 1 forwards;">
                    {{( bmxItem.componentText[testNameIndex].nameCandidates)?
                    bmxItem.componentText[testNameIndex].nameCandidates:'TEST NAME'}}
                </div>
                <div style="font-size: 24px;text-align: center;color: #bababa;max-width: 80%;margin: 30px 0 0 0;">
                    {{( bmxItem.componentText[testNameIndex].rationale)?
                    bmxItem.componentText[testNameIndex].rationale:'Rationale of an undisclosed length'}}
                </div>
                <textarea name="comments" placeholder="Comments" style="width: 100%;
                    padding: 20px;" [(ngModel)]="bmxItem.componentText[testNameIndex].comments"></textarea>
                <div class="container-bar">
                    <div class="bar-progress" [ngStyle]="{'width.%':value}"></div>
                </div>
                <span style="font-size: 25px">
                    {{testNameIndex}} / {{bmxItem.componentText.length - 1}}
                </span>

                <!-- LIKE OR DISLIKE SYSTEM -->
                <div *ngIf="!ranking"
                    style="display: flex;flex-direction:row;justify-content: center;align-items: center;gap: 110px;">
                    <mat-icon (click)="voteName('negative')"
                        style="font-size: 65px;color: #C50A19;cursor: pointer;width: 100%"
                        class="full-icon">highlight_off</mat-icon>

                    <mat-icon style="cursor: pointer;color: #324395;"
                        (click)="playTestNameSound(bmxItem.componentText[testNameIndex].nameCandidates)"
                        *ngIf="!editSingleTableCells && displaySound">
                        volume_up
                    </mat-icon>
                    <mat-icon *ngIf="showNeutralIcon" (click)="voteName('neutral')" class="full-icon"
                        style="font-size: 65px;color: #f37e00;cursor: pointer;width: 100%">
                        sentiment_satisfied</mat-icon>

                    <mat-icon (click)="voteName('positive')"
                        style="font-size: 65px;color: #12772F;cursor: pointer;width: 100%"
                        class="full-icon">sentiment_satisfied_alt</mat-icon>
                </div>

                <!-- RANKING SYSTEM -->
                <div *ngIf="ranking"
                    style="display: flex;flex-direction:row;justify-content: center;align-items: center;gap: 35px;">
                    <div *ngFor="let rank of rankingAmountArr; let rankIndex = index">
                        <button style="min-width: 35px;" (click)="voteName(rank)"
                            [ngClass]="(currentrank === rankIndex)?'rankNumberActive':'rankNumber'" mat-raised-button>
                            {{rank}}
                        </button>
                    </div>
                </div>

                <button mat-raised-button (click)="resetVotes()" style="margin-top: 16px;">Reset Votes</button>

            </div>
            <mat-icon *ngIf="survey==false; else isSurvey" (click)="moveRight()"
                style="font-size: 30px;color: grey;cursor: pointer;" class="full-icon">
                chevron_right
            </mat-icon>
            <ng-template #isSurvey>
                <mat-icon *ngIf="hasVoted; else noHasVoted" (click)="moveRight()"
                    style="font-size: 30px;color: grey;cursor: pointer;" class="full-icon">
                    chevron_right
                </mat-icon>
                <ng-template #noHasVoted><mat-icon style="font-size: 30px;color: grey" class="full-icon">
                        chevron_right</mat-icon>
                </ng-template>
            </ng-template>
        </div>
    </div>
</div>
<!--💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲 END TABLE GENERATOR 💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲-->



<!--📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱 TABLE 📱 MOBILE 📱 GENERATOR 📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱-->
<div class="mobile">
    <div style="display: flex;flex-direction: column;text-align: center;">

        <div style="font-size: 25px;color: #324395;padding-bottom: 10px;">{{bmxItem.componentSettings[0].categoryName}}
        </div>
        <div style="font-size: 18px;color: #ee7f25;padding-bottom: 20px;">
            {{bmxItem.componentSettings[0].categoryDescription}}</div>

    </div>
    <div class="scroller-style main-container">

        <div style="display: flex;flex-direction: column;align-items: center;">


            <div class="tinder-box">
                <div [ngStyle]="{'color':colorText}"
                    style="font-size: 50px;text-align: center;font-weight: bolder;animation: fadeInRight 1s ease-in 0 1 forwards;">
                    {{( bmxItem.componentText[testNameIndex].nameCandidates)?
                    bmxItem.componentText[testNameIndex].nameCandidates:'TEST NAME'}}
                </div>
                <div style="font-size: 18px;text-align: center;color: #bababa;">
                    {{( bmxItem.componentText[testNameIndex].rationale)?
                    bmxItem.componentText[testNameIndex].rationale:'Rationale of an undisclosed length'}}
                </div>
                <textarea name="comments" placeholder="comments" style="width: 95%;
                padding: 10px;height: 40px;" [(ngModel)]="bmxItem.componentText[testNameIndex].comments"></textarea>

                <!-- LIKE OR DISLIKE -->
                <div *ngIf="!ranking" style="display: flex;flex-direction:row;justify-content: space-around;align-items: center;width: 100%;position: relative;
                bottom: 15px;">

                    <mat-icon (click)="moveleft()" style="font-size: 30px;color: grey;cursor: pointer;">
                        chevron_left
                    </mat-icon>

                    <div style="display: flex; justify-content: center">
                        <mat-icon (click)="voteName('negative')"
                            style="font-size: 50px;color: #e800d7;cursor: pointer;width: 100%">
                            highlight_off</mat-icon>

                        <mat-icon (click)="voteName('positive')"
                            style="font-size: 50px;color: #00cd38;cursor: pointer;width: 100%">
                            sentiment_satisfied_alt
                        </mat-icon>
                    </div>

                    <mat-icon *ngIf="survey==false; else isSurvey" (click)="moveRight()"
                        style="font-size: 30px;color: grey;cursor: pointer;">
                        chevron_right
                    </mat-icon>
                    <ng-template #isSurvey>
                        <mat-icon *ngIf="hasVoted; else noHasVoted" (click)="moveRight()"
                            style="font-size: 30px;color: grey;cursor: pointer;" class="full-icon">
                            chevron_right
                        </mat-icon>
                        <ng-template #noHasVoted><mat-icon style="font-size: 30px;color: grey" class="full-icon">
                                chevron_right</mat-icon>
                        </ng-template>
                    </ng-template>
                </div>


                <!-- RANKING SYSTEM -->
                <div *ngIf="ranking"
                    style="display: flex;flex-direction:column;justify-content: center;align-items: center;gap: 30px;">

                    <div style="width: 100%;display: flex;justify-content: space-around">
                        <mat-icon (click)="moveleft()" style="font-size: 30px;color: grey;cursor: pointer;">
                            chevron_left
                        </mat-icon>

                        <mat-icon *ngIf="survey==false; else isSurvey" (click)="moveRight()"
                            style="font-size: 30px;color: grey;cursor: pointer;">
                            chevron_right
                        </mat-icon>
                        <ng-template #isSurvey>
                            <mat-icon *ngIf="hasVoted; else noHasVoted" (click)="moveRight()"
                                style="font-size: 30px;color: grey;cursor: pointer;" class="full-icon">
                                chevron_right
                            </mat-icon>
                            <ng-template #noHasVoted><mat-icon style="font-size: 30px;color: grey" class="full-icon">
                                    chevron_right</mat-icon>
                            </ng-template>
                        </ng-template>
                    </div>
                    <div style="display: flex; gap: 5px">
                        <div *ngFor="let rank of rankingAmountArr; let rankIndex = index">
                            <button style="min-width: 35px;" (click)="voteName(rank)"
                                [ngClass]="(currentrank === rankIndex)?'rankNumberActive':'rankNumber'"
                                mat-raised-button>
                                {{rank}}
                            </button>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>

<!--📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱 END TABLE GENERATOR 📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱-->

<div *ngIf="showModalTable" class="modal-video" style="display: flex;flex-direction: column;gap: 2px;">

    <div class="modal-content">

        <div class="content-table" style="display: flex;
        justify-content: center;
        background: white;
        flex-direction: column;
    ">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="box-shadow: none !important;">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <ng-container matColumnDef="nameCandidates">
                    <th mat-header-cell *matHeaderCellDef> Name Candidates</th>
                    <td mat-cell *matCellDef="let element"><input type="text" matInput
                            [(ngModel)]="element.nameCandidates" [value]="element.nameCandidates">
                    </td>

                </ng-container>
                <ng-container matColumnDef="rationale">
                    <th mat-header-cell *matHeaderCellDef> Rationales</th>
                    <td mat-cell *matCellDef="let element"><input type="text" matInput [(ngModel)]="element.rationale"
                            [value]="element.rationale"></td>
                </ng-container>
                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef> Delete</th>
                    <td mat-cell *matCellDef="let element"><mat-icon (dblclick)="deleteName(element)"
                            style="margin-left: 15px; cursor: pointer">delete</mat-icon></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div class="content-button" style="width: 100%; display: flex; justify-content: space-between;">
                <button mat-button *ngIf="!showNewInput"
                    (click)="showModalAddRow = true; showModalTable = false;  newCandidate.nameCandidates = 'TEST NAMES'; newCandidate.rationale = 'Rationale of an undisclosed length'"><mat-icon>add_box</mat-icon></button>
                <button mat-button (click)="showModalTable = false;">Save</button>
            </div>

        </div>

    </div>

</div>

<!-- modal add row -->
<div *ngIf="showModalAddRow" id="myModalrow" class="modal-video" class="modal-video"
    style="display: flex;flex-direction: column;gap: 2px;">

    <div class="modal-content-add-row" style="background: white; padding: 15px" class="modal-content">
        <div class="content-input">
            <mat-form-field class="example-full-width">
                <mat-label>Name Candidates</mat-label>
                <input matInput [(ngModel)]="newCandidate.nameCandidates" value="{{newCandidate.nameCandidates}}">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Rationales</mat-label>
                <textarea rows="4" matInput [(ngModel)]="newCandidate.rationale"
                    value="{{newCandidate.rationale}}"></textarea>
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="uploadNames();showModalTable = true"
                style="color: white">Save</button>
            <button mat-raised-button (click)="showModalAddRow = false;showModalTable = true "
                style="margin-left: 2px;">Cancel</button>
        </div>
    </div>
</div>