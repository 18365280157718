<div *ngIf="showModal" class="modal-background"></div>
<div *ngIf="showModal" class="modal-content">
    <button mat-icon-button class="close-button" aria-label="Close" (click)="closeModal()">
        <mat-icon>close</mat-icon>
    </button>
    <label class="selectLabels">{{selectedDisplayName}}</label>
    <label class="selectLabels">Change template name</label>
    <div style="width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center; overflow-x: auto;">
        <mat-form-field appearance="outline">
            <input type="text" placeholder="Enter a new name" matInput [(ngModel)]="newTemplateName">
        </mat-form-field>

    </div>
    <button mat-raised-button color="success" style=" background-color: #063074; color: white; width: 40%;;" (click)="saveNewName()">Save </button>
</div>
<div *ngIf="showDialog">
    <app-survey-dialog (onConfirm)="deleteTemplate(nameToDialog)" (onCancel)="closeDialog()"
        [dialogText]="'Are you sure you want to delete this template'"></app-survey-dialog>
</div>
<div style="position: relative; top: 10px">
    <mat-form-field style="padding-right: 20px" appearance="outline">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value)" value="" placeholder="Search Project">
    </mat-form-field>
    <button mat-raised-button color="success" style=" background-color: #063074;color: white;width: 140px;position: relative;left: 65%;" (click)=" showNewTemplateModal = true">Add template</button>    
</div>

<div *ngIf="showNewTemplateModal" class="modal-content" style="gap: 10px;">
    <button mat-icon-button class="close-button" aria-label="Close" (click)="closeModal()">
        <mat-icon>close</mat-icon>
    </button>

    <div *ngIf="!existingTemplate && !newTemplate" style="width: 100%; display: flex; flex-direction: column; gap: 15px">
        <div style="display: flex; align-items: center; gap:unset 15px;  justify-content: space-between;">
            Create a new empty template <button mat-raised-button color="success" style=" background-color: #063074;
            color: white;
            width: 200px;" (click)="newTemplate = true">Create new template</button>
        </div>
        <div style="display: flex; align-items: center; gap:unset 15px;  justify-content: space-between;">
            Create a new template from an existing one <button mat-raised-button color="success" style=" background-color: #063074;
            color: white;
            width: 200px;" (click)="existingTemplate = true">Clone template</button>
        </div>
    </div>

    <div *ngIf="existingTemplate" style="display: flex; flex-direction: column;align-items: center;">
        <label class="selectLabels">New template name</label>
        <div style="width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center; overflow-x: auto;">
            <mat-form-field appearance="outline">
                <input type="text" placeholder="Enter a new name" matInput [(ngModel)]="newTemplateName">
            </mat-form-field>
        </div>
        <p style="text-align: center;color: grey;" *ngIf="templateName.length == 0">select one to load or update</p>
        <mat-form-field appearance="outline">
            <mat-label>Templates</mat-label>
            <mat-select [(ngModel)]="selectedTemplateName" >
                <mat-option *ngFor="let template of TEMPLATES " [value]="template.templateName">
                    {{template.displayName? template.displayName: template.templateName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="templateName.length > 3" style="display: flex;flex-direction: row;justify-content: space-evenly; width: 300px;">
            <button *ngIf="isSaveOrUpdate" (click)="saveTemplateFromAnExistingOne(selectedTemplateName)"
                mat-raised-button style="color: white;background-color: #8a2a5a ">
                load
            </button>
        </div>
        <button mat-raised-button color="success" style=" background-color: #063074;color: white; width: 40%;;" (click)="saveTemplateFromAnExistingOne(selectedTemplateName)">Save </button>
    </div>

    <div *ngIf="newTemplate" style="display: flex;flex-direction: column;align-items: center;">

        <label class="selectLabels">New template name</label>
        <div style="width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center; overflow-x: auto;">
            <mat-form-field appearance="outline">
                <input type="text" placeholder="Enter a new name" matInput [(ngModel)]="newTemplateName">
            </mat-form-field>
        </div>
        <button mat-raised-button color="success" style=" background-color: #063074;color: white;width: 40%;" (click)="saveNewName(true)">Save </button>
    </div>
</div>

<div style="position: relative;top: 5%;">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%;">
        <!-- Position Column -->
        <ng-container matColumnDef="index">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
            <td mat-cell *matCellDef="let element">{{ element.index }}</td>
        </ng-container>
        <ng-container matColumnDef="displayName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Template Name</th>
            <td mat-cell *matCellDef="let element"> {{element.displayName}} </td>
        </ng-container>
        <ng-container matColumnDef="TemplateName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Template name </th>
            <td mat-cell *matCellDef="let element"> {{element.templateName}} </td>
        </ng-container>
        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
            <td mat-cell *matCellDef="let element">{{element.created | date:'dd MMMM yyyy, hh:mm:ss a'}}</td>
        </ng-container>        

        <ng-container matColumnDef="Edit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 3.33%;"> </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button matTooltip="edit template" style="color: #48bcb4;" aria-label="Example icon button with a home icon"
                    (click)=" selectedTemplateName = element.templateName; templateName = element.templateName; templateSelected(element.templateName, element.displayName, element.brandMatrix) ; selectedTemplateName= element.displayName? element.displayName : element.templateName">
                    <mat-icon>build</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 3.33%;"> </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button matTooltip="change template name" mat-icon-button
                    (click)="selectedDisplayName = element.displayName;openModal(); selectedTemplateName = element.templateName; templateName = element.templateName; bmxPages = element.brandMatrix"
                    aria-label="Example icon button with a home icon">
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </ng-container>
      
        <ng-container matColumnDef="Delete">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 3.33%;"> </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button matTooltip="delete template" (click)="showDialogComponent(element.templateName)" mat-icon-button style="color: #bc488a;" aria-label="Example icon button with a home icon">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>    
</div>
