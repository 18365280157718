<div class="container-modal" style=" display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
margin: 34px;
border-radius: 10px;
" *ngIf="infoMessage">
    <form  name="loginForm" [formGroup]="loginForm" novalidate>


        <div *ngIf="!isDeleting" class="mat-h2" style="text-align: center;">Are you sure you want to delete? </div>
        <div *ngIf="!isDeleting" class="mat-h1" style="text-align: center;color: #004684;">{{editName}}</div>
        <div *ngIf="!isDeleting" class="mat-h4">If you delete this name, it will be removed permanently.</div>

        <div class="columns-input" style="display: flex; flex-direction: column;">
            <mat-form-field *ngIf="isDeleting" appearance="outline" class="responsive-input">
              <mat-label>Edit Name</mat-label>
              <input matInput type="name" placeholder="name" formControlName="name">
            </mat-form-field>
            
            <mat-form-field *ngIf="isDeleting" appearance="outline" class="responsive-input">
              <mat-label>Rationale</mat-label>
              <input matInput type="text" placeholder="rationale" formControlName="rationale">
            </mat-form-field>
          </div>
          

        <div class="row" style="margin-top: -8px; text-align: center; justify-content: center;">

            <div class="row-icon" style="display: flex; gap: 56px;" fxFlex fxLayoutAlign="center center">

                <div fxFlex *ngIf="isDeleting">
                    <button (click)="buttonOption('delete')" class="delete-update-buttons" mat-mini-fab
                        style="color: white;background-color: red;" color="success" aria-label="icon">
                        <mat-icon>delete</mat-icon>
                    </button><br>
                    <span>delete</span>
                </div>

                <div fxFlex *ngIf="isDeleting">
                    <button (click)="buttonOption('like')" *ngIf="!favourite" class="delete-update-buttons" mat-mini-fab
                        style="color: white;background-color: grey;" color="success" aria-label="icon">
                        <mat-icon>favorite</mat-icon>
                    </button>
                    <button (click)="buttonOption('like')"  *ngIf="favourite" class="delete-update-buttons" mat-mini-fab
                        style="color: white;background-color: red;" color="success" aria-label="icon">
                        <mat-icon>favorite</mat-icon>
                    </button>
                    <br>
                    <span>favorite</span>


                </div>

                <div fxFlex *ngIf="isDeleting">
                    <button (click)="buttonOption('save')" class="delete-update-buttons" mat-mini-fab
                        style="color: white;background-color: green;" color="success" aria-label="icon">
                        <mat-icon>check_circle</mat-icon>
                    </button><br>
                    <span>update</span>


                </div>

                <div fxFlex="50" *ngIf="!isDeleting">
                    <button (click)="buttonOption('cancel')" class="alertButons" mat-mini-fab
                        style="color: white;background-color: grey;" color="success" aria-label="icon">
                        <mat-icon>cancel</mat-icon>
                    </button><br>
                    <span>cancel</span>

                </div>

                <div fxFlex="50" *ngIf="!isDeleting">

                    <button (click)="buttonOption('delete')" class="alertButons" mat-mini-fab
                        style="color: white;background-color: red;" color="success" aria-label="icon">
                        <mat-icon>delete</mat-icon>
                    </button><br>
                    <span>delete</span>
                </div>
            </div>
        </div>

    </form>
</div>
<div class="modal-container-help" style=" display: block;
padding: 24px;

" *ngIf="!infoMessage">
    <div class="mat-h4" style="text-align: center;">Help</div>
    <hr>
    <div class="mat-h3" style="text-align: center;color: #004684;">Touch or click a name to edit or delete. v0.5.07
    </div>

    <div class="end-button" style="display: flex;justify-content: flex-end;" fxLayoutAlign="end end">
        <button (click)="buttonOption('dismiss')" class="refreshbutton" mat-mini-fab style="font-size: 8px;"
            color="success" aria-label="icon">
            <mat-icon>cancel</mat-icon>
        </button>
    </div>
</div>

