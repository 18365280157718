<mat-toolbar class="toolbar mat-elevation-z2">{{projectName}}</mat-toolbar>

<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">

            <section class="text-center " style="padding: 35px 0px 31px 0px;">
                <div fxLayout="row" fxLayoutAlign="center center" >
                    <div><h1 style="font-size: 40px;">{{nameToDisplay}}</h1></div>&nbsp;
                    <div>
                        <span style="font-size:19px ;">{{notation}}</span>
                    </div>
                </div>
               
                <h3 style="max-width: 500px;" [innerHTML]="rationales" html style="font-size: 28px;"></h3>
                <hr>

            </section>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <section class="text-center " style="padding: 35px 0px 31px 0px;">

                        <button *ngIf="!positiveVote && !readOnlyName && name !== '....'" (click)="setPositive()" type="button"
                            class="btn btn-outline-success btn-lg mat-elevation-z8 buttons-positive" style="width: 260px;">
                            POSITIVE
                        </button>
                        <button *ngIf="positiveVote && !readOnlyName && name !== '....'" (click)="removeVote()" type="button"
                            class="btn btn-lg mat-elevation-z2 "
                            style="background-color: #1b5e20;color: white;width: 260px;">
                            POSITIVE
                        </button>
                        <button *ngIf="readOnlyName || name === '....'"  [disabled]="true" type="button"
                            class="btn btn-lg mat-elevation-z2 "
                            style="color:grey;width: 260px;">
                            POSITIVE
                        </button>

                    </section>

                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <section class="text-center " style="padding: 35px 0px 31px 0px;">

                        <button  *ngIf="!neutralVote  && !readOnlyName   && name !== '....'" (click)="setNeutral()" type="button"
                            class="btn btn-outline-primary btn-lg mat-elevation-z8 buttons-neutral" style="width: 260px ">
                            NEUTRAL
                        </button>
                        <button *ngIf="neutralVote  && !readOnlyName  && name !== '....'" (click)="removeVote()" type="button"
                            class="btn btn-lg mat-elevation-z2"
                            style="background-color: #0d47a1;color: white;width: 260px;">
                            NEUTRAL
                        </button>
                        <button *ngIf="readOnlyName || name === '....'" [disabled]="true" type="button"
                            class="btn btn-lg mat-elevation-z2"
                            style="color: grey;width: 260px;">
                            NEUTRAL
                        </button>
                    </section>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <section class="text-center " style="padding: 35px 0px 31px 0px;">

                        <button  *ngIf="!negativeVote  && !readOnlyName  && name !== '....'" (click)="setNegative()" type="button"
                            class="btn btn-outline-danger btn-lg mat-elevation-z8 buttons-negative" style="width: 260px;">
                            NEGATIVE
                        </button>
                        <button *ngIf="negativeVote  && !readOnlyName  && name !== '....'" (click)="removeVote()" type="button"
                            class="btn btn-lg mat-elevation-z2"
                            style="background-color: #b71c1c;color: white;width: 260px;">
                            NEGATIVE
                        </button>
                        <button *ngIf="readOnlyName || name === '....'"  [disabled]="true" type="button"
                            class="btn btn-lg mat-elevation-z2"
                            style="color:grey;width: 260px;">
                            NEGATIVE
                        </button>

                    </section>

                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="progress mat-elevation-z2" style="margin-top: 50px;">
                <div class="progress-bar bg-info" role="progressbar" [style]="progressBarCompletion" aria-valuenow="50"
                    aria-valuemin="0" aria-valuemax="100">{{progressBarValue}}%</div>
            </div>
        </div>
    </div>
</div>

