<div *ngIf="showDialog"><app-survey-dialog (onConfirm)="confirmAction()" (onCancel)="closeDialog()"
    [dialogText]="dialogText"></app-survey-dialog>
</div>
<div *ngIf="showCreationModalVideo && bmxClientPageOverview" id="myModal" class="modal-video"
style="display: flex;flex-direction: column;gap: 2px;">
<!-- <label class="container-checkbox">Don't show again
    <input (click)="saveSelection()" type="checkbox" value="Bike" #modalChecked>
    <span class="checkmark">&#x2714;</span>
</label> -->
<div class="modal-content">
    <video src='{{CREATION_VIDEO_PATH}}' controls></video>

</div>
<button class="btn btn-raised close-modal" (click)="showCreationModalVideo=false">close</button>
</div>
<!-- ➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗  SETTINGS ICONS  ➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗ -->
<div *ngIf="bmxClientPageOverview" style="display: flex;
flex-direction: row-reverse;
justify-content: end;
top: -30px;
right: 50px;border: 2px dashed #324395;padding-right: 10px;background: #ffffffd9;
position: absolute;height: 54px;align-items: center">

<!-- ⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺RAGE INPUTS FOR COLUMN SETTINGS ⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺ -->

<div *ngIf="isColumnResizerOn && bmxClientPageOverview" class="" style="    display: flex;
    flex-direction: row;
    justify-content: start;background: #ffffffd9;
    gap: 0px;position: absolute;left: -713px;z-index: 1;border: 2px dashed #324395;">
    <!-- NAME CANDIATES -->
    <div style="display: flex;flex-direction: column;align-items: center;">
        <p style="margin: 0 0 -12px;color: #324395;">questions
        </p>
        <input type="range" style="margin: 20px;width: 50px;"
            [(ngModel)]="bmxItem.componentSettings[0].nameCandidatesWidth" class="slider-font-size"
            tickInterval="0.5" min="50" max="900" [value]="bmxItem.componentSettings[0].nameCandidatesWidth">
    </div>

    <div style="display: flex;flex-direction: column;align-items: center;">
        <p style="margin: 0 0 -12px;color: #324395;">answers
        </p>
        <input type="range" style="margin: 20px;width: 50px;"
            [(ngModel)]="bmxItem.componentSettings[0].rationalewidth" class="slider-font-size" tickInterval="0.5"
            min="50" max="900" [value]="bmxItem.componentSettings[0].rationalweidth">

    </div>

    <!-- COMMENTS -->
    <div style="display: flex;flex-direction: column;align-items: center;">
        <p style="margin: 0 0 -12px;color: #324395;">options
        </p>
        <input type="range" style="margin: 20px;width: 50px;"
            [(ngModel)]="bmxItem.componentSettings[0].commentsWidth" class="slider-font-size" tickInterval="0.5"
            min="50" max="900" [value]="bmxItem.componentSettings[0].commentsWidth">

        </div>


    <!-- REST OF THE COLUMNS -->
    <div style="display: flex;flex-direction: column;align-items: center;">
        <p style="margin: 0 0 -12px;color: #324395;">other columns
        </p>
        <input type="range" style="margin: 20px;width: 50px;" [(ngModel)]="bmxItem.componentSettings[0].columnWidth"
            class="slider-font-size" tickInterval="0.5" min="50" max="900"
            [value]="bmxItem.componentSettings[0].columnWidth">
    </div>

    <!-- ROW HEIGHT -->

    <div style="display: flex;flex-direction: column;align-items: center;">
        <p style="margin: 0 0 -12px;color: #324395;">row height</p>
        <input type="range" style="margin: 20px;width: 60px;" [(ngModel)]="bmxItem.componentSettings[0].rowHeight"
            class="slider-font-size" tickInterval="0.5" min="-10" max="20"
            [value]="bmxItem.componentSettings[0].rowHeight">
    </div>

    <!-- FONT -->
    <div style="display: flex;flex-direction: column;align-items: center;">
        <p style="margin: 0 0 -12px;color: #324395;">font size: {{bmxItem.componentSettings[0].fontSize}}</p>
        <input type="range" style="margin: 20px;width: 50px;" [(ngModel)]="bmxItem.componentSettings[0].fontSize"
            class="slider-font-size" tickInterval="0.1" min="2" max="50"
            [value]="bmxItem.componentSettings[0].fontSize">
    </div>

    <!-- RADIO -->
    <div *ngIf="radioColumnCounter > 1" style="display: flex;flex-direction: column;align-items: center;">
        <p style="margin: 0 0 -12px;color: #324395;">radio</p>
        <input type="range" style="margin: 20px;width: 50px;"
            [(ngModel)]="bmxItem.componentSettings[0].radioColumnsWidth" class="slider-font-size" tickInterval="0.5"
            min="50" max="100" [value]="bmxItem.componentSettings[0].radioColumnsWidth">
    </div>
</div>

<!-- <mat-slider min="200" max="500" step="10" value="200" [(value)]="bmxItem.componentSettings[0].columnWidth"></mat-slider><p>{{bmxItem.componentSettings[0].columnWidth}}</p> -->
<!-- ⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺  END  ⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺ -->

<div style="display: flex; flex-direction: row-reverse;">
    <button (click)="showMatrixMenuBmx()" mat-icon-button matTooltip="{{textToolTip}}">
        <mat-icon style="font-size: 26px;" class="full-icon">{{iconMenuShow}}</mat-icon>
    </button>
    <div *ngIf="showMatrixMenu">
        <button (click)="openDialog('undo');editSingleTableCells = false" class="add-column"
            matTooltip="undo last change" matTooltipPosition="above" mat-icon-button aria-label="ranking">
            <mat-icon style="font-size: 26px;" class="full-icon">undo</mat-icon>
        </button>
        <button (click)="selectedIndex = ''; editSingleTableCells = false" class="add-column" matTooltip="cancel"
            matTooltipPosition="above" *ngIf="i === selectedIndex" mat-icon-button>
            <mat-icon style="font-size: 25px;" class="full-icon">check_circle</mat-icon>
        </button>
        <button (click)="deleteRows = !deleteRows;editSingleTableCells = false"
            [ngClass]="(!deleteRows)?'add-column':'add-column-active'" matTooltip="delete rows"
            matTooltipPosition="above" mat-icon-button aria-label="ranking">
            <mat-icon style="font-size: 26px;" class="full-icon">delete_outline</mat-icon>
        </button>
        <button (click)="insertTextColumn(); editSingleTableCells = false" class="add-column"
            matTooltip="add text column" matTooltipPosition="above" mat-icon-button aria-label="ranking">
            <mat-icon style="font-size: 26px;" class="full-icon">view_list</mat-icon>
        </button>
        <!-- <button (click)="insertRadioColumn()" class="add-column"  matTooltip="add radio column"
            matTooltipPosition="above" mat-icon-button aria-label="ranking">
            <mat-icon style="font-size: 26px;" class="full-icon">radio_button_checked</mat-icon>
        </button> -->

        <button (click)="insertRow(); editSingleTableCells = false" class="add-column" matTooltip="insert row"
            matTooltipPosition="above" mat-icon-button aria-label="ranking">
            <mat-icon style="font-size: 26px;" class="full-icon">vertical_distribute</mat-icon>
        </button>
        <button (click)="dragRows = !dragRows; editSingleTableCells = false"
            [ngClass]="(!dragRows)?'add-column':'add-column-active'" matTooltip="drag rows"
            matTooltipPosition="above" mat-icon-button aria-label="ranking">
            <mat-icon style="font-size: 26px;" class="full-icon">drag_indicator</mat-icon>
        </button>

        <button (click)="editSingleTableCells = !editSingleTableCells"
            [ngClass]="(!editSingleTableCells)?'add-column':'add-column-active'" matTooltip="edit table cells"
            matTooltipPosition="above" mat-icon-button aria-label="ranking">
            <mat-icon style="font-size: 26px;" class="full-icon">explicit</mat-icon>
        </button>
    </div>
    <button (click)="showCreationModalVideo=true; editSingleTableCells = false" class="add-column"
        matTooltip="see tutorial" matTooltipPosition="above" mat-icon-button aria-label="ranking">
        <mat-icon style="font-size: 26px;" class="full-icon">video_library</mat-icon>
    </button>

    <button (click)="insertAnswerColumn(); editSingleTableCells = false" class="add-column"
        matTooltip="add comments column" matTooltipPosition="above" mat-icon-button aria-label="ranking">
        <mat-icon style="font-size: 26px;" class="full-icon">wysiwyg</mat-icon>
    </button>
    <button (click)="isColumnResizerOn = !isColumnResizerOn;editSingleTableCells = false"
        [ngClass]="(!isColumnResizerOn)?'add-column':'add-column-active'" matTooltip="columns width"
        matTooltipPosition="above" mat-icon-button aria-label="ranking">
        <mat-icon style="font-size: 26px;    z-index: 9;" class="full-icon">swap_horizontal_circle</mat-icon>
    </button>
    <button (click)="selectedIndex = i; editSingleTableCells = false; verifyCritera(); verifyCritera()"
        class="add-column" matTooltip="setup settings" matTooltipPosition="above" *ngIf="i !== selectedIndex"
        mat-icon-button>
        <mat-icon style="font-size: 25px;" class="full-icon">edit</mat-icon>
    </button>
</div>
</div>
<!-- ➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗  END  ➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗➗ -->

<!-- 🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧 CONFIG BOX 🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧 -->
<div *ngIf="i === selectedIndex" class="mat-elevation-z4" cdkDrag style="position: absolute;width: 50%;background: #fafafa;z-index: 1;top: -65px;left: 25%;border: #063074 4px solid; overflow: auto;
   max-height: 36rem;
bottom: -2; ">

<div class="example-handle" cdkDragHandle style="display: flex;
flex-direction: row;
justify-content: center;cursor: move; height: 25px; width: 100%;"><svg width="24px" fill="currentColor"
        viewBox="0 0 24 24">
        <path
            d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
        </path>
        <path d="M0 0h24v24H0z" fill="none"></path>
    </svg></div>
<!-- Cards Test Name-->

<mat-card class="example-card">
    <mat-card-header>
        <mat-card-title>Option Type

        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="sm-section3-rkScale">

            <div class="sm-row-box-rkScale">

                <div style="margin-top: 0.5rem;">
                    <mat-radio-group aria-label="Select an option"
                        [(ngModel)]="this.bmxItem.componentSettings[0].rankType">
                        <div style="    display: flex;
                        flex-direction: column;
                        gap: 1px;
                        align-items: flex-start;">
                            <mat-radio-button value="dropDown">DropDown</mat-radio-button>
                            <mat-radio-button value="radio">Radio Buttons</mat-radio-button>
                            <mat-radio-button value="checkbox">Checkbox</mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
                <!-- <select>
              <option value="">
                  <mat-icon style="color:#e6ad11;">rectangle</mat-icon>
              </option>
              <option value="valid" selected>
                  <mat-icon style="color:#76d354;">grade</mat-icon>
              </option>
              <option value="invalid">
                  <mat-icon style="color:#e6ad11;">rectangle</mat-icon>
              </option>
          </select> -->
            </div>
        </div>
    </mat-card-content>

</mat-card>

<mat-card class="example-card">
    <mat-card-header>
        <mat-card-title>Question and Options</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div style="text-align: center;">
            <div style="font-size: 16px;margin: 20px;color: #48535e;">Paste Questions & Options </div>
            <!-- <div style="font-size: 14px;margin-bottom: 20px;color: lightgray;">Brief Description Here</div> -->
        </div>
        <div style="display: flex; justify-content: center; align-items: center;">
            <mat-form-field style="width: 90%; margin-left: 20px;" appearance="outline">
                <mat-label>Copy & Paste Columns</mat-label>
                <textarea matInput [(ngModel)]="testNamesInput" (paste)="onPaste(); bmxItem.componentSettings[0].commentsWidth = 250; bmxItem.componentSettings[0].nameCandidatesWidth = 500"
                    (ngModelChange)="upLoadNamesAndRationales(testNamesInput)" placeholder="Paste test names here"
                    cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5"></textarea>
                <mat-hint>Type or paste the test names here that you want to include</mat-hint>
            </mat-form-field>
        </div>

    </mat-card-content>

</mat-card>
<br>
<!-- Cards Criteria-->
<mat-card class="example-card">
    <mat-card-header>
        <mat-card-title>Category</mat-card-title>
    </mat-card-header>
    <br>
    <div class="sm-section2-rkScale">
        <div class="sm-column-box-rkScale">
            <mat-form-field appearance="outline" style="width: 40%">
                <mat-label>Category Name</mat-label>
                <input matInput [(ngModel)]="bmxItem.componentSettings[0].categoryName" placeholder="Category Name"
                    style="font-size: 20px;color: #324395;">
                <mat-hint>Enter the name of the category</mat-hint> <!-- Hint text for Category Name -->
            </mat-form-field>

            <mat-form-field appearance="outline" style="width: 40%">
                <mat-label>Sub category Description</mat-label>
                <input matInput [(ngModel)]="bmxItem.componentSettings[0].categoryDescription"
                    placeholder="Sub category Description" style="font-size: 20px;color: #ee7f25;">
                <mat-hint>Enter a brief description of the Sub category </mat-hint>
                <!-- Hint text for Category Description -->
            </mat-form-field>
        </div>
        <br>

        <mat-checkbox [(ngModel)]="randomizeTestNames">Randomize Questions</mat-checkbox>

    </div>
    <mat-card-content>

    </mat-card-content>

</mat-card>
<br>



<!-- BUTTON-->
<div class="sm-row-box-rkScale">

    <div class="button-cancel" style=" display: flex;
    flex-direction: column;
    align-items: center; ">
              <button style="outline: none;color:#d35854;   font-size: 21px;" mat-icon-button (click)="selectedIndex = ''"
                  aria-label="ranking">
                  <mat-icon>cancel</mat-icon>
              </button>
              <p style="margin-top: -11; color: #D35854; font-size: 21px;">Cancel</p>
          </div>

    <div class="button-Done"style="display: flex; flex-direction: column; align-items: center;">

      <button style="outline: none;color:#76d354;   font-size: 21px;" mat-icon-button
      (click)="selectedIndex = '';upLoadNamesAndRationales(testNamesInput)" [disabled]="false"
      aria-label="ranking">
          <mat-icon>check_circle</mat-icon>
      </button>
      <p style="margin-top: -1; color: #76D354; font-size: 21px;">Done</p>
    </div>
</div>
</div>

<!-- 🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧END CONFIG BOX 🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧 -->

<!--🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑 RATING TABLE 🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑-->

<!--💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲  TABLE GENERATOR 💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲-->
<div class="desktop">
<div style="display: flex;flex-direction: column;padding-left: 40px;">

    <div style="font-size: 25px;color: #324395;padding-bottom: 10px;">{{bmxItem.componentSettings[0].categoryName}}
    </div>
    <div style="font-size: 18px;color: #ee7f25;padding-bottom: 20px;">
        {{bmxItem.componentSettings[0].categoryDescription}}</div>

</div>
<div class="scroller-style main-container">

    <div *ngFor="let testName of bmxItem.componentText; let y = index">

        <!-- <div *ngIf="(y !== 0)" style="color: white;font-size: xx-small;">.</div> -->
        <div class="test-name-table">
            <div *ngFor="let columnName of columnsNames; let e = index"
            style="display: flex;align-items: center; justify-content: center;"
            [ngClass]="(e % 2 === 0) ? 'red' : 'blue'"
            [style.width]="(columnName.includes('name')) ? bmxItem.componentSettings[0].nameCandidatesWidth + 'px' :
                          (columnName.includes('options')) ? bmxItem.componentSettings[0].commentsWidth + 'px' :
                          (columnName.includes('Answers')) ? bmxItem.componentSettings[0].rationalewidth + 'px' :
                          bmxItem.componentSettings[0].columnWidth + 'px'"
            [style.justify-content]="(testName[columnName] === 'Questions') ? 'center' : 'flex-start'">
            <div *ngIf="(y == 0)" class="header-title " (mouseenter)="selectedColumn = e"
                    (mouseleave)="selectedColumn = ''">

                    <!-- IF YOU WANT TO DELET ALL COLUMNS PASS THE COLUMN NAME  NOT testName[columnName]-->
                    <span *ngIf="selectedColumn === e && bmxClientPageOverview" class="header-delete-icon"
                        matTooltip="double click to delete column" matTooltipPosition="above"
                        (dblclick)="openDialog('delete column', columnName)">x</span>

                    <!-- 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 HEADER TEXT AREA 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 -->

                    <textarea *ngIf="y == 0 && editSingleTableCells"
                        [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                        style="text-align: center; font-weight: bold;color: #324395;" class="row-box"
                        [(ngModel)]="testName[columnName]" value="testName[columnName]"></textarea>


                    <p style="text-align: center; font-weight: bold;color: #324395;margin: 0 0 0px;"
                        [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                        *ngIf=" !editSingleTableCells">
                        {{testName[columnName]}}
                    </p>
                </div>

                <!-- 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 END HEADER TEXT AREA  🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 -->


                <!-- 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 TABLE CELLS  🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 -->
                <div>

                    <div
                        *ngIf="y !== 0  &&  columnName.includes('options') == true && this.bmxItem.componentSettings[0].rankType == 'radio'">
                        <div *ngFor="let checkBoxName of testName[columnName].split(','); let m = index"
                            style="display: flex;flex-direction: row;gap:10px; justify-content: space-between;  ">
                            <label for="">{{checkBoxName}}</label>
                            <!-- <input type="checkbox" [checked]="allComplete" -->
                            <input type="radio" [checked]="testName['RATE']==checkBoxName"
                                (change)="saveChoice(checkBoxName, y,  $event)">
                        </div>
                    </div>
                    <div
                        *ngIf="y !== 0  &&  columnName.includes('options') == true && this.bmxItem.componentSettings[0].rankType == 'checkbox'">
                        <div *ngFor="let checkBoxName of testName[columnName].split(','); let m = index"
                            style="display: flex;flex-direction: row;gap:10px; justify-content: space-between;  ">
                            <label for="">{{checkBoxName}}</label>
                            <!-- <input type="checkbox" [checked]="allComplete" -->
                            <input type="checkbox"
                                [checked]="(testName['RATE'] && testName['RATE']!=-1 && isChecked(checkBoxName, y))?true:allComplete"
                                (change)="saveMultipleChoice(checkBoxName, y,  $event)">
                        </div>
                    </div>

                    <div
                        *ngIf="y !== 0  &&  columnName.includes('options') == true && this.bmxItem.componentSettings[0].rankType == 'dropDown'">
                        <mat-form-field *ngIf="this.bmxItem.componentSettings[0].rankType == 'dropDown'"
                            appearance="outline" style="width: 110px;padding-top: 13px;height: 80px;">
                            <mat-label>Rank 1 to {{rankingScaleValue}}</mat-label>
                            <mat-select matNativeControl [(ngModel)]="bmxItem.componentText[y].RATE"
                                (selectionChange)="setRating(bmxItem.componentText[y].RATE,y)">
                                <mat-option
                                    *ngFor="let checkBoxName of testName[columnName].split(','); let m = index"
                                    [value]="checkBoxName">
                                    {{checkBoxName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- TEXT_AREA COLUMN-->
                    <!-- <textarea *ngIf="y !== 0  && columnName.includes('Answers') == true"
                        [style.width]="(columnName == 'rationale')?bmxItem.componentSettings[0].rationalewidth + 'px':bmxItem.componentSettings[0].columnWidth +'px'"
                        [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'" cdkTextareaAutosize
                        [cdkAutosizeMinRows]="bmxItem.componentSettings[0].rowHeight"
                        style="text-align: initial;height: 32px;width: 358px;font-size: 16px;border: 1px solid grey;padding: 6px 10px;"
                        class="row-box" [(ngModel)]="testName[columnName]" rows="4" cols="50"></textarea> -->

                    <!-- add on blur -->
                    <textarea *ngIf="y !== 0  && columnName.includes('Answers') == true"
                        [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'" cdkTextareaAutosize
                        [cdkAutosizeMinRows]="bmxItem.componentSettings[0].rowHeight"
                        style="text-align: center;height: 32px;font-size: 16px;border: 1px solid #dddddd;"
                        class="row-box" [(ngModel)]="testName[columnName]"
                        (focusout)="testName[columnName] ? autosaveAnswer($event) : null" rows="4"
                        cols="50"></textarea>


                    <!-- TEXT AREA TO EDIT TABLE CELL CONTENT -->
                    <textarea
                        *ngIf="y !== 0  && editSingleTableCells && (columnName.includes('options') != true) &&  columnName.includes('Answers') != true"
                        [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'" cdkTextareaAutosize
                        [cdkAutosizeMinRows]="bmxItem.componentSettings[0].rowHeight"
                        style="outline: none;text-align: initial;margin: 0 10px;" class="row-box"
                        [(ngModel)]="testName[columnName]"></textarea>

                    <p *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('options') != true) &&  columnName.includes('Answers') != true"
                        [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'"
                        style="text-align: initial;margin: 0 0 0px;"
                        [innerHTML]="testName[columnName] | safe: 'html'">{{testName[columnName]}}</p>
                </div>
                <!-- 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 END TABLE CELLS 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 -->
            </div>

            <div *ngIf="(y !== 0) && bmxClientPageOverview && deleteRows"
                style="min-width: 100px; position: absolute;right: 0px;">
                <button style="outline: none;margin:3px -8px;" (dblclick)="deletRow(y)" mat-icon-button
                    matTooltip="doble click to delete row" matTooltipPosition="left" aria-label="ranking">
                    <mat-icon>delete_outline</mat-icon>
                </button>
            </div>
            <!--♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️ END TABLE ROW ICONS ♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️♦️-->
        </div>
    </div>

</div>
</div>


<!--💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲 END TABLE GENERATOR 💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲💲-->


<!--📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱 TABLE 📱 MOBILE 📱 GENERATOR 📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱-->
<div class="mobile">
<div style="display: flex;flex-direction: column;text-align: center;">

    <div style="font-size: 25px;color: #324395;padding-bottom: 10px;">{{bmxItem.componentSettings[0].categoryName}}
    </div>
    <div style="font-size: 18px;color: #ee7f25;padding-bottom: 20px;">
        {{bmxItem.componentSettings[0].categoryDescription}}</div>

</div>
<div class="scroller-style main-container">

    <div *ngFor="let testName of bmxItem.componentText; let y = index" style="margin-bottom: 10px;">

        <div class="test-name-table" *ngIf="(y != 0)" [style.height]="(selectedCard == y)?'':''">


            <div *ngFor="let columnName of columnsNames; let e =  index">


                <!-- 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 END HEADER TEXT AREA  🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 -->


                <!-- 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 TABLE CELLS  🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 -->
                <div>

                    <!-- IF ELSE TEMPLATE INPUTS  -->

                    <div
                        *ngIf="y !== 0  &&  columnName.includes('options') == true && this.bmxItem.componentSettings[0].rankType == 'radio'">
                        <div *ngFor="let checkBoxName of testName[columnName].split(','); let m = index"
                            style="display: flex;flex-direction: row;gap:10px; justify-content: space-between;  ">
                            <label for="">{{checkBoxName}}</label>
                            <!-- <input type="checkbox" [checked]="allComplete" -->
                            <input type="radio" [checked]="testName['RATE']==checkBoxName"
                                (change)="saveChoice(checkBoxName, y,  $event)">
                        </div>
                    </div>
                    <div
                        *ngIf="y !== 0  &&  columnName.includes('options') == true && this.bmxItem.componentSettings[0].rankType == 'checkbox'">
                        <div *ngFor="let checkBoxName of testName[columnName].split(','); let m = index"
                            style="display: flex;flex-direction: row;gap:10px; justify-content: space-between;  ">
                            <label for="">{{checkBoxName}}</label>
                            <!-- <input type="checkbox" [checked]="allComplete" -->
                            <input type="checkbox"
                                [checked]="(testName['RATE'] && testName['RATE']!=-1 && isChecked(checkBoxName, y))?true:allComplete"
                                (change)="saveMultipleChoice(checkBoxName, y,  $event)">
                        </div>
                    </div>

                    <div
                        *ngIf="y !== 0  &&  columnName.includes('options') == true && this.bmxItem.componentSettings[0].rankType == 'dropDown'">
                        <mat-form-field *ngIf="this.bmxItem.componentSettings[0].rankType == 'dropDown'"
                            appearance="outline" style="width: 110px;padding-top: 13px;height: 80px;">
                            <mat-label>Rank 1 to {{rankingScaleValue}}</mat-label>
                            <mat-select matNativeControl [(ngModel)]="bmxItem.componentText[y].RATE"
                                (selectionChange)="setRating(bmxItem.componentText[y].RATE,y)">
                                <mat-option
                                    *ngFor="let checkBoxName of testName[columnName].split(','); let m = index"
                                    [value]="checkBoxName">
                                    {{checkBoxName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- TEXT_AREA COLUMN-->
                    <div style="display: flex;
                    justify-content: center;">
                        <textarea *ngIf="y !== 0  && columnName.includes('Answers') == true " [style.width]="'95%'"
                            [style.font-size]="bmxItem.componentSettings[0].fontSize +'px'" cdkTextareaAutosize
                            [cdkAutosizeMinRows]="bmxItem.componentSettings[0].rowHeight" style="text-align: center;height: 32px;font-size: 16px;border: 1px solid grey;
                        animation: bounceIn 500ms ease-in forwards;padding: 0 4px" class="row-box"
                            [(ngModel)]="testName[columnName]" placeholder="Type Your Answer" rows="4"
                            cols="50"></textarea>
                    </div>

                    <p (click)="(selectedCard == y)?selectedCard = '':selectedCard = y" *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('options') != true) &&
                          columnName.includes('Comments') != true &&
                          columnName.includes('kata') != true &&
                          columnName.includes('ratio') != true"
                        [style.font-size]=" (columnName.includes('name') == true)?'27px':'20px'"
                        [style.color]="(columnName.includes('name') == true)?'#324395':'#bababa'"
                        style="text-align: center;padding: 10px 4px;"
                        [innerHTML]="testName[columnName] | safe: 'html'">{{testName[columnName]}}</p>

                    <p *ngIf="y !== 0  && !editSingleTableCells && (columnName.includes('options') != true) &&
                          columnName.includes('Comments') != true && selectedCard == y &&
                          columnName.includes('name') != true"
                        [style.font-size]=" (columnName.includes('name') == true)?'27px':'20px'"
                        [style.color]="(columnName.includes('name') == true)?'#324395':'#bababa'"
                        style="text-align: center;padding: 5px 4px;"
                        [innerHTML]="testName[columnName] | safe: 'html'">{{testName[columnName]}}</p>


                </div>
                <!-- 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 END TABLE CELLS 🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡🟡 -->
            </div>

        </div>

    </div>

</div>
</div>

<!--📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱 END TABLE GENERATOR 📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱-->
<app-table *ngIf="showModalTable" (save)="showModalTable = $event" [displayedColumns]="columnsNames"
[dataSource]="dataSource" (cancelEvent)="showModalTable = false"></app-table>
