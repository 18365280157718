<div style="width: 100%; display: flex; align-items: flex-start; gap: 0%;">
    <div style="width: 75%;">
        <div style="display: flex;justify-content: space-between; margin-top: 20px;">
            <div style="margin: 20px;font-size: 20px;color:#063074;font-weight: 800;">MATRIX LIST</div>
            <mat-form-field style="float:right; padding-right: 20px" appearance="outline">
                <mat-label>Search</mat-label>
                <input matInput (keyup)="applyFilter($event.target.value)" value="" placeholder="Search Project">
            </mat-form-field>
            <mat-form-field appearance="outline" style="border-radius: 0px">
                <mat-label>Sort By</mat-label>
                <mat-select [(value)]="selected" (selectionChange)="changeView()">
                    <mat-option value="Live">Live</mat-option>
                    <mat-option value="Closed">Closed</mat-option>
                    <mat-option value="All">All</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 table" style="width: 100%;">
                <!-- Position Column -->
                <ng-container matColumnDef="bmxCompany">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Client</th>
                    <td mat-cell *matCellDef="let element"> {{element.bmxCompany}} </td>
                </ng-container>
                <ng-container matColumnDef="bmxProjectName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Project Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.bmxProjectName}} </td>
                </ng-container>
                <ng-container matColumnDef="bmxDepartment">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Department </th>
                    <td mat-cell *matCellDef="let element"> {{element.bmxDepartment}} </td>
                </ng-container>
                <ng-container matColumnDef="bmxRegion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Office </th>
                    <td mat-cell *matCellDef="let element"> {{element.bmxRegion}} </td>
                </ng-container>
                <ng-container matColumnDef="Created">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
                    <td mat-cell *matCellDef="let element"> {{element.Created}} </td>
                </ng-container>
                <ng-container matColumnDef="Close">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Close </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.bmxStatus === 'close'">
                            <button mat-icon-button aria-label="Example icon button with a home icon"
                                (click)="setBMStatus(element)">
                                <mat-icon style="color: red">close</mat-icon>
                            </button>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Active">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.bmxStatus != 'close'">
                            <button mat-icon-button aria-label="Example icon button with a home icon"
                                (click)="setBMStatus(element)">
                                <mat-icon style="color: green">done</mat-icon>
                            </button>
                        </ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 3.33%;"> </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button style="color: #488abc;"
                            aria-label="Example icon button with a home icon"
                            (click)="sendEmail(element.bmxProjectName)">
                            <mat-icon>email</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Edit">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 3.33%;"> </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button style="color: #48bcb4;"
                            aria-label="Example icon button with a home icon" (click)="editBM(element.bmxProjectName)">
                            <mat-icon>build</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Delete">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 3.33%;"> </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button style="color: #bc488a;"
                            aria-label="Example icon button with a home icon" (click)="deleteBM(element.ProjectId)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator style="margin-top: 1px;" [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <!-- CALENDAR  📆📆📆📆📆📆📆📆📆-->
    <div class="calendar" style="width: 20%;position: relative;
        left: 70px;">
        <div style="width: 100%;">
            <mat-card appearance="outlined" class="demo-inline-calendar-card">
                <mat-calendar  [selected]="selectedDate" (selectedChange)="onSelect($event)"></mat-calendar>
                <button mat-button (click)="onDeselect()">Reset date</button>

            </mat-card>

        </div>
    </div>
</div>

<!--   📆📆📆📆📆📆📆📆📆 END CALENDAR-->
